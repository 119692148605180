<template>
  <div id="wrapper">
    <div class="content">
      <section class="parallax-section single-par custom-header" data-scrollax-parent="true">
        <div class="bg par-elem" data-bg="images/bg/11.jpg" data-scrollax="properties: { translateY: '30%' }"></div>
        <div class="overlay op7"></div>
        <div class="container">
          <div class="section-title">
            <h2><span><b>Бидний </b> тухай</span></h2>
            <div class="breadcrumbs fl-wrap"><a href="#">Эхлэл</a><span>Бидний тухай</span></div>
          </div>
        </div>
      </section>
      <div class="page-content container">
        <div class="content-wrapper">

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About',
  mounted() {
    window.initTowhub()
    window.initparallax();
  },
}
</script>

<style scoped>

</style>