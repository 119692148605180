<template>
    <div id="wrapper">
        <div class="content">
            <section class="parallax-section single-par custom-header" data-scrollax-parent="true">
                <div class="bg par-elem" data-bg="/assets/images/home-slide/home-slide-bg-1.jpg"
                     data-scrollax="properties: { translateY: '30%' }"></div>
                <div class="overlay op7"></div>
                <div class="container">
                    <div class="section-title">
                        <h2><span><b>{{ 'contact' | translate }} </b> {{ 'us' | translate }}</span></h2>
                        <div class="breadcrumbs fl-wrap"><a href="#">{{ 'home' | translate }}</a><span>{{
                                'contact_us' | translate }}</span></div>
                    </div>
                </div>
            </section>

            <section id="sec1" class="contact-main">
              <div v-if="loading" class="loading-wrap">
                <div class="lds-ellipsis">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="row g-0">
                                <div class="col-lg-1 col-md-1 col-sm-2 order-2 order-xl-1 order-lg-1 order-md-2 order-sm-2">
                                    <div class="mt-100 box-shadow social-main">
                                        <ul class="social-icons d-xl-flex d-lg-flex d-md-flex d-sm-flex d-flex">
                                            <li class="social-facebook">
                                                <el-tooltip class="item" effect="dark" content="Facebook" placement="top">
                                                    <a :href="contact.facebook" target="_blank"  title="Facebook хуудас">
                                                        <i class="fab fa-facebook-f"></i>
                                                    </a>
                                                </el-tooltip>
                                            </li>
                                            <li class="social-twitter">
                                                <el-tooltip class="item" effect="dark" content="Twitter" placement="top">
                                                    <a :href="contact.twiiter" target="_blank">
                                                        <i class="fab fa-twitter"></i>
                                                    </a>
                                                </el-tooltip>
                                            </li>
                                            <li class="social-linkedin">
                                                <el-tooltip class="item" effect="dark" content="Linkedin" placement="top">
                                                    <a :href="contact.linkedin" target="_blank">
                                                        <i class="fab fa-linkedin"></i></a>
                                                </el-tooltip>
                                            </li>
                                            <li class="social-youtube">
                                                <el-tooltip class="item" effect="dark" content="Youtube" placement="top">
                                                    <a :href="contact.youtube" target="_blank">
                                                        <i class="fab fa-youtube"></i></a>
                                                </el-tooltip>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-11 col-sm-10 order-2 order-sm-3">
                                    <div class="box-shadow contact-form-main">
                                        <div class="contact-form-layer">
                                            <h3>{{ 'send_feedback' | translate }}</h3>
                                            <p>{{ 'warning' | translate }}</p>
                                            <el-form ref="formValidate" :model="formValidate" :rules="ruleValidate" class="feedback">
                                                <el-form-item :label="'your_name' | translate" prop="name">
                                                    <el-input v-model="formValidate.name" prefix-icon="el-icon-user" autocomplete="off"
                                                              :placeholder="'_input_your_name' | translate"></el-input>
                                                </el-form-item>
                                                <el-form-item :label="'your_phone' | translate" prop="phone">
                                                    <el-input v-model="formValidate.phone" prefix-icon="el-icon-phone-outline" type="number" autocomplete="off"
                                                              :placeholder="'_input_your_phone' | translate"></el-input>
                                                </el-form-item>
                                                <el-form-item :label="'mail' | translate" prop="mail">
                                                    <el-input v-model="formValidate.mail" prefix-icon="el-icon-message" type="email"
                                                              :placeholder="'_input_email_address' | translate"></el-input>
                                                </el-form-item>
                                                <el-form-item prop="comment" :label="'comments' | translate">
                                                    <el-input type="textarea" required="required" rows="5" v-model="formValidate.comment"
                                                              :placeholder="'_input_feedback' | translate"
                                                              maxlength="500"
                                                              show-word-limit></el-input>
                                                </el-form-item>
                                                <div class="form-button-container">
                                                    <el-button :disabled="loading" type="primary"  @click="dataSent('formValidate')" size="medium" style="width: 100%">
                                                        <template v-if="loading">
                                                            <i class="el-icon-loading"></i>{{ '_sending' | translate }}
                                                        </template>
                                                        <template v-else>
                                                            <i class="el-icon-s-promotion"></i>{{ '_sent' | translate }}
                                                        </template>
                                                    </el-button>
                                                </div>
                                            </el-form>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-7 col-md-12 order-1 order-xl-3 order-lg-3">
                                    <div class="mt-100 box-shadow contact-map">
                                        <div class="contact-map-layer">
                                            <template v-if="contact">
                                            <h3>{{ '_contact_map' | translate }}</h3>
                                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2674.282060371748!2d106.81762411633562!3d47.91157957920634!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5d96ed2d79d855c9%3A0x9845cd6c687234e0!2sNational%20Geological%20Survey!5e0!3m2!1sen!2sus!4v1628678973374!5m2!1sen!2sus" width="600" height="450" allowfullscreen="" loading="lazy"></iframe>
                                            </template>
                                        </div>
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="contact-info-container">
                                                    <div class="icon-style-border-contact">
                                                        <i class="fal fa-envelope"></i>
                                                    </div>

                                                    <div class="title-box-contact">
                                                        <h5>{{ 'email_address' | translate }}</h5>
                                                        <p><a :href="`mailto:${contact.mail}`">{{contact.mail}}</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="contact-info-container">
                                                    <div class="icon-style-border-contact">
                                                        <i class="el-icon-location-information"></i>
                                                    </div>
                                                    <div class="title-box-contact">
                                                        <h5>{{ '_address' | translate }}</h5>
                                                        <p class="address" v-if="this.$store.state.i18n.locale === 'mn'">{{contact.address_mn}}</p>
                                                        <p class="address" v-else>{{contact.address_en}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="contact-info-container">
                                                    <div class="icon-style-border-contact">
                                                        <i class="fal fa-phone"></i>
                                                    </div>
                                                    <div class="title-box-contact">
                                                        <h5>{{ 'phone' | translate }}</h5>
                                                        <p><a :href="`tel:${contact.phone_one}`">{{contact.phone_one}}</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="contact-info-container">
                                                    <div class="icon-style-border-contact">
                                                        <i class="fal fa-phone"></i>
                                                    </div>
                                                    <div class="title-box-contact">
                                                        <h5>{{ 'phone' | translate }}</h5>
                                                        <p><a :href="`tel:${contact.phone_two}`">{{contact.phone_two}}</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import {GET_CONTACT_ORGANIZATION, POST_FEEDBACK} from "../graphql/queries";
import {mapGetters} from "vuex";
// import ContactMap from "../components/ContactMap";


export default {
    name: 'Contact',
    components: {
        // ContactMap
    },
    data() {
        const validateSpace = (rule, value, callback) => {
            let whiteSpace = /\s/g;
            if (whiteSpace.test(value)) {
                callback(new Error("Хоосон зай орсон байна"));
            } else {
                callback();
            }
        };
        return {
          loading: false,
          disabled: true,
          contact: {},
          saveData: {},
          formValidate: {
            name: '',
            mail: '',
            comment: '',
            phone: ''
          },
          ruleValidate: {
            name: [
              {required: true, message: "Та нэрээ бичнэ үү", trigger: "blur"},
              {
                min: 2,
                max: 60,
                message: "2-60 үсэгт багтаан бичнэ үү",
                trigger: "blur",
              },
            ],
            phone: [
              {required: true, message: "Та дугаараа бичнэ үү", trigger: "blur"},
              {
                min: 8,
                max: 8,
                message: "8 оронтой дугаар бичнэ үү",
                trigger: "blur",
              },
              {validator: validateSpace, trigger: "blur"},
            ],
            mail: [
              {required: true, message: "Email хаягаа бичнэ үү", trigger: "blur"},
              {
                type: "email",
                message: "Зөв и-мейл хаяг бичнэ үү",
                trigger: ["blur"],
              },
            ],
            comment: [
              {
                required: true,
                message: "Санал хүсэлтээ бичнэ үү",
                trigger: "blur",
              },
              {
                min: 10,
                message: "Хамгийн багадаа 10 тэмдэгт бичнэ үү",
                trigger: "blur",
              },
              {
                max: 500,
                message: "Хамгийн ихдээ 500 тэмдэгт бичнэ үү",
                trigger: "blur",
              },
            ],
          },
        }
    },
    computed: {
       ...mapGetters([
          'languages',
          'language',
          // ...
       ])
    },
    methods: {
        getContact() {
          this.loading = true;
            this.$apollo.query({query: GET_CONTACT_ORGANIZATION}).then(({data}) => {
              this.contact = data.contact_organization[0];
              this.loading = false;
            })
        },
        dataSent(name) {
          this.$refs[name].validate((valid) => {
            if (valid ) {
              this.loading = true;
              if(name === 'formValidate'){
                // let saveData = {
                //   name:this.formValidate.name,
                //   mail:this.formValidate.mail,
                //   phone:this.formValidate.phone,
                //   comment:this.formValidate.comment,
                // };

                console.log(this.saveData);
                this.$apollo.mutate({mutation: POST_FEEDBACK,
                  variables: {
                    name: this.formValidate.name,
                    mail: this.formValidate.mail,
                    phone: this.formValidate.phone,
                    comment: this.formValidate.comment,
                  }
                }).then(() =>{
                  this.$refs[name].resetFields();
                  this.$notify.success({title: 'Амжилттай боллоо', message: 'Санал хүсэлт амжилттай илгээгдлээ.', offset: 100});
                  this.loading = false;
                  // if(data != null){
                  //   this.saveData = saveData;
                  //   this.loading = false;
                  //   this.$notify.success({title: 'Амжилттай боллоо', message: 'Санал хүсэлт амжилттай илгээгдлээ.', offset: 100});
                  //   console.log(this.saveData);
                  // }else {
                  //   this.$notify({title: 'Амжилтгүй боллоо !!!', message: 'Талбарыг гүйцэт бөглөнө үү !!!', type: 'warning'});
                  // }
                })
              }
            } else {
              this.$notify({title: 'Амжилтгүй боллоо !!!', message: 'Талбарыг гүйцэт бөглөнө үү !!!', type: 'warning'});
              this.loading = false;
              return false;
            }
          });
        },


    },
    mounted() {
        window.initTowhub()
        window.initparallax();
        this.getContact();
    },
}
</script>
