<template>
  <!-- dashboard content-->
  <div class="col-md-8">
    <div class="dashboard-title fl-wrap">
      <h3>{{ '_my_orders' | translate }} </h3>
    </div>

    <div class="order-container">

      <template>
        <div class="order-container">

          <el-button size="mini" @click="clearFilter()">{{ 'showAll' | translate }}</el-button>
          <el-button size="mini" type="info" @click="filterTag(3)">{{ '_expected' | translate }}</el-button>
          <el-button size="mini" type="success" @click="filterTag(1)">{{ '_approved' | translate }}</el-button>
          <el-button size="mini" type="danger" @click="filterTag(2)">{{ '_canceled' | translate }}</el-button>
          <el-button size="mini" type="warning">{{ '_pay_the_fee' | translate }}</el-button>


        </div>

        <div class="order-table">
          <el-table
              ref="filterTable"
              :data="clonedProducts"
              style="width: 100%">
            <el-table-column
                prop="date"
                :label="'date' | translate"
                sortable
                width="110"
            >
            </el-table-column>
            <el-table-column
                prop="title_mn"
                :label="'name' | translate"
                sortable
                width="200">
            </el-table-column>
            <el-table-column
                prop="price"
                :label="'price' | translate"
                width="90"
                sortable
                :formatter="formatter">
            </el-table-column>
            <el-table-column
                prop="product_type_name"
                :label="'_type' | translate"
                sortable
                width="100"
                :formatter="formatter">
            </el-table-column>
            <el-table-column
                prop="invoice_status"
                :label="'tuluv' | translate"
                sortable
                width="155">
              <template slot-scope="scope">
                <el-tag v-if="scope.row.status_id === 1"
                        type="success"
                        disable-transitions>{{ scope.row.invoice_status }}
                </el-tag>
                <el-tag v-else-if="scope.row.status_id === 2"
                        type="danger"
                        disable-transitions>{{ scope.row.invoice_status }}
                </el-tag>
                <el-tag v-else-if="scope.row.status_id === 3"
                        type="info"
                        disable-transitions>{{ scope.row.invoice_status }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column
                prop="action"
                :label="'action' | translate"
                width="120">
              <template slot-scope="scope" class="small-font">
                <router-link v-if="scope.row.status_id === 1" :to="`/download/progress/${scope.row.id}`">{{ '_file_download' | translate }}</router-link>
                <!-- <a href="javascript:void(0)" @click="generateOneTimeLink(scope.row.id)" class="small-text" v-if="scope.row.one_time_link === 0">Нэг удаагийн татах холбоос үүсгэх</a>-->
                <!-- <a v-else href="javascript:void(0)" @click="downloadFile(scope.row.id)">Файл татах</a>-->
              </template>
            </el-table-column>
          </el-table>
        </div>
      </template>
    </div>
    <!-- dashboard content end-->
  </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "OrderList",
  data() {
    return {
      products: [],
      clonedProducts: [],
    };
  },

  created() {
    this.getProducts();
  },

  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters([
      'user',
      'languages',
      'language',
    ])
  },

  mounted() {
  },

  methods: {
    formatter(val){
      //console.log(val);
      return val.price;
    },
    clearFilter() {
      this.clonedProducts = this.products;
    },

    filterTag(val) {
      // return row.invoice_status === value;
      console.log(val);
      this.clonedProducts = this.products.filter(f => {
        return f.status_id == val
      })
      console.log(this.products);
      console.log(this.clonedProducts);
    },

    filterHandler(value, row, column) {
      const property = column['property'];
      return row[property] === value;
    },

    getProducts() {
      this.loading = true;

      // this.filter = {
      //   user_id: 10025,
      //   status: null
      // }
      this.$http.get(`/api/user-invoice-items/${this.user.id}`).then(({data}) => {
        this.clonedProducts = data['items'];
        this.products = data['items'];

        this.clonedProducts.forEach((ch) => {
          ch.date = ch.created_at.substr(0, 10);
          switch (ch.product_file_type) {
            case 1:
              ch.product_type_name = 'Тайл баримт бичиг';
              ch.status_id == 1 && ch.status_id == 2 ? ch.action = 'Татах' : 'Харах'
              break;
            case 2:
              ch.product_type_name = 'Вэктор ба растер газрын зураг';
              ch.status_id == 1 && ch.status_id == 2 ? ch.action = 'Татах' : 'Харах'
              break;
            case 3:
              ch.product_type_name = 'Вэб газрын зургийн үйлчилгээ'
              ch.action = 'Татах'
              break;
            case 4:
              ch.product_type_name = 'Геологийн өгөгдөл'
              break;
            default:
          }
        });

        console.log(this.products);
        console.log('here');
        console.log(data['items']);
        this.loading = false;
      }).catch(e => {
        console.log(e)
      });
      // this.$http.post('/api/user-invoice-items', this.filter).then(({data}) => {
      //   this.products = data['items'];
      //   this.loading = false;
      // }).catch(e => {
      //   console.log(e)
      // });
    },
  },
}
</script>
