<template>
  <div>
    <div id="wrapper">
      <div class="content">
        <section class="parallax-section single-par custom-header" data-scrollax-parent="true">
          <div class="bg par-elem" data-bg="/assets/images/home-slide/home-slide-bg-1.jpg" data-scrollax="properties: { translateY: '30%' }"></div>
          <div class="overlay op7"></div>
          <div class="container">
            <div class="section-title">
              <h2><span><b>{{ 'tvgeemel' | translate }} </b> {{ 'a_q' | translate }}</span></h2>
              <div class="breadcrumbs fl-wrap"><router-link to="/">{{ 'home' | translate }}</router-link><span>{{ 'answer_quistion' | translate }}</span></div>
            </div>
          </div>
        </section>

        <section class="gray-bg" id="sec3">
          <div v-if="loading" class="loading-wrap">
            <div class="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <div class="container" v-else>
            <div class="section-title">
              <h2> {{ 'answer_quistion' | translate }}</h2>
              <div class="section-subtitle">{{ 'answer_quistion' | translate }}</div>
              <span class="section-separator"></span>
            </div>
            <div class="post-container fl-wrap">
              <div class="row">
                <!-- blog content-->
                <div class="col-md-3">
                  <div class="faq-nav help-bar scroll-init">
                    <ul class="no-list-style">
                      <li :key="index" v-for="(cat, index) in categories">
                        <a class="item" href="javascript:void(0);" :class="[(index+1===activeCategory) ? 'visible':'']" @click="callData(cat.id)" >
                          <!--                          <a href="javascript:void(0);" @click="callData(`${cat.id}`)">-->
                          <i class="fal fa-border-right"></i>
                          <span v-if="language.short == 'mn'">{{ cat.question_type_mn }}</span>
                          <span v-else>{{ cat.question_type_en }}</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <!-- blog conten end-->
                <!-- blog sidebar -->
                <div class="col-md-9">
                  <!-- faq-section -->
<!--                  <div class="faq-title faq-title_first fl-wrap" v-if="language.short == 'mn'">{{ cat_by_id.question_type_mn }}</div>-->
<!--                  <div class="faq-title faq-title_first fl-wrap" v-else>{{ cat_by_id.question_type_en }}</div>-->
                  <div class="faq-section fl-wrap" id="fq1">
                    <!-- accordion-->
                    <div class="accordion"  :key="index" v-for="(answer_quistion, index) in answer_quistions">
<!--                      <div v-if="answer_quistion.question_category_id == 3">-->
                        <a class="toggle "  :class="[(activeItem == answer_quistion.id) ? 'act-accordion':'']" @click="paymentActive(answer_quistion.id)" href="#" >
                          <div v-if="language.short == 'mn'"> {{ answer_quistion.question_mn }}</div>
                          <div v-else> {{ answer_quistion.question_en }}</div>
                          <span></span></a>
                        <div class="accordion-inner" :class="[(activeItem == answer_quistion.id) ? 'visible':'']">
                          <p v-if="language.short == 'mn'">{{ answer_quistion.answer_mn }}</p>
                          <p v-else>{{ answer_quistion.answer_en }}</p>
                        </div>
<!--                      </div>-->
                    </div>
                    <!-- accordion end -->
                  </div>
                  <!-- faq-section end -->
                </div>
                <!-- blog sidebar end -->
              </div>
            </div>
          </div>
        </section>

        <div class="limit-box fl-wrap"></div>
      </div>
      <!--content end-->
    </div>
  </div>
</template>

<script>
import {
  //GET_ANSWER_QUESTION,
  GET_ANSWER_QUESTION_BY_ID,
  GET_ANSWER_CAT,
} from "../graphql/queries";
import {mapGetters} from 'vuex'
export default {
  name: "Faq",
  data() {
    return {
      activeItem:'0',
      showOpen:false,
      answer_quistions: [],
      categories: [],
      activeCategory: 1,
      loading: true,
    }
  },
  methods:{
    getCategory(){
      this.loading = true;
      this.$apollo.query({ query: GET_ANSWER_CAT }).then(({data}) => {
        this.categories = data.ds_fqa_lut_view;
        //console.log(this.categories);
        this.loading = false;
      })
    },
    paymentActive(p) {this.activeItem = p;},
    callData(cat_id){
      this.currentCat = cat_id;
      this.activeCategory = cat_id
      //console.log(this.currentCat);
      if (this.currentCat) {
        this.$apollo.query({ query: GET_ANSWER_QUESTION_BY_ID, variables: { question_category_id: this.currentCat.toString() }}).then(({data}) => {
          this.answer_quistions = data.fqa;
          setTimeout(()=>{
            this.loading = false;
          })
        });
      }else{
        this.activeCategory = 1;
        this.$apollo.query({ query: GET_ANSWER_QUESTION_BY_ID, variables: { question_category_id: this.activeCategory.toString() }}).then(({data}) => {
          this.answer_quistions = data.fqa;
          setTimeout(()=>{
            this.loading = false;
          })
        });
      }
    },
  },
  mounted() {
    window.initTowhub()
    window.initparallax();
    this.callData();
    this.getCategory();
  },
  computed: {
    ...mapGetters([
      'languages',
      'language',
      // ...
    ])
  },
}
</script>

<style scoped>

</style>