export default {
    "home": "Home",
    "list": "Digital library",
    "article": "Article",
    "contact_us": "Contact Us",
    "contact": "Contact",
    "us": "Us",
    "profile": "Profile",
    "order": "Order List",
    "search": "Search",
    "searches": "Search",
    "share": "Share",
    "niitlel_search": "Search for articles",
    "other": "Other",
    "bvleg": "Chapter",
    "cat": "Category",
    "cat_all": "Category",
    "prev": "Prev",
    "next": "Next",
    "read_more": "Read more",
    "login": "Login",
    "add": "Add",
    "save": "Save",
    "saved": "Saved",
    "price": "Price",
    "action": "Action",
    "free": "Free",
    "other_information": "Data format",
    "mongeomin": "MonGeoMin",
    "mongeocol": "MonGeoCol",
    "mongeocat": "MonGeoCat",
    "mongeonet": "MonGeoNet",
    "mongeocat_webgis_desc": "WEB GIS",
    "mongeocat_geonet_desc": "Metadata Catalogue",
    "mongeomin_desc": "Mineral reserve registration system",
    "mongeocol_desc": "Field monitoring and data collector system",
    "national_geologal_survey": "NATIONAL GEOLOGICAL SURVEY © 2021 ALL RIGHTS RESERVED.",
    "link": "Link",
    "systems": "Related systems",
    "terms_conditions": "Terms & Conditions",
    "hel_p": "Help",
    "answer_quistion": "Frequently Asked Questions",
    "detail_": "Read more",
    "eh_surwalj": "Data or document source",
    "about": "About us",
    "_address": "Address",
    "_network": "Public network",
    "sanhvvjvvlegch": "Funded by EBRD",
    "ishlel": "References",
    "magazine_information": "Magazine information",
    "last_name": "Lastname",
    "hariya_bailguullaga": "SUBJECT ORGANIZATION",
    "o_number": "Volume number",
    "tvgeemel": "Frequently",
    "a_q": "Asked Questions",
    "terms": "Terms",
    "conditions": "Conditions",
    "step": "Purchase steps",
    "goverment_officials": "Governmental organization officials, experts",
    "video_zaawar": "Video of using the portal",
    "magazine_name": "Name of the magazine",
    "article_name": "Article title",
    "page_num": "Page number",
    "issn": "ISSN / Magazine /, ISBN / book / number",
    "eISSN_duygaar": "eISSN number",
    "hewlegdsen_on": "Year of publication",
    "hurliin_name": "Name of the meeting",
    "tusul_name": "Name of Project",
    "date": "Date",
    "country": "Country",
    "city": "City",
    "a_link": "Related link",
    "_location": "Location",
    "file_size": "File size",
    "organization": "Organization",
    "year": "Year",
    "tuluv": "Status",
    "dio_number": "DOI number",
    "authors": "Author",
    "content": "Content",
    "magazine_number": "Magazine number",
    "no_imformation": "No information provided.",
    "archive": "Doc library",
    "archive_information": "Archive service payment information",
    "a4": "A4 size",
    "_pdf": "In PDF format",
    "imageFile": "Contains an image file",
    "currency": "$",
    "yourCart": "In your cart",
    "close": "Close",
    "total": "Total",
    "seeList": "See list",
    "pay": "Pay the fee",
    "sendReguest": "Send a request",

    "additional_pictures": "Additional pictures",
    "_maps": "Map",
    "registration_number": "Report number",
    "information_typ": "Information type",
    "_format": "Format",
    "add_cart": "Add to Cart",
    "remove_cart": "Remove from cart",
    "subscription": "Subscription",
    "start_page": "Start page",
    "end_page": "End page",
    "cancel": "Cancel",
    "geodata": "Geodata",
    "report": "Тайлан",
    "web_serve": "WMS / WFS",
    "vector_and_raster_maps": "Vector and raster maps",
    "thematic": "Thematic",
    "_type": "Type",
    "province_city": "Province / City",
    "soum_district": "Soum / District",
    "author": "Аuthor",
    "result": "Result",
    "_order": "Order",
    "related_products": "Related products",
    "thumbnail": "Thumbnail",
    "list_view": "List",
    "_cat": "Category",
    "contact_information": "Contact information",
    "phone": "Telphone",
    "mail": "Email",
    "send_feedback": "Send feedback",
    "warning": "Use the form below to send us your comments",
    "name": "Name:",
    "your_name": "Name:",
    "your_lastname": "Lastname",
    "your_mail": "Email address:",
    "your_phone": "Phone number:",
    "comments": "Feedback:",
    "email_address": "Email address",
    "_input_your_name": "etc: Ganbold",
    "_input_your_phone": "etc: 9900xxxx",
    "_input_email_address": "ganbold@gmail.com",
    "_input_feedback": "Write your comments here ...",
    "_sent": "Submit feedback",
    "_sending": "Sending",
    "_contact_map": "Contact map",
    "clean": "Reset",
    "_a": "A",
    "rticle": "rticle",
    "_research_work": "Research work",
    "news": "Information",
    "book": "Ном",
    "magazine": "Magazine",
    "registration_mineral_resources": "Registration of mineral resources",
    "product": "Product",
    "details": "Details",
    "labels": "Labels",
    "free_download": "Free download",
    "paid": "Paid",
    "no_video": "Video not included",
    "steps": "Steps",
    "video_tutorial": "Video tutorial",
    "expected": "Expected",
    "payment": "Payment",
    "toPay": "to pay",
    "cart": "Cart",
    "typePayment": "Type of payment",
    "fileType": "File type",
    "quantity": "Quantity",
    "totalSize": "Total size",
    "pleaseSelectPaymentType": "Please select a payment type",
    "transfers": "Treasury transfers",
    "continued": "To be continued",
    "madeAfterLoging": "Transactions can be made after logging in",

    "choosePayment": "Choose your payment terms!",

    "qpay_the_pee":"Qpay the fee",
    "use_the_qpay":"Use the Qpay-enabled banking application you use to scan the following QR code and make a payment.",
    "check_payment":"Check payment",
    "transfer_treasury":"Transfer to the treasury",
    "transfer_account":"Transfer account",
    "recipient":"Recipient",
    "nat_geological_survey":"National Geological Survey",
    "transaction_value":"Transaction value",
    "successfully_paid":"Successfully paid",
    "order_list":"Order list",
    "_test":"Sorry, an update is in progress!",
    "file_type":"File type",


    // home page words
    "national_geological": "NATIONAL GEOLOGICAL SURVEY",
    "national_geological_survey": "INTEGRATED NATIONAL GEOSCIENCE DATABASE",
    "information_systems": "Geological and mineral information systems",
    "reports_and_documents": "Document Management System",
    "metadat_catelogy_sistem": "Metadata-Geological catalogue system",
    "resource_registration_system": "Resource registration system",
    "heeriin_sudalgaa": "Field survey and business trip data collector",
    "location": "By location",
    "search_word": "Type here to search",
    "category": "By category",
    "new_add": "Recently added",
    "geo_desc": "Geology, tectonics, geomorphology, quaternary maps, annotations, database annotations, database.",
    "more": "More",
    "view": "view",
    "vector_maps": "Geology digital products",
    "geophysics": "Geophysics",
    "tyectonics": "Tectonics",
    "mineral_resources": "Mineral Resources",
    "geology": "Geology",
    "partners": "Partners",
    "total_map": "Total map",
    "total_documentation": "Total documentation",
    "total_web_service": "Total web service",
    "public_doc": "Public doc",
    "showAll": "Show all",
    "non_location": "Location not specified",
    "extra_info": "Additional info",
    // list page words

    // Saved product
    "saved_items": "Saved items",
    "saved_items1": "Saved",
    "saved_items2": "items",
    "no_product_title": "Sorry. You do not have a list of saved products",
    "no_product_description": "Choose the product you like from the Product list and save it",
    "no_product_title_list": "Sorry. The product you are looking for has not been found",
    "no_product_description_list": "You can filter and search for alternatives from the Product list",

    //profile
    "_firstname": "Firstname",
    "_lastname": "Lastname",
    "_gender": "Gender",
    "_email": "Email",
    "_birthday": "Birth date",
    "_select_birthday": "Please select a date of birth",
    "_phone": "Phone",
    "_addres": "Address",
    "_website": "Website",
    "_comment": "Comment",
    "_comment_write": "Write comment",
    "_password": "Password",
    "_newpassword": "New password",
    "_signin_password": "Login password",
    "_confirmpassword": "Confirm password",
    "_personal_information": "Personal information",
    "_organzation_information": "Company information",
    "_civil_service_users": "Civil service users",
    "_update_password": "Change password",
    "_update_phone": "Change login name",
    "_update_phone_send": "Send a request to change the login name",
    "_my_orders": "My orders",
    "_signout": "Sign out",
    "_rule_firstname": "Please input firstname name",
    "_rule_lastname": "Please input lastname name",
    "_rule_mail": "Please input your email address",
    "_select": "Select",
    "_pay_the_fee": "Pay the fee",
    "_expected": "Expected",
    "_approved": "Approved",
    "_canceled": "Canceled",
    "_file_download": "File download",
    "_which_low": "The end page is smaller than the start page",
    "_select_type": "Please select a type",

    //auth
    "login_text":"Login",
    "login_btn_text":"Sign in",
    "login_btn_text_loading":"Signing in...",
    "login_email_placeholder": "Enter your registered email address",
    "login_password_placeholder": "Please enter your password",
    "register_text":"Register",
    "register_btn_text":"Register",
    "forgot_password": "Forgot password?",
    "dan_label":"Decentralized Authentication Network, simply stated DAN is the platform which can operate thoroughly with the other independent systems of public as well as private sector services and authenticate the customers to access those services easily",
    "dan_btn_text":"Dan platform login",
    "login_error": "An error occurred while logging in. Please check your login information and try again!",
    "login_success": "Successfully logged in",
    "reg_citizen": "Citizen and individual",
    "reg_organization": "Government organization",
    "reg_back": "Back",
    "reg_loading": "Please wait, a new registration request is being sent.",
    "reg_success": "Successfully registered. Please log in with your registered information.",
    "reg_error": "An error occurred during registration. Please check your information and try again.",
    "reg_term_label": "Terms of service",
    "reg_term": "View terms of service",
    "reg_term_agree": "I agree",
    "reg_term_agree_org": "Our organization agrees",
    "reg_rePassError": "Re-entered password does not match.",
    "reg_invalid": "Please complete your registration.",
    "reg_surname": "Your surname",
    "reg_surname_placeholder": "Please enter a surname",
    "reg_first_name": "Your name",
    "reg_first_name_placeholder": "Please enter your name",
    "reg_phone": "Your phone",
    "reg_phone_placeholder": "Please enter a phone number",
    "reg_gender": "Your gender",
    "reg_gender_male": "Male",
    "reg_gender_female": "Female",
    "reg_email_placeholder": "Your email address will be your login name",
    "reg_password": "Password",
    "reg_password_placeholder": "Please enter a password",
    "reg_password_repeat": "Password confirm",
    "reg_password_repeat_placeholder": "Please re-enter your password",
    "reg_official_letter": "Official letter",
    "reg_official_letter_desc": "Please upload a jpg / png file smaller than 500kb",
    "reg_official_letter_btn": "Upload official letter",
    "reg_position": "Your position",
    "reg_position_placeholder": "Please enter your position",
    "reg_organization_name": "Organization",
    "reg_organization_name_placeholder": "Enter your organization",
    "reg_purpose": "The purpose of using the system",
    "reg_purpose_1": "Geological survey",
    "reg_purpose_2": "Geological exploration",
    "reg_purpose_3": "Research",
    "reg_purpose_4": "Knowledge",
    "reg_purpose_other": "Other purposes",
    "reg_purpose_other_placeholder": "Please enter your other purposes",
    "reg_direction": "The direction of your work",
    "reg_direction_placeholder": "Please select your field of work",
    "reg_orcid_placeholder": "Please enter your ORCID",
    "reg_org_type": "Type of the organization",
    "reg_org_type_placeholder": "Please select your type of organization",
    "reg_org_sub_type": "Subtype of organization",
    "reg_org_sub_type_placeholder": "Please select a subtype of organization",
    "reg_org_name_warning": "If your organization is not an option, please contact the National Geological Survey to verify your organization's rights.",
    "basemap":"Basemap",
    "complementary_maps":"Complementary maps",
    "hurliin_name_ognoo": "Name of the meeting, Date",
    "tusul_name_ognoo": "Name of the project, Date ",
    "published_language": "Published language",
    "published_organization": "Publishing Organization",
    "contact_email": "Email",
    "contact_phone": "Phone number",
    "keyword": "Keyword",
    "name_content": "Name, content",
};
