<template>
  <div>
    <ul id="progressbar" class="no-list-style">
      <li class="active">
        <span class="tolt" data-microtip-position="top" :data-tooltip="languages == 'mn' ? `Төлбөрийн төрөл` : `Type of payment`">01</span>
      </li>
      <li>
        <span class="tolt" data-microtip-position="top" :data-tooltip="languages == 'mn' ? `Төлбөр төлөлт` : `Payment`">02</span>
      </li>
      <li>
        <span class="tolt" data-microtip-position="top" :data-tooltip="languages == 'mn' ? `Төлөв` : `Status`">03</span>
      </li>
    </ul>

    <div v-if="user != null" class="bookiing-form-wrap block_box fl-wrap">
      <!--   list-single-main-item -->
      <div class="list-single-main-item fl-wrap hidden-section tr-sec">

<!--        <div>-->
<!--          <h3 class="p-type">-->
<!--            {{ '_test' | translate }}-->
<!--          </h3>-->
<!--        </div>-->

        <h3 class="p-type">{{ 'pleaseSelectPaymentType' | translate }}</h3>
        <div class="profile-edit-container">
          <div class="custom-form">
            <div class="row">
              <div class="col-sm-6">
                <div :class="`select-bank ${selectedBank == 'qpay' ? 'active' : ''}`"
                     @click="bankSelected('qpay')">
                  <img src="/assets/images/logo_qpay.jpeg" alt="">
                  <span>QPay</span>
                  <span class="check">
                                <i class="el-icon-check"></i>
                              </span>
                </div>
              </div>

              <div class="col-sm-6">
                <div :class="`select-bank ${selectedBank == 'state' ? 'active' : ''}`"
                     @click="bankSelected('state')">
                  <img src="/assets/images/logo-state.svg" alt="">
                  <span>{{ 'transfers' | translate }}</span>
                  <span class="check">
                                <i class="el-icon-check"></i>
                              </span>
                </div>
              </div>
            </div>
            <br/>
            <el-alert v-if="isError && selectedBank == null"
                :title="languages == 'mn' ? `Төлбөрийн нөхцөлөө сонгоно уу!` : `Choose your payment terms!`"
                type="error">
            </el-alert>

            <div class="p-action">
              <!--                          <router-link to="/check-out-confirm" class="next-form action-button color-bg">-->
              <!--                            Үргэлжлүүлэх-->
              <!--                          </router-link>-->
              <a href="javascript:void(0)" @click="gotoNext" class="btn float-btn color2-bg submit-btn next-btn" id="submit">
                {{ 'continued' | translate }} <i class="fa fa-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>


      </div>
      <!--   list-single-main-item end -->
    </div>

    <div v-else class="bookiing-form-wrap block_box fl-wrap">
      <!--   list-single-main-item -->
      <div class="list-single-main-item fl-wrap hidden-section tr-sec no-auth-box">
        <i class="fa fa-info-circle"></i>
        <h3 class="p-type">{{ 'madeAfterLoging' | translate }}</h3>
        <div class="no-auth-container">
          <a href="javascript:void(0)" @click="gotoLogin"
             class="btn float-btn color2-bg submit-btn next-btn">
            {{ 'login_btn_text' | translate }} <i class="fa fa-user"></i>
          </a>
        </div>
      </div>
      <!--   list-single-main-item end -->
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex"
export default {
  name: "Payment",
  data() {
    return {
      selectedBank: null,
      isError: false
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'languages',
      'language',
      // ...
    ])
  },
  mounted() {
    window.initTowhub()
    window.initparallax();
  },

  methods: {
    bankSelected(val) {
      console.log(val);
      this.selectedBank = val;
    },

    gotoNext() {
      if(this.selectedBank == null) {
        this.isError = true
      }else{
        if(this.selectedBank == 'qpay'){
          this.$router.push("/payment/qpay/transaction");
          // window.location.reload();
        }else{
          this.$router.push("/payment/swift/transaction");
        }
      }
    },

    gotoLogin() {
        this.$router.push("/auth");
    }
  }
}
</script>
