<template>
  <div>
    <!--cart  -->
    <div class="show-cart" @click="showCart">
      <i class="far fa-shopping-cart"></i>
      <span id="basketCounter">{{ basketItems.length }}</span>
    </div>
    <div class="cart-overlay" :style="`display: ${showCartModal ? 'block' : 'none' }`" @click="closeCart"></div>
    <div class="cart-modal" :style="`right: ${showCartModal ? 0 : -350}px`">
      <div class="cart-modal-wrap">
        <el-button type="primary" class="close-cart" icon="el-icon-circle-close" @click="closeCart">{{ 'close' | translate }}</el-button>
        <h3>{{ 'yourCart' | translate }}</h3>
        <el-tooltip class="item" effect="dark" content="Сагсыг цэвэрлэх" placement="left">
          <el-button type="danger" icon="el-icon-delete" size="mini" circle class="cart-clear"
                     @click="clearAll"></el-button>
        </el-tooltip>
        <ul class="cart-modal-list no-list-style">
          <li v-for="item in basketItems" :key="item.id">
            <router-link class="cart-modal-image" :to="`/product-detail/${item.id}`">
              <img :src="IMAGE_URL+item.photo" alt="" key="2">
            </router-link>
            <div class="cart-modal-det">
              <router-link :to="`/product-detail/${item.id}`">{{ item.title_mn }}</router-link>
              <div class="cart-quantity">
                <span class="woocommerce-Price-amount">{{ item.price_mnt }}₮</span>
              </div>
            </div>
            <a href="javascript:void(0)" class="remove" @click="toggleBasketItem(item.id)">
              <i class="fal fa-times-circle"></i>
            </a>
          </li>
        </ul>

        <div class="cart-modal-total">
          <span class="cart-modal-total-title">{{ 'total' | translate }}:</span>
          <span class="cart-total-amount">{{ totalPrice }}₮</span>
        </div>

        <div class="cart-sb_footer">
          <router-link to="/product-list" @click.native="closeCart" class="cart_btn-cart">
            {{ 'seeList' | translate }}
          </router-link>
          <router-link to="/payment" @click.native="closeCart" class="cart_btn-check-out">
            {{ 'pay' | translate }}
          </router-link>
        </div>

        <div class="cart-sb_footer_down" v-if="user != null && (user.is_org == 1 || user.is_org == '1')">
          <router-link to="/company-form" @click.native="closeCart" class="cart_btn-order">
            {{ 'sendReguest' | translate }}
          </router-link>
        </div>
      </div>
    </div>
    <!--cart end-->
  </div>
</template>
<script>
import {toggleBasket, checkBasket, clearBasketStorage} from "../utils/helper";
import {IMAGE_URL} from "../graphql/queries";
import {mapGetters, mapActions} from "vuex";

export default {
  name: "AppCartSide",
  data() {
    return {
      showCartModal: false,
      IMAGE_URL: IMAGE_URL,
    }
  },

  computed: {
    ...mapGetters([
      'languages',
      'language',
      "basketItems",
      "totalPrice",
      "user",
// ...
    ])
  },

  methods: {
    ...mapActions({
      clearBasket: "clearBasket",
      toggleBasketItem: "toggleBasketItem",
    }),
    showCart() {
      this.showCartModal = true;
    },

    closeCart() {
      this.showCartModal = false;
    },

    checkBasket(id) {
      checkBasket(this, id);
    },

    toggleBasket(id) {
      toggleBasket(this, id);
    },

    clearAll() {
      this.clearBasket()
      clearBasketStorage()
    }
  }
}
</script>
