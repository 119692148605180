<template>
  <div id="wrapper">
    <!-- content-->
    <div class="content">
      <section class="parallax-section single-par custom-header" data-scrollax-parent="true">
        <div class="bg par-elem" data-bg="images/bg/11.jpg" data-scrollax="properties: { translateY: '30%' }"></div>
        <div class="overlay op7"></div>
        <div class="container">
          <div class="section-title">
            <h2><span><b>Миний </b>сагс / 3 материал</span></h2>
            <div class="breadcrumbs fl-wrap"><a href="#">Эхлэл</a><span>Холбоо барих</span></div>
          </div>
        </div>
      </section>

      <!--  section  -->
      <section class="gray-bg small-padding no-top-padding-sec" id="sec1">
        <div class="container">
          <div class="fl-wrap">
            <div class="row">
              <div class="col-md-8">
                <div class="cart-totals">
                  <h4 class="cart-ttitle ">Сагсан дахь бүтээгдэхүүн</h4>
                  <table class="table table-border checkout-table">
                    <tbody>
                    <tr>
                      <th class="hidden-xs">Зураг</th>
                      <th>Материалын нэр</th>
                      <th>Төрөл</th>
                      <th class="hidden-xs">Үнэ</th>
                      <th></th>
                    </tr>
                    <tr>
                      <td class="hidden-xs">
                        <a href="#"><img src="images/shop/1.jpg" alt="" class="respimg"></a>
                      </td>
                      <td>
                        <h5 class="product-name">Судалгаа тайлан</h5>
                      </td>
                      <td class="hidden-xs">
                        <h5 class="order-money">Судалгаа</h5>
                      </td>
                      <td class="hidden-xs">
                        <h5 class="order-money">₮56.00</h5>
                      </td>
                      <td class="pr-remove">
                        <a href="#" title="Remove"><i class="fal fa-times"></i></a>
                      </td>
                    </tr>
                    <tr>
                      <td class="hidden-xs">
                        <a href="#"><img src="images/shop/2.jpg" alt="" class="respimg"></a>
                      </td>
                      <td>
                        <h5 class="product-name">Судалгаа тайлан</h5>
                      </td>
                      <td class="hidden-xs">
                        <h5 class="order-money">Судалгаа</h5>
                      </td>
                      <td class="hidden-xs">
                        <h5 class="product-title order-money">₮23.00</h5>
                      </td>
                      <td class="pr-remove">
                        <a href="#" title="Remove"><i class="fal fa-times"></i></a>
                      </td>
                    </tr>
                    <tr>
                      <td class="hidden-xs">
                        <a href="#"><img src="images/shop/3.jpg" alt="" class="respimg"></a>
                      </td>
                      <td>
                        <h5 class="product-name">Судалгаа тайлан</h5>
                      </td>
                      <td class="hidden-xs">
                        <h5 class="order-money">Судалгаа</h5>
                      </td>
                      <td class="hidden-xs">
                        <h5 class="product-title order-money">₮101.00</h5>
                      </td>
                      <td class="pr-remove">
                        <a href="#" title="Remove"><i class="fal fa-times"></i></a>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="col-md-4">
                <div class="cart-totals cart-summary">
                  <h4 class="cart-title">
                    <i class="fa fa-shopping-basket"></i>
                    <span>Нийт дүн</span>
                  </h4>
                  <table class="table table-border checkout-table">
                    <tbody>
                    <tr>
                      <th>Тоо ширхэг:</th>
                      <td>3</td>
                    </tr>
                    <tr>
                      <th>Дүн:</th>
                      <td>₮40.00</td>
                    </tr>
                    <tr>
                      <th>НӨАТ дүн:</th>
                      <td>₮42.00</td>
                    </tr>
                    <tr>
                      <th>Нийт дүн:</th>
                      <td>₮42.00</td>
                    </tr>
                    </tbody>
                  </table>

                  <router-link to="/check-out" type="submit" class="btn  color-bg btn-d">
                    <span>Тооцоо хийх</span>
                    <i class="fa fa-arrow-right"></i>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="limit-box fl-wrap"></div>
    </div>
    <!--content end-->
    <!--cart  -->
      <cart-side></cart-side>
    <!--cart end-->
  </div>
</template>

<script>
import CartSide from "../components/cartSide";

export default {
  name: 'Contact',
    components: {
      CartSide
    },
  mounted() {
    window.initTowhub()
    window.initparallax();
  },
}
</script>