<template>
  <div id="wrapper">
    <!-- content-->
    <div class="content">

      <!--  section  -->
      <section class="gray-bg main-dashboard-sec" id="sec1">
        <div class="container">
        <!-- dashboard content-->
        <div class="col-md-8">
          <div class="dashboard-title fl-wrap">
            <h3>{{ '_civil_service_users' | translate }}</h3>
          </div>
          <!-- profile-edit-container-->
          <div class="profile-edit-container fl-wrap block_box">
            <el-form :model="personalForm" :rules="rules" ref="personalForm">
              <el-row :gutter="20" class="demo-ruleForm">
                <el-col :span="12">
                  <el-form-item prop="type">
                    <el-input prefix-icon="el-icon-guide"
                              v-model="personalForm.type"
                              autocomplete="off"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="company">
                    <el-input prefix-icon="el-icon-office-building"
                              v-model="personalForm.company"
                              autocomplete="off"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="24">
                  <el-form-item prop="user">
                    <el-input prefix-icon="fal fa-user"
                              v-model="personalForm.user"
                              autocomplete="off"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="24">
                  <el-form-item label="Судалгааны нэрийг бичнэ үү." prop="jobname">
                    <el-input prefix-icon="el-icon-edit"
                              type="textarea"
                              :rows="3"
                              v-model="personalForm.jobname"
                              autocomplete="off"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="24">
                  <el-form-item label="Мэдээлэл ашиглах зориулалтаа бичнэ үү." prop="purpose">
                    <el-input prefix-icon="fal fa-user"
                              type="textarea"
                              :rows="5"
                              v-model="personalForm.purpose"
                              autocomplete="off"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="24">
                  <el-upload
                      prop="file"
                      class="upload-file"
                      drag
                      action="https://beta.mris.mn/lambda/krud/upload"
                      :on-success="handleAvatarSuccess"
                      :before-upload="beforeAvatarUpload"
                      :file-list="fileList"
                      multiple>
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
                    <div class="el-upload__tip" slot="tip">jpg/png files with a size less than 500kb</div>
                  </el-upload>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="24">
                  <div class="info">
                    <span>Хүсэлт баталгаажсан мэдэгдэл хэрэглэгчийн бүртгэлтэй мэйл хаягаар очих ба бүтээгдэхүүнийг ТАТАЖ АВАХ эрх үүснэ.<br>Мэдэгдэл очсоноос хойш 7 хоногийн турш 3 удаа татах эрх үүснэ.</span><br>
                    <span class="danger">Албан тоот хүсэлтийг эх хувиар хүлээн авах хүртэл хүсэлт баталгаажихгүй болохыг анхаарна уу.</span><br>
                    <el-checkbox v-model="personalForm.checked">Худалдан авалтын нөхцлийг зөвшөөрч байна.</el-checkbox>
                  </div>
                </el-col>
              </el-row>
              <div class="home-search-buttonx vertical-center">
                <el-button type="primary"
                           icon="el-icon-upload"
                           @click="submitForm('personalForm')"
                           v-loading.fullscreen.lock="loading"
                >{{ 'save' | translate }}</el-button>
              </div>
            </el-form>
          </div>
        </div>
        <!-- dashboard content end-->
        <!--  dashboard-menu-->
        <div class="col-md-4">
          <div class="mob-nav-content-btn color2-bg init-dsmen fl-wrap"><i class="fal fa-bars"></i> Dashboard menu</div>
          <div class="clearfix"></div>
          <div class="fixed-bar fl-wrap" id="dash_menu">
            <CartSummary></CartSummary>
          </div>
          <div class="clearfix"></div>
        </div>
        </div>
        <!-- dashboard-menu  end-->
      </section>
      <!--  section  end-->
      <div class="limit-box fl-wrap"></div>
    </div>
    <!--content end-->
  </div>
</template>

<script>
import CartSummary from "../../components/CardSummary";

export default {
  name: 'ProfileForm',
  components: {
    CartSummary,
  },
  data() {
    return {
      loading: false,
      isSuccess: false,
      isError: false,
      personalForm: {
        type: '',
        company: '',
        user: '',
        purpose: '',
        jobname: '',
        file: '',
        checked: false
      },
      rules: {
        purpose: [
          { required: true, message: 'Please input purpose' },
          { min: 3, max: 5, message: 'Length should be 3 to 5', trigger: 'blur' }
        ],
        file: [
          { required: true, message: 'Please input file', trigger: 'blur' },
        ],
      }
    };
  },
  mounted() {
  },
  methods: {
    handleAvatarSuccess(res, file) {
      this.personalForm.file = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('File picture must be JPG format!');
      }
      if (!isLt2M) {
        this.$message.error('File picture size can not exceed 2MB!');
      }
      return isJPG && isLt2M;
    },
    submitForm(formName) {
      if (!this.personalForm.checked) {
        this.$alert('Худалдан авалтын нөхцөлөө зөвшөөрнө үү', 'Уучлаарай', {
          confirmButtonText: 'OK',
        });
      }
      this.$refs[formName].validate((valid) => {

        if (valid) {
          console.log(this.personalForm);
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  }
}
</script>