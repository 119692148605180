<template>
  <div class="fl-wrap ">
    <ul id="progressbar" class="no-list-style">
      <li class="active"><span class="tolt" data-microtip-position="top" :data-tooltip="languages == 'mn' ? `Төлбөрийн нөхцөл` : `Payment condition`">01.</span></li>
      <li class="active"><span class="tolt" data-microtip-position="top" :data-tooltip="languages == 'mn' ? `Төлбөр төлөх` : `Pay the fee`">02.</span></li>
      <!--                                    <li><span class="tolt" data-microtip-position="top" data-tooltip="Payment Method">03.</span></li>-->
      <li><span class="tolt" data-microtip-position="top" :data-tooltip="this.languages == 'mn' ? `Амжилттай` : `Success`">03.</span></li>
    </ul>
    <div class="bookiing-form-wrap block_box fl-wrap">
      <!--   list-single-main-item -->
      <div class="list-single-main-item fl-wrap hidden-section tr-sec">
        <div class="profile-edit-container">
          <div class="custom-form">
            <div class="list-single-main-item-title fl-wrap qpay-box">
              <h3>{{ 'transfer_treasury' | translate }}</h3>

              <ul class="txn-info">
                <li>
                  <strong>{{ 'transfer_account' | translate }}: </strong>
                  <span>1164537434</span>
                </li>
                <li>
                  <strong>{{ 'recipient' | translate }}: </strong>
                  <span>{{ 'nat_geological_survey' | translate }}</span>
                </li>
                <li>
                  <strong>{{ 'transaction_value' | translate }}: </strong>
                  <span>УГҮЗ-300</span>
                </li>
              </ul>
            </div>

            <div class="p-action">
              <router-link to="/payment" class="previous-form action-button back-form color2-bg prev-btn">
                <i class="fa fa-arrow-left"></i>
                {{ 'reg_back' | translate }}
              </router-link>

              <router-link to="/payment/status" class="btn float-btn color2-bg submit-btn next-btn" id="submit">
                {{ 'continued' | translate }} <i class="fa fa-arrow-right"></i>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PaymentTransaction',
  mounted() {
    window.initTowhub()
    window.initparallax();
  },
}
</script>