<template>
  <div id="wrapper">
    <!-- content-->
    <div class="content">
      <section class="gray-bg no-top-padding-sec" id="sec1">
        <div class="container">
          <div v-if="loading" class="loading-wrap">
            <div class="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <div class="breadcrumbs inline-breadcrumbs fl-wrap block-breadcrumbs">
            <a href="#">{{ 'home' | translate }}</a> <router-link to="/news">{{ 'article' | translate }}</router-link><span>{{ 'read_more' | translate }}</span>
<!--            <div  class="showshare brd-show-share color2-bg"> <i class="fal fa-share"></i> Share </div>-->
          </div>
          <div class="share-holder hid-share sing-page-share top_sing-page-share">
            <div class="share-container  isShare"></div>
          </div>
          <div  class="post-container fl-wrap">
            <div class="row">
              <!-- blog content-->
              <div class="col-md-8">
                <!-- article> -->
                <article class="post-article single-post-article">

                  <div class="list-single-main-item fl-wrap block_box">

                    <h2 class="post-opt-title" v-if="language.short == 'mn'">{{niitlel.publish_name_mn}}</h2>
                    <h2 class="post-opt-title" v-else>{{niitlel.publish_name_en}}</h2>
<!--                    <div class="post-author">-->
<!--                      <a href="#"><img src="images/avatar/5.jpg" alt=""><span>By , Alisa Noory</span></a>-->
<!--                    </div>-->
                    <div class="post-opt">
                      <ul class="no-list-style">
                        <li><i class="fal fa-calendar"></i> <span>{{niitlel.created_at | date}}</span></li>
<!--                        <li><i class="fal fa-eye"></i> <span>1,200</span></li>-->
                        <li><i class="fal fa-link"></i> <a :href="niitlel.link" target="_blank">PDF {{ 'link' | translate }}</a>
<!--                          , <a href="#">Design</a>-->
                        </li>
                      </ul>
                    </div>
                    <span class="fw-separator"></span>
                    <div class="clearfix"></div>

                      <div class="zohiogchiin_medeelel" >
                          <div :key="index" v-for="(lut, index) in lut_publishment_author">
                              <div v-if="language.short == 'mn'" style="margin-bottom: 8px">
                                  <b>{{lut.lastname_mn}} {{ lut.author_mn }}</b>
                                  <br>
                                  <span class="org-name-zohiogch">
                                      {{ lut.org_mn }}
                                  </span>
                              </div>

                              <div  v-else style="margin-bottom: 8px">
                                  <b> {{ lut.author_en }} {{lut.lastname_en}}</b>
                                  <br>
                                  <span class="org-name-zohiogch">
                                      {{ lut.org_en }}
                                  </span>
                              </div>
                          </div>
                      </div>
                      <div class="clearfix"></div>
                      <br>
                      <h4>Abstract</h4>
                      <br>

                    <p v-if="language.short == 'mn'" v-html="niitlel.publishing_mn"></p>
                    <p v-html="niitlel.publishing_en" v-else></p>
<!--                    <blockquote>-->
<!--                      <p> testset test tes</p>-->
<!--                    </blockquote>-->


                      <div class="zohiogchiin_medeelel" >
                          <div >
                              <div style="margin-bottom: 8px">
                                  <b>{{ 'published_language' | translate }}</b>
                                  <br>
                                  <span class="org-name-zohiogch">
                                    {{language.short === 'mn' ? niitlel.published_language : niitlel.language_en}}
                                  </span>
                              </div>
                              <div style="margin-bottom: 8px">
                                  <b>{{ 'keyword' | translate }}</b>
                                  <br>
                                  <span class="org-name-zohiogch">
                                    {{niitlel.keyword ? niitlel.keyword : '-'}}
                                  </span>
                              </div>
                              <div style="margin-bottom: 8px">
                                  <b>{{ 'dio_number' | translate }}</b>
                                  <br>
                                  <span class="org-name-zohiogch">
                                     {{niitlel.dio_num ? niitlel.dio_num : '-'}}
                                  </span>
                              </div>
                              <div style="margin-bottom: 8px">
                                  <b>{{ 'contact_email' | translate }}, {{ 'contact_phone' | translate }}</b>
                                  <br>
                                  <span class="org-name-zohiogch">
                                     {{niitlel.contact_email}} {{niitlel.contact_phone}}
                                  </span>
                              </div>

                          </div>
                      </div>


<!--                      <div id="add-review" class="add-review-box">-->
<!--                          &lt;!&ndash; Review Comment &ndash;&gt;-->
<!--                          <form id="add-comment" class="add-comment  custom-form" name="rangeCalc">-->
<!--                              <fieldset>-->
<!--                                  <div class="list-single-main-item_content fl-wrap">-->


<!--                                      <div class="row">-->

<!--                                          <div class="col-md-12">-->
<!--                                              <label>{{ 'published_language' | translate }}</label>-->
<!--                                              <input type="text" :value="niitlel.published_language" :disabled="true"/>-->
<!--                                          </div>-->
<!--                                      </div>-->
<!--                                      <div class="row">-->
<!--                                          <div class="col-md-6">-->
<!--                                              &lt;!&ndash;                              <div class="m-desc">&ndash;&gt;-->
<!--                                              &lt;!&ndash;                                Үндэсний геологи, эрдэс баялгийн мэдээллийн сан төсөл&ndash;&gt;-->
<!--                                              &lt;!&ndash;                              </div>&ndash;&gt;-->
<!--                                              <label>{{ 'keyword' | translate }}</label>-->
<!--                                              <input type="text" :value="niitlel.keyword" :disabled="true"/>-->
<!--                                          </div>-->
<!--                                          <div class="col-md-6">-->
<!--                                              <label>{{ 'dio_number' | translate }}</label>-->
<!--                                              <input type="text"  :value="niitlel.dio_num" :disabled="true"/>-->
<!--                                          </div>-->
<!--                                      </div>-->
<!--                                      <div class="row">-->
<!--                                          <div class="col-md-6">-->
<!--                                              &lt;!&ndash;                              <div class="m-desc">&ndash;&gt;-->
<!--                                              &lt;!&ndash;                                Үндэсний геологи, эрдэс баялгийн мэдээллийн сан төсөл&ndash;&gt;-->
<!--                                              &lt;!&ndash;                              </div>&ndash;&gt;-->
<!--                                              <label>{{ 'contact_email' | translate }}</label>-->
<!--                                              <input type="text" :value="niitlel.contact_email" :disabled="true"/>-->
<!--                                          </div>-->
<!--                                          <div class="col-md-6">-->
<!--                                              <label>{{ 'contact_phone' | translate }}</label>-->
<!--                                              <input type="text"  :value="niitlel.contact_phone" :disabled="true"/>-->
<!--                                          </div>-->
<!--                                      </div>-->


<!--                                      &lt;!&ndash;                          <div class="row">&ndash;&gt;-->
<!--                                      &lt;!&ndash;                            <div class="col-md-12">&ndash;&gt;-->
<!--                                      &lt;!&ndash;                              <label>{{ 'a_link' | translate }}????</label>&ndash;&gt;-->
<!--                                      &lt;!&ndash;                              <input type="text" :value="niitlel.link" :disabled="true"/>&ndash;&gt;-->
<!--                                      &lt;!&ndash;                            </div>&ndash;&gt;-->
<!--                                      &lt;!&ndash;                          </div>&ndash;&gt;-->

<!--                                      <div class="row">-->
<!--                                          <div class="col-md-12">-->

<!--                                          </div>-->
<!--                                      </div>-->
<!--                                  </div>-->
<!--                              </fieldset>-->
<!--                          </form>-->
<!--                      </div>-->
                  </div>

                </article>
                <!-- list-single-main-item -->
                <div class="list-single-main-item fl-wrap block_box" id="sec6">
                  <div class="list-single-main-item-title fl-wrap">
                    <h3>{{ 'magazine_information' | translate }}</h3>
                  </div>
                  <!-- Add Review Box -->
                  <div id="add-review" class="add-review-box">
                    <!-- Review Comment -->
                    <form id="add-comment" class="add-comment  custom-form" name="rangeCalc">
                      <fieldset>
                        <div class="list-single-main-item_content fl-wrap">
                          <div class="row">
                            <div class="col-md-12">
                              <label>{{ 'magazine_name' | translate }}</label>
                              <input type="text" :value="language.short === 'mn' ? niitlel.magazine_name : niitlel.magazine_name_en" :disabled="true"/>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-md-6">
<!--                              <div class="m-desc">-->
<!--                                Үндэсний геологи, эрдэс баялгийн мэдээллийн сан төсөл-->
<!--                              </div>-->
                              <label> {{ 'o_number' | translate }}</label>
                              <input type="text" :value="niitlel.volume_num" :disabled="true"/>
                            </div>
                            <div class="col-md-6">
                              <label>{{ 'page_num' | translate }}</label>
                              <input type="text"  :value="niitlel.page_num" :disabled="true"/>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6">
                              <!--                              <div class="m-desc">-->
                              <!--                                Үндэсний геологи, эрдэс баялгийн мэдээллийн сан төсөл-->
                              <!--                              </div>-->
                              <label>{{ 'issn' | translate }}</label>
                              <input type="text" :value="niitlel.issn" :disabled="true"/>
                            </div>
                            <div class="col-md-6">
                              <label>{{ 'eISSN_duygaar' | translate }}</label>
                              <input type="text"  :value="niitlel.eissn_number" :disabled="true"/>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-12">
                              <label>{{ 'hewlegdsen_on' | translate }}</label>
                              <input type="text" :value="niitlel.publishment_date | year" :disabled="true"/>
                            </div>

                          </div>
                          <div class="row">
                            <div class="col-md-6">
                              <!--                              <div class="m-desc">-->
                              <!--                                Үндэсний геологи, эрдэс баялгийн мэдээллийн сан төсөл-->
                              <!--                              </div>-->
                              <label>{{ 'hurliin_name_ognoo' | translate }}</label>
                              <input type="text" :value="niitlel.conference_name" :disabled="true"/>
                            </div>
                            <div class="col-md-6">
                              <label>{{ 'tusul_name_ognoo' | translate }}</label>
                              <input type="text"  :value="niitlel.project_name" :disabled="true"/>
                            </div>
                          </div>
<!--                          <div class="row">-->
<!--                            <div class="col-md-12">-->
<!--                              <label>{{ 'date' | translate }}</label>-->
<!--                              <input type="text" :value="niitlel.date | year" :disabled="true"/>-->
<!--                            </div>-->
<!--                          </div>-->
                          <div class="row">
                            <div class="col-md-6">
                              <!--                              <div class="m-desc">-->
                              <!--                                Үндэсний геологи, эрдэс баялгийн мэдээллийн сан төсөл-->
                              <!--                              </div>-->
                              <label>{{ 'country' | translate }}</label>
                              <input type="text" :value="language.short === 'mn' ? niitlel.countery : niitlel.countery_en" :disabled="true"/>
                            </div>
                            <div class="col-md-6">
                              <label>{{ 'city' | translate }}</label>
                              <input type="text"  :value="language.short === 'mn' ? niitlel.city_name : niitlel.city_name_en" :disabled="true"/>
                            </div>
                          </div>

<!--                          <div class="row">-->
<!--                            <div class="col-md-12">-->
<!--                              <label>{{ 'a_link' | translate }}????</label>-->
<!--                              <input type="text" :value="niitlel.link" :disabled="true"/>-->
<!--                            </div>-->
<!--                          </div>-->

                            <div class="row">
                                <div class="col-md-12">

                                </div>
                            </div>
                        </div>
                      </fieldset>
                    </form>
                  </div>
                  <!-- Add Review Box / End -->
                </div>
                <!-- list-single-main-item end -->
<!--                <article class="post-article single-post-article">-->
<!--                  <div class="list-single-main-item fl-wrap block_box">-->
<!--                    <div class="article-image">-->
<!--                      <img :src="IMAGE_URL+niitlel.magazine_photo" alt=""  v-if="niitlel.magazine_photo != ''">-->
<!--                      <img src="../../src/assets/no-image.jpeg" alt="" v-else>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </article>-->

<!--                <article class="post-article single-post-article">-->
<!--                  <div class="list-single-main-item fl-wrap block_box">-->
<!--                    <div class="zohiogchiin_medeelel">-->
<!--                      <table width="100%" v-if="this.selectIshlel == 4" >-->
<!--                        <thead>-->
<!--                        <tr>-->
<!--                          <th>{{ 'author' | translate }}</th>-->
<!--                          <th>{{ 'last_name' | translate }}</th>-->
<!--                          <th>{{ 'hariya_bailguullaga' | translate }}</th>-->
<!--                          <th>ORCID {{ 'o_number' | translate }}</th>-->
<!--                        </tr>-->
<!--                        </thead>-->
<!--                        <tbody>-->
<!--                        <tr :key="index" v-for="(lut, index) in lut_publishment_author">-->
<!--                          <td><div v-if="language.short == 'mn'">{{ lut.author_mn }}</div><div v-else>{{ lut.author_en }}</div> </td>-->
<!--                          <td><div v-if="language.short == 'mn'">{{ lut.lastname_mn }}</div><div v-else>{{ lut.lastname_en }}</div> </td>-->
<!--                          <td><div v-if="language.short == 'mn'">{{ lut.org_mn }}</div><div v-else>{{ lut.org_en }}</div> </td>-->
<!--                          <td>{{ lut.orcid }}</td>-->
<!--                        </tr>-->
<!--                        </tbody>-->
<!--                      </table>-->
<!--                    -->
<!--                    </div>-->
<!--                  </div>-->
<!--                </article>-->

                <article class="post-article single-post-article">

                  <div class="list-single-main-item fl-wrap block_box">
                      <div class="list-single-tags tags-stylwrap">
<!--                          <span class="tags-title"><i class="fas fa-tag"></i> {{ 'ishlel' | translate }} : </span>-->
<!--                          <div  class="ishlel" style="width: 50%">-->
<!--                              <el-select v-model="selectIshlel" placeholder="Сонгох" style="width: 100%">-->
<!--                                  <el-option-->
<!--                                          v-for="item in options"-->
<!--                                          :key="item.value"-->
<!--                                          :label="item.label"-->
<!--                                          :value="item.value">-->
<!--                                  </el-option>-->
<!--                              </el-select>-->
<!--                          </div>-->
<!--                          &lt;!&ndash;                     <div style="text-align: center">&ndash;&gt;-->
<!--                          &lt;!&ndash;                       <el-input type="textarea" id="copied-data" ref="textToCopy"  required :value="niitlel.publish_name_mn + ' ' + niitlel.magazine_name + ' ' + niitlel.dio_num + ' ' + niitlel.date" ></el-input>&ndash;&gt;-->
<!--                          &lt;!&ndash;                       <button type="button" class="el-button el-button&#45;&#45;primary el-button&#45;&#45;medium" @click="copyReferrer" style="margin: 20px 0;">&ndash;&gt;-->
<!--                          &lt;!&ndash;                         <span v-if="!isFavorite">Хуулах</span>&ndash;&gt;-->
<!--                          &lt;!&ndash;                         <span v-else>Хуулагдсан</span>&ndash;&gt;-->
<!--                          &lt;!&ndash;                       </button>&ndash;&gt;-->
<!--                          &lt;!&ndash;                     </div>&ndash;&gt;-->
<!--                          <div class="zohiogchiin_medeelel" v-if="this.selectIshlel == 1">-->
<!--                              <table width="100%" >-->
<!--&lt;!&ndash;                                  <thead>&ndash;&gt;-->
<!--&lt;!&ndash;                                  <tr>&ndash;&gt;-->
<!--&lt;!&ndash;                                      <th>{{ 'author' | translate }}</th>&ndash;&gt;-->
<!--&lt;!&ndash;                                      <th>{{ 'hariya_bailguullaga' | translate }}</th>&ndash;&gt;-->
<!--&lt;!&ndash;                                      <th>{{ 'hewlegdsen_on' | translate }}</th>&ndash;&gt;-->

<!--&lt;!&ndash;                                      <th>ORCID {{ 'o_number' | translate }}</th>&ndash;&gt;-->
<!--&lt;!&ndash;                                  </tr>&ndash;&gt;-->
<!--&lt;!&ndash;                                  </thead>&ndash;&gt;-->
<!--                                  <tbody>-->
<!--                                  <tr>-->
<!--                                      <td>-->
<!--                                          <div :key="index" v-for="(lut, index) in lut_publishment_author">-->
<!--                                              <div v-if="language.short == 'mn'" style="white-space: nowrap;">-->
<!--                                                  {{lut.lastname_mn}} {{ lut.author_mn }}, {{ lut.org_mn }}-->
<!--                                              </div>-->
<!--                                              <div v-else style="white-space: nowrap;">{{ lut.author_en }}  {{lut.lastname_en}}, {{ lut.org_en }}</div>-->
<!--                                          </div>-->
<!--                                      </td>-->
<!--                                      <td>-->
<!--                                          {{niitlel.date | year}} - {{niitlel.dio_num}}-->
<!--                                          <div v-if="language.short == 'mn'">{{niitlel.publish_name_mn}}</div><div v-else>{{niitlel.publish_name_en}}</div>-->

<!--                                          <div v-if="language.short == 'mn'">{{niitlel.magazine_name}}</div><div v-else>{{niitlel.magazine_name}}</div>-->

<!--                                      </td>-->


<!--                                  </tr>-->
<!--                                  </tbody>-->
<!--                              </table>-->
<!--                              &lt;!&ndash;                        <button type="button" class="el-button el-button&#45;&#45;primary el-button&#45;&#45;medium " @click="copyData" style="margin: 20px 0; float: right">&ndash;&gt;-->
<!--                              &lt;!&ndash;                          <span v-if="!isFavorite">Хуулах</span>&ndash;&gt;-->
<!--                              &lt;!&ndash;                          <span v-else>Хуулагдсан</span>&ndash;&gt;-->
<!--                              &lt;!&ndash;                        </button>&ndash;&gt;-->
<!--                          </div>-->
<!--                          <div class="zohiogchiin_medeelel" v-if="this.selectIshlel == 2">-->
<!--                              <table width="100%" >-->
<!--                                  <tbody>-->
<!--                                  <tr>-->
<!--                                      <td>-->
<!--                                          <div :key="index" v-for="(lut, index) in lut_publishment_author">-->
<!--                                              <div v-if="language.short == 'mn'" style="white-space: nowrap;">-->
<!--                                                  {{lut.lastname_mn}} {{ lut.author_mn }}, {{ lut.org_mn }}-->
<!--                                              </div>-->
<!--                                              <div v-else style="white-space: nowrap;">{{ lut.author_en }}  {{lut.lastname_en}}, {{ lut.org_en }}</div>-->
<!--                                          </div>-->
<!--                                      </td>-->
<!--                                      <td>{{niitlel.date | year}} - {{niitlel.dio_num}}<div v-if="language.short == 'mn'">{{niitlel.publish_name_mn}}</div><div v-else>{{niitlel.publish_name_en}}</div>-->
<!--                                     <div v-if="language.short == 'mn'">{{niitlel.magazine_name}}</div><div v-else>{{niitlel.magazine_name}}</div> </td>-->

<!--                                  </tr>-->
<!--                                  </tbody>-->
<!--                              </table>-->
<!--                              &lt;!&ndash;                        <button type="button" class="el-button el-button&#45;&#45;primary el-button&#45;&#45;medium " @click="copyData" style="margin: 20px 0;">&ndash;&gt;-->
<!--                              &lt;!&ndash;                          <span v-if="!isFavorite">Хуулах</span>&ndash;&gt;-->
<!--                              &lt;!&ndash;                          <span v-else>Хуулагдсан</span>&ndash;&gt;-->
<!--                              &lt;!&ndash;                        </button>&ndash;&gt;-->
<!--                          </div>-->
<!--                          <div class="zohiogchiin_medeelel" v-if="this.selectIshlel == 3">-->
<!--                              <table width="100%" >-->
<!--                                  <tbody>-->
<!--                                  <tr>-->
<!--                                      <td>-->
<!--                                          <div :key="index" v-for="(lut, index) in lut_publishment_author">-->
<!--                                              <div v-if="language.short == 'mn'" style="white-space: nowrap;">-->
<!--                                                  {{lut.lastname_mn}} {{ lut.author_mn }}, {{ lut.org_mn }}-->
<!--                                              </div>-->
<!--                                              <div v-else style="white-space: nowrap;">{{ lut.author_en }}  {{lut.lastname_en}}, {{ lut.org_en }}</div>-->
<!--                                          </div>-->
<!--                                      </td>-->
<!--                                      <td>{{niitlel.date | year}} - {{niitlel.volume_num}} - {{niitlel.dio_num}}<div v-if="language.short == 'mn'">{{niitlel.publish_name_mn}}</div><div v-else>{{niitlel.publish_name_en}}</div> <div v-if="language.short == 'mn'">{{niitlel.magazine_name}}</div><div v-else>{{niitlel.magazine_name}}</div> </td>-->

<!--                                  </tr>-->
<!--                                  </tbody>-->
<!--                              </table>-->
<!--                              &lt;!&ndash;                        <button type="button" class="el-button el-button&#45;&#45;primary el-button&#45;&#45;medium " @click="copyData" style="margin: 20px 0;">&ndash;&gt;-->
<!--                              &lt;!&ndash;                          <span v-if="!isFavorite">Хуулах</span>&ndash;&gt;-->
<!--                              &lt;!&ndash;                          <span v-else>Хуулагдсан</span>&ndash;&gt;-->
<!--                              &lt;!&ndash;                        </button>&ndash;&gt;-->
<!--                          </div>-->
<!--                          <div class="zohiogchiin_medeelel" v-if="this.selectIshlel == 4">-->
<!--                              <table width="100%" >-->
<!--                                  <tbody>-->
<!--                                  <tr>-->
<!--                                      <td>-->
<!--                                          <div :key="index" v-for="(lut, index) in lut_publishment_author">-->
<!--                                              <div v-if="language.short == 'mn'" style="white-space: nowrap;">-->
<!--                                                  {{lut.lastname_mn}} {{ lut.author_mn }}, {{ lut.org_mn }}-->
<!--                                              </div>-->
<!--                                              <div v-else style="white-space: nowrap;">{{ lut.author_en }}  {{lut.lastname_en}}, {{ lut.org_en }}</div>-->
<!--                                          </div>-->
<!--                                      </td>-->
<!--                                      <td>{{niitlel.volume_num}} - {{niitlel.dio_num}}<div v-if="language.short == 'mn'">{{niitlel.publish_name_mn}}</div><div v-else>{{niitlel.publish_name_en}}</div> </td>-->

<!--                                  </tr>-->
<!--                                  </tbody>-->
<!--                              </table>-->
<!--                              &lt;!&ndash;                        <button type="button" class="el-button el-button&#45;&#45;primary el-button&#45;&#45;medium " @click="copyData" style="margin: 20px 0;">&ndash;&gt;-->
<!--                              &lt;!&ndash;                          <span v-if="!isFavorite">Хуулах</span>&ndash;&gt;-->
<!--                              &lt;!&ndash;                          <span v-else>Хуулагдсан</span>&ndash;&gt;-->
<!--                              &lt;!&ndash;                        </button>&ndash;&gt;-->
<!--                          </div>-->
                      </div>

                      <div v-if="niitlel.publishment_number && (niitlel.map_element_type === 'polygon' || niitlel.map_element_type === 'point')">
                          <a :href="`https://webgis.mris.mn/?article_number=${niitlel.publishment_number}&article_type=${niitlel.map_element_type}`" class="top-bar-button" target="_blank"><i class="fas fa-map-marker-alt"></i><span>Газарзүйн холболт харах</span></a>

                          <div style="width: 100%;
    padding-bottom: 60%;
    margin: 10px 0px;
    position: relative;">
                              <iframe :src="`https://webgis.mris.mn/?article_number=${niitlel.publishment_number}&article_type=${niitlel.map_element_type}&iframe=true`" frameborder="0" style="width: 100%; height:  100%; position:absolute;"></iframe>
                          </div>
                      </div>

                  </div>

                </article>
                  <div class="clearfix"></div>
                  <br>
                  <h4 v-if="language.short === 'mn'">Тухайн зохиогчийн их уншигдсан нийтлэл</h4>
                  <h4 v-else>Most read articles by the same author(s)</h4>
                  <br>
                  <div class="clearfix"></div>
                  <div class="zohiogchiin_medeelel" >
                      <router-link :to="'/newsDetails/' + other.publishment_id" :key="index" v-for="(other, index) in uniqueOthers">
                          <div v-if="language.short == 'mn'" style="margin-bottom: 8px">
                              <b>{{other.mn_aouter }}</b>
                              <br>
                              <span class="org-name-zohiogch">
                                      {{ other.publish_name_mn }}
                                  </span>
                          </div>

                          <div  v-else style="margin-bottom: 8px">
                              <b>{{other.en_aouter }}</b>
                              <br>
                              <span class="org-name-zohiogch">
                                      {{ other.publish_name_en }}
                                  </span>
                          </div>
                      </router-link>
                  </div>


<!--                <article class="post-article single-post-article">-->
<!--                  <div class="list-single-main-item fl-wrap block_box">-->
<!--                    <div class="zohiogchiin_medeelel" style="text-align: center">-->
<!--                      <div class="row" v-if="this.selectIshlel == 1">-->
<!--                        <div class="col-md-12">-->
<!--                          <el-input type="textarea" id="copied-data" :value="niitlel.publish_name_mn + ' ' + niitlel.magazine_name + ' ' + niitlel.dio_num" ref="textToCopy"  required>-->
<!--                          </el-input>-->
<!--                          <button type="button" class="el-button el-button&#45;&#45;primary el-button&#45;&#45;medium" @click="copyReferrer" style="margin: 20px 0;">-->
<!--                            <span v-if="!isFavorite">Хуулах</span>-->
<!--                            <span v-else>Хуулагдсан</span>-->
<!--                          </button>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </article>-->
              </div>
              <!-- blog conten end-->
              <!-- blog sidebar -->
              <div class="col-md-4">
                <div class="box-widget-wrap fl-wrap fixed-bar">
                  <!--box-widget-item -->
                  <div class="box-widget-item fl-wrap block_box">
                    <div class="box-widget-item-header">
                      <h3>{{ 'other' | translate }}</h3>
                    </div>
                    <div class="box-widget  fl-wrap">
                      <div class="box-widget-content">
                        <!--widget-posts-->
                        <div class="widget-posts  fl-wrap">
                          <ul class="no-list-style">
                            <li :key="index" v-for="(art, index) in articles">
                              <div class="widget-posts-img">
                                <router-link  :to="'/newsDetails/' + art.id">
                                  <img :src="IMAGE_URL+art.magazine_photo" alt=""  v-if="art.magazine_photo != ''">
                                  <img src="../../src/assets/no-image.jpeg" alt="" v-else>
                                </router-link>
                              </div>
                              <div class="widget-posts-descr">
                                <h4><router-link :to="'/newsDetails/' + art.id">
                                  <div v-if="language.short == 'mn'">{{art.publish_name_mn}}</div>
                                  <div v-else>{{art.publish_name_en}}</div>
                                </router-link></h4>
                                <div class="geodir-category-location fl-wrap"><a href="#"><i class="fal fa-calendar"></i> {{art.created_at | date}}</a></div>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <!-- widget-posts end-->
                      </div>
                    </div>
                  </div>
                  <!--box-widget-item end -->
                  <!--box-widget-item -->
                  <div class="box-widget-item fl-wrap block_box">
                    <div class="box-widget-item-header">
                      <h3>{{ 'cat' | translate }}</h3>
                    </div>
                    <div class="box-widget fl-wrap">
                      <div class="box-widget-content">
                        <ul class="cat-item no-list-style">
                          <li :key="index" v-for="(count, index) in counts">
                            <a href="javascript:void(0);" v-if="language.short == 'mn'">{{ count.type_mn }}</a>
                            <a v-else>{{ count.type_en }}</a>
                            <span>{{ count.publishing_count }}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <!--box-widget-item end -->
                </div>
              </div>
              <!-- blog sidebar end -->
            </div>
          </div>
        </div>
      </section>
      <div class="limit-box fl-wrap"></div>
    </div>
    <!--content end-->
  </div>
</template>

<script>
import {
    GET_ARTICLE,
    GET_ARTICLE_BY_ID,
    IMAGE_URL,
    GET_ARTICLE_LUT_PUBLISHMENT_AUTHER_ID,
    GET_OTHER_EN,
    GET_OTHER_MN
} from "../graphql/queries";
import {mapGetters} from 'vuex'
export default {
  name: 'newsDetails',
  props: ['postId'],
  data() {
    return {
      loading: true,
      selectIshlel: 4,
      isFavorite: false,
      niitlel: {},
      authors: {},
      lut_publishment_author: [],
      IMAGE_URL: IMAGE_URL,
      articles: [],
      options: [
        {value: 1, label: '-APA  /American Psychological Association/'},
        {value: 2, label: '-MLA /Modern Language Association/'},
        {value: 3, label: '-Chicago /Chicago Manual of Style/'},
        {value: 4, label: '-Mongolian geoscientist сэтгүүлийн стандарт'},
      ],
      value: '',
      author_mn:'',
      result: {},
      counts: [],
        otherPage:1,
        others:[]
    }
  },
  computed: {
    ...mapGetters([
      'languages',
      'language',
      // ...
    ]),
      uniqueOthers() {
          let uniqueKeys = new Set();
          return this.others.filter(obj => {
              if (uniqueKeys.has(obj.publishment_id)) {
                  return false;
              } else {
                  uniqueKeys.add(obj.publishment_id);
                  return true;
              }
          });

      }
  },
  watch: {
    '$route'() {
      this.get_ARTICLE_BY_ID()
    }
  },
  methods: {
    getOtherArticle() {
      this.loading = true;
      this.$apollo.query({query: GET_ARTICLE}).then(({data}) => {
        this.articles = data.publishing;
        //console.log(this.articles)
        setTimeout(() => {
          this.loading = false;
        })
      })
    },
    getCount() {
      this.$http.get('/api/publishment-count-by-type').then(({data}) => {
        this.counts = data;
      })
    },
    get_ARTICLE_BY_ID() {
      this.$apollo.query({query: GET_ARTICLE_BY_ID, variables: {id: this.postId.toString()}}).then(({data}) => {
        this.niitlel = data.ds_publishment_view[0];
        //console.log(this.niitlel)

        // var arr = this.lut_publishment_author.map(row => ({
        //   data: row.author_mn,
        // }));
        // console.log(arr);
        // console.log(JSON.stringify(arr))

          this.getCount();

          this.get_ARTICLE_LUT_PUBLISHMENT_AUTHER_ID(this.niitlel.id);
      });
    },
    get_ARTICLE_LUT_PUBLISHMENT_AUTHER_ID(id) {
      this.$apollo.query({query: GET_ARTICLE_LUT_PUBLISHMENT_AUTHER_ID, variables: {id: id.toString()}}).then(({data}) => {
        this.lut_publishment_author = data.lut_publishment_author;
        //console.log(this.lut_publishment_author)
          this.others = [];
          this.lut_publishment_author.forEach(author=>{
              this.get_Other(author, id)
          })
      });
    },
    get_Other(author, id) {

        if(this.language.short === "mn"){
            this.$apollo.query({query: GET_OTHER_MN, variables: {

                    "author": author.author_mn,
                    "publishmentID": id.toString()
                }}).then(({data}) => {
                this.others.push(...data.ds_view_publishment_with_auters);

                //console.log(this.lut_publishment_author)
            });
        } else {
            this.$apollo.query({query: GET_OTHER_EN, variables: {

                    "author": author.author_en,
                    "publishmentID": id.toString()
                }}).then(({data}) => {
                this.others.push(...data.ds_view_publishment_with_auters);

                //console.log(this.lut_publishment_author)
            });
        }

    },
    copyReferrer() {
      let textToCopy = this.$refs.textToCopy.$el.querySelector('#copied-data')
      textToCopy.setAttribute('readonly', '',)
      textToCopy.select()
      document.execCommand("copy");
      this.isFavorite = true;
      // try {
      //   var successful = document.execCommand('copy');
      //   var msg = successful ? 'copied' : 'not copied';
      //   console.log(msg)
      //   this.isFavorite = true;
      // } catch (err) {
      //   console.log(msg)
      // }
    },
  },
  mounted() {
    window.initTowhub()
    window.initparallax();
    this.getOtherArticle();
    this.get_ARTICLE_BY_ID();

  },
}

</script>
