<template>
    <header id="header-wrap" :class="{sticky:stickyActive}">
        <div class="overlay-mobile" v-bind:class="{ active: isActive }" @click="showNav()"></div>
        <!-- Mobile Menu Start -->
        <transition name="mobile-nav">
            <div class="mobile-menu" v-if="isActive">
                <div class="mobile-main-title">
                    <i class="fas fa-bars"></i>
                    <span>Үндсэн цэс</span>
                </div>
                <ul>
                    <li @click="isActive = false">
                        <router-link to="/">{{ 'home' | translate }}</router-link>
                    </li>
                    <li @click="isActive = false">
                        <router-link to="/product-list">{{ 'list' | translate }}</router-link>
                    </li>
                    <li>
                       <router-link to="/archive-list">{{ 'archive' | translate }}</router-link>
                    </li>
                    <li @click="isActive = false">
                        <router-link to="/news">{{ 'article' | translate }}</router-link>
                    </li>
                    <li @click="isActive = false">
                        <router-link to="/contact">{{ 'contact_us' | translate }}</router-link>
                    </li>
                </ul>
                <div class="mobile-contact-title">
                    <i class="far fa-building"></i>
                    <span>Холбоо барих</span>
                </div>
                <div class="mobile-social">
                    <a href="https://twitter.com/" target="_blank">
                        <i class="fab fa-twitter"></i>
                    </a>
                    <a href="https://facebook.com/" target="_blank">
                        <i class="fab fa-facebook-f"></i>
                    </a>
                    <a href="mailto:info@gazar.gov.mn" target="_blank">
                        <i class="far fa-envelope"></i>
                    </a>
                </div>
                <div class="contact-address">
                    <i class="far fa-map"></i>
                    <span>Монгол Улс. Улаанбаатар хот 18072, Үйлдвэрчний Эвлэлийн гудамж 37, Үндэсний геологийн алба</span>
                </div>
                <div class="contact-address">
                    <i class="far fa-phone"></i>
                    <span>(976) 7018-1206 <br>(976) 7018-1206</span>
                </div>
                <a href="javascript:void(0)" class="mobile-close ">
                    <i class="fas fa-times" @click="showNav()"></i>
                </a>
            </div>
        </transition>
        <!-- Mobile Menu END -->
        <div class="top-bar container-md">
            <div class="top-bar-container">
                <!-- lang-wrap-->
                <LanguageSwitch/>
                <!-- lang-wrap end-->
                <!-- Top-bar login start -->
                <a href="/#/auth" v-if="userToken==null || userToken==false" class="top-bar-button top-login">
                    <i class="fal fa-user"></i>
                    <span>{{ 'login' | translate }}</span>
                </a>
                <!-- Top-bar login end -->
                <!-- Top-bar login start -->
                <router-link href="javascript:void(0)" v-else to="/profile" class="top-bar-button top-login">
                    <i class="fal fa-user"></i>
                    <span v-if="user">{{ user.login }}</span>
                </router-link>
                <!-- Top-bar login end -->
            </div>
        </div>

        <div class="main-header">
            <div class="main-header-container">
                <!-- logo-->
                <router-link class="logo-holder" to="/">
<!--                    <img alt="NGS" src="assets/images/main-logo.png">-->
                    <img alt="NGS" src="../assets/main-logo-mn.png" v-if="language.short == 'mn'">
                    <img alt="NGS" src="../assets/main-logo-en.png" v-else>
                </router-link><!-- logo end-->

                <!--  navigation -->
                <div class="nav-holder">
                    <nav class="main-menu">
                        <ul class="no-list-style">
                            <li>
                                <router-link to="/">{{ 'home' | translate }}</router-link>
                            <li>
                                <router-link to="/product-list">{{ 'list' | translate }}</router-link>
                            </li>
                            <li>
                               <router-link to="/archive-list">{{ 'archive' | translate }}</router-link>
                            </li>
                            <li>
                                <router-link to="/news">{{ 'article' | translate }}</router-link>
                            </li>
                            <li>
                                <router-link to="/contact">{{ 'contact_us' | translate }}</router-link>
                            </li>
                        </ul>
                    </nav>
                    <div class="cart-btn" @click="$router.push('/product-list/saved')">
                        <i class="fal fa-bookmark"></i>
                        <span class="cart-counter" id="favCounter">0</span>
                    </div>
                    <!--          <a class="add-list" href="dashboard-add-listing.html">-->
                    <!--            <span> {{ 'add' | translate }}</span>-->
                    <!--            <i class="fal fa-layer-plus"></i>-->
                    <!--          </a>-->
                    <div class="nav-mobile-btn" @click="showNav()">
                        <div class="nav-button">
                            <span></span><span></span><span></span>
                        </div>
                    </div>
                </div>
                <!-- navigation  end -->
                <!-- wishlist-wrap-->
                <div class="header-modal novis_wishlist">
                    <!-- header-modal-container-->
                    <div class="header-modal-container scrollbar-inner fl-wrap" data-simplebar>
                        <!--widget-posts-->
                        <div class="widget-posts  fl-wrap">
                            <ul v-if="products.length > 0" class="no-list-style">
                                <li v-for="item in products" :key="item.id">
                                    <div class="widget-posts-img">
                                        <router-link :to="`/product-detail/${item.id}`">
                                            <img :src="IMAGE_URL+details.photo" alt="" key="2"/>
                                        </router-link>
                                    </div>
                                    <div class="widget-posts-descr">
                                        <h4>
                                            <router-link :to="`/product-detail/${item.id}`">{{ item.title_mn }}</router-link>
                                        </h4>
                                        <div class="geodir-category-location fl-wrap"><a href="#"><i
                                            class="fas fa-map-marker-alt"></i> Өмнөговь аймаг, Цогт цэций сум</a></div>
                                        <div class="clear-wishlist"><i class="fal fa-times-circle"></i></div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <!--wishlist-wrap end -->
            </div>
        </div>
    </header>
</template>

<script>
import LanguageSwitch from "./languageSwitch"
import {mapGetters, mapActions} from "vuex";
import {IMAGE_URL} from "../graphql/queries";

export default {
    name: "AppHeader",
    components: {
        LanguageSwitch
    },
    data() {
        return {
            stickyActive: false,
            isActive: false,
            products: [],
            IMAGE_URL: IMAGE_URL,
        }
    },
    computed: {
        // mix the getters into computed with object spread operator
        ...mapGetters([
            'user',
            'userToken',
          'languages',
          'language',
        ])
    },
    mounted() {

        window.document.onscroll = () => {
            let header = document.getElementById('header-wrap');
            if (!this.$route.path.includes("auth")) {
                this.stickyActive = window.scrollY > header.offsetTop;
                if (this.stickyActive) {
                    this.stickyTrue(true);
                } else {
                    this.stickyTrue(false);
                }
            }
        }


        let counterEl = document.getElementById("favCounter");
        if (counterEl) {
            if (localStorage.getItem("favorites")) {
                let favorites = localStorage.getItem("favorites");
                let favArr = favorites.split(",");
                counterEl.innerHTML = favArr.length.toString();
            } else {
                counterEl.innerHTML = "0";
            }
        }
    },
    methods: {
        ...mapActions({setSticky: "setSticky"}),
        stickyTrue(val) {
            this.$store.commit('setSticky', val);
        },
        toggleStickyClass() {
            if (this.stickyActive == false) {
                return 'nav'
            } else {
                return 'sticky-nav'
            }
        },
        showNav() {
            this.isActive = !this.isActive;
        },
    }
}
</script>
