<template>
    <div id="wrapper">
        <div class="content listing-page">
            <div class="filter">
                <div class="list-main-wrap-header">
                    <div class="filter-row">
                        <div class="filter-item input">
                            <el-input :placeholder="'search_word' | translate" class="plain" @change="getProducts"
                                      v-model="filterData.search">
                                <i slot="prefix" class="el-input__icon fal fa-keyboard"></i>
                            </el-input>
                        </div>
                        <div class="filter-item select first">
                            <el-select class="plain" v-model="filterData.type" clearable @change="getProducts"
                                       :placeholder="'_type' | translate">
                                <el-option
                                    v-for="item in types"
                                    :key="item.t_name"
                                    :label="item.t_name"
                                    :value="item.id">
                                </el-option>
                                <i slot="prefix" class="el-input__icon el-icon-s-operation"></i>
                            </el-select>
                        </div>
                        <div class="filter-item select second">
                            <el-select class="plain long" v-model="filterData.aimag" clearable :placeholder="'province_city' | translate"
                                       @change="choiceAimag">
                                <el-option
                                    v-for="item in aimguud"
                                    :key="item.aimagname"
                                    :label="item.aimagname"
                                    :value="item.id">
                                </el-option>
                                <i slot="prefix" class="el-input__icon el-icon-location-information"></i>
                            </el-select>
                        </div>
                        <div class="filter-item select third">
                            <el-select class="plain long" v-model="filterData.sum" clearable :placeholder="'soum_district' | translate"
                                       @change="getProducts">
                                <el-option
                                    v-for="item in sumuud"
                                    :key="item.sumname"
                                    :label="item.sumname"
                                    :value="item.id">
                                </el-option>
                                <i slot="prefix" class="el-input__icon el-icon-location-information"></i>
                            </el-select>
                        </div>
                        <div class="filter-item select fourth">
                            <el-select class="plain" v-model="filterData.source" clearable :placeholder="'eh_surwalj' | translate"
                                       @change="getProducts">
                                <el-option
                                    v-for="item in sources"
                                    :key="item.data_source_mn"
                                    :label="item.data_source_mn"
                                    :value="item.id">
                                </el-option>
                                <i slot="prefix" class="el-input__icon el-icon-user"></i>
                            </el-select>
                        </div>
                        <div class="reset-btn">
                            <a href="javascript:void(0)" @click="cleanFilter">
                                <i class="el-icon-refresh"></i>
                                <span>{{ 'clean' | translate }}</span>
                            </a>
                        </div>
                        <div class="filter-row-left"></div>
                        <div class="filter-row-right"></div>
                    </div>
                </div>

            </div>
            <div class="listing-page-content">
                <div class="p-list">
                    <div class="p-list-wrap">
                        <div :class="`p-list-header ${sticky}`">
                            <div class="list-main-wrap-title">
                                <h2>{{ 'result' | translate }} : <span>{{ 'showAll' | translate }} ({{ total }}) </span></h2>
                            </div>

                            <div class="change-layout">
                                <el-pagination
                                    @current-change="paginate"
                                    layout="prev, pager, next"
                                    :page-size="15"
                                    :total="total">
                                </el-pagination>
                                <el-select size="small" v-model="filterData.sort" :placeholder="'_order' | translate"
                                           @change="getProducts">
                                    <el-option
                                        v-for="item in sortOptions"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                                <el-tooltip :content="'thumbnail' | translate" placement="top"
                                            :class="`change-layout-btn ${viewType === 0 ? 'active-layout-btn' : ''}`">
                                    <a href="javascript:void(0)" @click="viewType=0">
                                        <i class="fal fa-th"></i>
                                    </a>
                                </el-tooltip>
                                <el-tooltip :content="'list_view' | translate" placement="top">
                                    <a href="javascript:void(0)" @click="viewType=1"
                                       :class="`change-layout-btn ${viewType === 1 ? 'active-layout-btn' : ''}`">
                                        <i class="fal fa-list"></i>
                                    </a>
                                </el-tooltip>
                            </div>
                        </div>
                        <div class="p-list-wrap-inner no-saved">
                            <p-list v-if="products.length > 0" :fTypes="types" :total="total"
                                    :products="products" :layout="viewType"></p-list>
                            <div class="no-product" v-else>
                                <i class="far fa-meh"></i>
                                <div class="no-product-text">
                                    <p>{{ 'no_product_title_list' | translate }}</p>
                                    <span>{{ 'no_product_description_list' | translate }}</span>
                                </div>
                            </div>

                            <div v-if="loading" class="loading-wrap">
                                <div class="lds-ellipsis">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="p-map">
                    <WebMap :filterData="filterData"/>
                </div>
            </div>
            <div class="limit-box fl-wrap"></div>
        </div>
    </div>
</template>

<script>
import WebMap from "../map/WebMap";
import pList from "../components/p_list";
import {mapGetters} from "vuex";

export default {
    components: {
        WebMap,
        pList
    },
    data() {
        return {
            products: [],
            total: 0,
            loading: true,
            aimguud: [],
            sumuud: [],
            types: [],
            sources: [],
            currentPage: 1,
            q: '',
            filterData: {
                type: '',
                aimag: '',
                sum: '',
                source: '',
                sort: '1',
                search: ''
            },
            sortOptions: [
                {
                    value: '1',
                    label: 'Сүүлд нэмэгдсэн'
                }, {
                    value: '2',
                    label: 'Эхэнд нэмэгдсэн'
                }, {
                    value: '3',
                    label: 'Үнэ өсөхөөр'
                }, {
                    value: '4',
                    label: 'Үнэ буурахаар'
                }
            ],
            viewType: 0,
        }
    },
    computed: {
        // mix the getters into computed with object spread operator
        ...mapGetters({sticky: "sticky"})
    },
    created() {
        this.getType();
        this.getAimag();
        this.getSource();
    },
    mounted() {
        window.initTowhub()
        window.initparallax();

      if(this.$route.query.ref === 'home'){
        this.filterData.search = this.$route.query.q;
        this.filterData.aimag = this.$route.query.region;
        this.filterData.type = this.$route.query.type;
        this.getProducts();
      }else{
        this.getProducts();
      }
    },
    methods: {
        closePage() {
            this.pageName = "";
            this.showPage = false;
        },

        getProducts() {
            this.loading = true;
            this.$http.post(`/api/products?page=${this.currentPage}`, this.filterData).then(({data}) => {
                this.products = data.data;
                // console.log(this.products)
                this.total = data.total
                setTimeout(() => {
                    this.loading = false;
                }, 1000)
            })
        },

        getType() {
            this.$http.get('/api/type').then(({data}) => {
                this.types = data;
            })
        },

        getAimag() {
            this.$http.get('/api/aimag').then(({data}) => {
                this.aimguud = data;
            })
        },

        choiceAimag() {
            this.getSum(this.filterData.aimag);
            this.getProducts()
        },

        getSum(id) {
            this.$http.get('/api/sum/' + id).then(({data}) => {
                this.sumuud = data;
            })
        },

        getSource() {
            this.$http.get('/api/source').then(({data}) => {
                this.sources = data;
            })
        },

        paginate(p) {
            this.currentPage = p;
            this.getProducts();
        },

        cleanFilter() {
            this.filterData = {
                type: '',
                aimag: '',
                sum: '',
                source: '',
                sort: '1'
            };
            this.getProducts();
        },
    }
}
</script>
