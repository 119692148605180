<template>
    <div class="auth-form box-shadow">
        <div class="auth-form-layer">
            <div class="auth-form-layer-title">
                <i class="fas fa-user-plus"></i>
                <span>{{ 'register_text' | translate }}</span>
            </div>
            <div class="auth-form-layer-register">
                <router-link to="/auth/register-citizen" class="auth-register-option">
                    <div class="auth-reg-icon">
                        <i class="fas fa-user-plus"></i>
                    </div>
                    <p class="auth-reg-text">
                        {{ 'reg_citizen' | translate }}
                    </p>
                </router-link>
                <router-link to="/auth/register-organization" class="auth-register-option">
                    <div class="auth-reg-icon">
                        <i class="fas fa-building"></i>
                    </div>
                    <p class="auth-reg-text">
                        {{ 'reg_organization' | translate }}
                    </p>
                </router-link>
            </div>
            <div class="auth-form-layer-bottom">
                <router-link to="/auth" class="btn-register">
                    <i class="fal fa-sign-in-alt"></i>
                    <span>{{ 'login_text' | translate }}</span>
                </router-link>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "Register",
    data() {
        return {}
    }
}
</script>
