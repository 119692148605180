<template>
    <div :class="`p-list-content scrollable ${layout === 1 ? 'product-list' : ''}`">
      <product v-for="item in products"
               :key="item.id"
               :fTypes="fTypes"
               :product="item"
               :layout="layout"
               :remove-item="removeItem"/>
    </div>
</template>

<script>
import Product from "../components/ProductItem";

export default {
  name: "p_list",
  props: ['products', 'fTypes', "total", "layout", "removeItem"],
  components: {Product},
  // data() {
  //   return {
  //     productList: []
  //   }
  // },
  //
  // created() {
  //   this.productList = this.products;
  // },
  //
  // watch: {
  //   products(val){
  //     this.productList = val;
  //   }
  // },
}
</script>
