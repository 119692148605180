<template>
  <div class="download-status">
    is done
  </div>
</template>

<script>
export default {
  name: "Download"
}
</script>