<template>
  <div class="col-md-8">
    <div class="dashboard-title fl-wrap">
      <h3>{{ '_update_phone' | translate }} </h3>
    </div>
    <!-- profile-edit-container-->
    <div class="profile-edit-container fl-wrap block_box">
      <el-form class="profile-form-container" :model="phoneForm" :rules="rules" ref="phoneForm">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item :label="'_password' | translate" prop="Password">
              <el-input :placeholder="'_signin_password' | translate"
                        prefix-icon="fal fa-lock"
                        type="password"
                        v-model="phoneForm.Password"
                        autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item :label="'_email' | translate" prop="NewLoginName">
              <el-input :placeholder="'_email' | translate"
                        prefix-icon="fal fa-envelope"
                        v-model="phoneForm.NewLoginName"
                        autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="home-search-button">
          <el-button type="primary" icon="el-icon-upload" @click="submitForm('phoneForm')">
            {{ '_update_phone_send' | translate }}
          </el-button>
        </div>
      </el-form>
      <div class="login-msg">
        <el-alert v-if="loading" class="auth-alert"
                  title="Түр хүлээнэ үү, Хүсэлт илгээгдэж байна"
                  type="warning">
        </el-alert>
        <el-alert v-if="isSuccess" class="auth-alert"
                  title="Амжилттай бүртгэгдлээ. Солисон мэдээллээрээ нэвтэрч орно уу"
                  type="success">
        </el-alert>
        <el-alert v-if="isError" class="auth-alert"
                  :title="errorMsg"
                  type="error">
        </el-alert>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ChangePhone",
  data() {
    return {
      loading: false,
      isSuccess: false,
      isError: false,
      errorMsg:'Алдаа гарлаа',
      phoneForm: {
        Login: null,
        Password: null,
        NewLoginName: null
      },
      rules: {
        Password: [
          {required: true, message: 'Please input password', trigger: 'blur'}
        ],
        NewLoginName: [
          {required: true, message: 'Please input login email', trigger: 'blur'}
        ],
      }
    }
  },
  computed: {
    ...mapGetters([
      'user'
    ])
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.phoneForm.Login = this.user.login;

          this.loading = true;
          this.isSuccess = false;
          this.isError = false;

          this.$http.post('/api/changeLogin', this.phoneForm).then(({data}) => {

            console.log(data);
            if (data.status) {
              this.loading = false;
              this.isError = false;
              this.isSuccess = true;
              this.msg = data.msg;
              setTimeout(() => {
                this.$router.push("/")
                // window.location.reload();
              }, 1000);

            } else {
              this.loading = false;
              this.isError = true;
              this.errorMsg = data.msg;
              // }, 1000);
            }

            // eslint-disable-next-line no-unused-vars
          }).catch(e => {
            setTimeout(() => {
              this.loading = false;
              this.isError = true;
              this.isSuccess = false;
              this.msg = "Алдаа гарлаа";
            }, 1000);
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  }
}
</script>