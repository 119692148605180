export const checkBasket = (vm, id) => {
    if (localStorage.getItem("basket")) {
        let favorites = localStorage.getItem("basket");
        let favArr = favorites.split(",");
        let isExist = favArr.find((item) => item === id);
        if (isExist) {
            vm.isBasket = true;
        } else {
            vm.isBasket = false;
            if (vm.removeItem) {
                vm.removeItem(id);
            }
        }
    } else {
        vm.isBasket = false;
        if (vm.removeItem) {
            vm.removeItem(id);
        }
    }
}

export const clearBasketStorage = ()=>{
    localStorage.removeItem("basket");
}

export const getBasketItems = () => {
    let counterEl = document.getElementById("basketCounter");

    if (localStorage.getItem("basket")) {
        let items = localStorage.getItem("basket");
        let itemArr = items.split(",");
        counterEl.innerHTML = itemArr.length.toString();
        return items;
    }
    return "";
}

export const toggleBasket = (id) => {
    // let counterEl = document.getElementById("basketCounter");
    if (localStorage.getItem("basket")) {
        let favorites = localStorage.getItem("basket");
        let favArr = favorites.split(",");
        let isExist = favArr.find((item) => item === id);
        if (isExist) {
            favArr = favArr.filter((item) => item != id);
        } else {
            favArr.push(id);
        }
        // counterEl.innerHTML = favArr.length.toString();
        let favStr = favArr.join(",");
        localStorage.setItem("basket", favStr);
    } else {
        let favArr = [id];
        // counterEl.innerHTML = favArr.length.toString();
        let favStr = favArr.join(",");
        localStorage.setItem("basket", favStr);
    }
}

export const checkFavorites = (vm, id) => {
    if (localStorage.getItem("favorites")) {
        let favorites = localStorage.getItem("favorites");
        let favArr = favorites.split(",");
        let isExist = favArr.find((item) => item === id);
        if (isExist) {
            vm.isFavorite = true;
        } else {
            vm.isFavorite = false;
            if (vm.removeItem) {
                vm.removeItem(id);
            }
        }
    } else {
        vm.isFavorite = false;
        if (vm.removeItem) {
            vm.removeItem(id);
        }
    }
}

export const toggleFavorites = (vm, id) => {
    let counterEl = document.getElementById("favCounter");
    if (localStorage.getItem("favorites")) {
        let favorites = localStorage.getItem("favorites");
        let favArr = favorites.split(",");
        let isExist = favArr.find((item) => item === id);
        if (isExist) {
            favArr = favArr.filter((item) => item !== id);
        } else {
            favArr.push(id);
        }
        counterEl.innerHTML = favArr.length.toString();
        let favStr = favArr.join(",");
        localStorage.setItem("favorites", favStr);
    } else {
        let favArr = [id];
        counterEl.innerHTML = favArr.length.toString();
        let favStr = favArr.join(",");
        localStorage.setItem("favorites", favStr);
    }
    checkFavorites(vm, id);
}