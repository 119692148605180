<template>
  <div class="cart-details-wrap fl-wrap ch-summary">
    <h3>{{ '_my_orders' | translate }}</h3>

    <div class="cart-details fl-wrap block_box">
      <div v-for="item in basketItems" :key="item.id" class="cart-details_header">
        <router-link :to="`/product-detail/${item.id}`" class="widget-posts-img"><img :src="IMAGE_URL+item.photo"
                                                                                      class="respimg" alt="">
        </router-link>
        <div class="widget-posts-descr">
          <h4>
            <router-link :to="`/product-detail/${item.id}`">{{ item.title_mn }}</router-link>
          </h4>
          <div class="geodir-category-location fl-wrap">{{ item.price_mnt }}₮</div>
        </div>
      </div>

      <div class="cart-details_text">
        <ul class="cart_list no-list-style">
          <li>{{ 'fileType' | translate }}<span>PDF, GIS</span></li>
          <li>{{ 'quantity' | translate }}: <span>{{ basketItems.count }}</span></li>
<!--          <li>{{ 'totalSize' | translate }} <span>500mb</span></li>-->
        </ul>
      </div>
    </div>

    <div class="cart-total color3-bg fl-wrap">
      <span class="cart-total_item_title">{{ 'total' | translate }}:</span>
      <b>{{ totalPrice }}₮</b>
    </div>
  </div>
</template>

<script>
import {IMAGE_URL} from "../graphql/queries";
import {mapGetters, mapActions} from "vuex";

export default {
  name: "CardSummary",
  data() {
    return {
      IMAGE_URL: IMAGE_URL,
    }
  },
  computed: {
    ...mapGetters([
      'basketItems',
      'totalPrice',
      'languages',
      'language',
      // ...
    ])
  },

  methods: {
    ...mapActions({
      setBasketItems: "setBasketItems"
    })
  }
}
</script>
