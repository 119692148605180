<template>
    <div class="element-item">
        <router-link :to="`/product-detail/${product.id}`" class="item-img">
            <img src="images/all/62.jpg" alt="">
        </router-link>
        <a href="javascript:void(0)" :class="`card-product-favorite_btn yellow isotope ${isFavorite ? 'active' : ''}`"
           @click="toggleFavorites">
            <i class="fal fa-bookmark"></i>
            <span v-if="!isFavorite">{{ 'save' | translate }}</span>
            <span v-else>{{ 'saved' | translate }}</span>
        </a>
        <div class="card-product-date isotope">
            <i class="fal fa-clock"></i>
            <span>{{ product.release_date == null ? 'хоосон' : product.release_date.substr(0, 10) }}</span>
<!--            <span>{{ product.title_mn_pro }}</span>-->
        </div>
        <div class="item-price" v-if="language.short == 'mn'">
            {{ product.price_mnt }}₮
        </div>
        <div class="item-price" v-else>
             {{ product.price_en }}$
        </div>
        <div class="item-content">
            <h3 class="title" v-if="this.$store.state.i18n.locale === 'mn'">
                <template v-if="product.title_mn.length > 70">{{ product.title_mn.substr(0, 70) }}...</template>
                <template v-else>{{ product.title_mn }}</template>
            </h3>
            <h3 class="title" v-else>
                <template v-if="product.title_en.length > 70">{{ product.title_en.substr(0, 70) }}...</template>
                <template v-else>{{ product.title_en }}</template>
            </h3>
            <div class="item-location">
                <i class="fas fa-map-marker-alt"></i>
                <span></span>
            </div>
            <p class="description">
                <template v-if="product.title_mn.length > 110">{{ product.description.substr(0, 110) }}...</template>
                <template v-else>{{ product.title_mn }}</template>
            </p>
        </div>
        <div class="facilities-list">
            <div class="facilities-list-title">{{ 'extra_info' | translate }}:</div>
            <ul class="no-list-style">
                <li data-microtip-position="top" :data-tooltip="'a4' | translate" class="tolt">
                    <i class="fas fa-file-alt"></i>
                </li>
                <li data-microtip-position="top" :data-tooltip="'_pdf' | translate" class="tolt">
                    <i class="fas fa-file-pdf"></i>
                </li>
                <li data-microtip-position="top" :data-tooltip="'imageFile' | translate" class="tolt">
                    <i class="far fa-images"></i>
                </li>
            </ul>
        </div>
        <div class="item-footer">
            <router-link to="" class="item-category">
                <div :class="`file-type-icon ${getTypeIconColor(product.product_file_type)}`">
                    <i :class="getTypeIcon(product.product_file_type)"></i>
                </div>
                <span>{{ getTypeName(product.product_file_type) }}</span>
            </router-link>
            <div class="item-meta">
                <i class="fal fa-envelope"></i>
                <i class="fal fa-map-marker-alt"></i>
                <i class="fal fa-search-plus"></i>
            </div>
        </div>
    </div>
</template>

<script>
import {checkFavorites, toggleFavorites} from "../utils/helper";
import {mapGetters} from "vuex";

export default {
    props: ["product", "removeItem", "fTypes", "layout"],
    data() {
        return {
            isFavorite: false,
        };
    },
    mounted() {
        this.checkFavorites();
    },
    computed: {
      ...mapGetters([
        'languages',
        'language',
        // ...
      ])
    },
    methods: {
        toggleFavorites() {
            toggleFavorites(this, this.product.id.toString());
        },
        checkFavorites() {
            checkFavorites(this, this.product.id.toString())
        },
        getTypeName(val) {
            let type = this.$props.fTypes.find(item => item.id == val)
            if (type) {
                return type.t_name;
            }
            return "";
        },
        getTypeIcon(val) {
            switch (val) {
                case 1:
                    return 'fas fa-file-alt';
                case 2:
                    return 'fas fa-bezier-curve';
                case 3:
                    return 'far fa-images';
                case 4:
                    return 'fas fa-atlas';
            }
        },

        getTypeIconColor(val) {
            switch (val) {
                case 1:
                    return 'document';
                case 2:
                    return 'vector';
                case 3:
                    return 'web-images';
                case 4:
                    return 'geo';
            }
        },
        getProvince(val) {
            console.log(val);
        }
    },
};
</script>
