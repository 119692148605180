import Vue from "vue";
import Router from "vue-router";
import $ from 'jquery'

Vue.use(Router);

let base = "/";

if (location.pathname && location.pathname != "/") {
    base = location.pathname
        .split("/")
        .slice(0, -1)
        .join("/");
}

let routes = [
    {
        path: "/",
        component: () => import("./pages/Home"),
    },
    {
        path: "/product-list",
        component: require("./pages/ProductList").default,
    },
    {
        path: "/archive-list",
        component: require("./pages/ArchiveList").default,
    },
    {
        path: "/product-list/saved",
        component: require("./pages/ProductSaved").default,
    },
    {

        path: "/product-detail/:id",
        component: require("./pages/ProductDetail").default,
        name: "ProductDetail",
        props: true,
    },
    {

        path: "/archive-detail/:id",
        component: require("./pages/archiveDetail").default,
        name: "archiveDetail",
        props: true,
    },
    {
        path: "/cart",
        component: require("./pages/Cart").default,
    },
    {
        path: "/payment",
        component: require("./pages/Payment/IndexPayment").default,
        children: [
            {
                path: "/",
                component: require("./pages/Payment/PaymentType").default,
            },
            {
                path: "/payment/qpay/transaction",
                component: require("./pages/Payment/QpayPaymentTransaction").default,
            },
            {
                path: "/payment/swift/transaction",
                component: require("./pages/Payment/SwiftPaymentTransaction").default,
            },
            {
                path: "/payment/status",
                component: require("./pages/Payment/PaymentStatus").default,
            },
        ]
    },
    {
        path: "/news",
        component: require("./pages/NewsList").default,
    },
    {
        path: "/newsDetails/:postId",
        component: require("./pages/NewsDetail").default,
        name: "newsDetails",
        props: true,
    },
    {
        path: "/news-by-number/:postId",
        component: require("./pages/NewsDetailByNumber.vue").default,
        name: "newsDetails",
        props: true,
    },
    {
        path: "/contact",
        component: require("./pages/Contact").default,
    },
    {
        path: "/order_veiw",
        component: require("./pages/OrderView").default,
    },

    {
        path: "/profile",
        component: require("./pages/Profile/IndexProfile").default,
        children: [
            {
                path: "/",
                component: require("./pages/Profile/Profile").default,
            },
            {
                path: "/profile/password",
                component: require("./pages/Profile/ChangePassword").default,
            },
            {
                path: "/profile/phone",
                component: require("./pages/Profile/ChangePhone").default,
            },
            {
                name: "order-list",
                path: "/profile/order-list",
                component: require("./pages/Profile/Orderlist").default,
            },
        ]
    },
    {
        path: "/company-form",
        component: require("./pages/Profile/CompanyForm").default,
    },
    {
        path: "/personal-form",
        component: require("./pages/Profile/PersonalForm").default,
    },
    {
        path: "/faq",
        component: require("./pages/Faq").default,
    },
    {
        path: "/about",
        component: require("./pages/About").default,
    },
    {
        path: "/copyright",
        component: require("./pages/Copyright").default,
    },
    {
        path: "/help",
        component: require("./pages/help").default,
    },
    {
        path: "/ask_question",
        component: require("./pages/Ask_questions").default,
    },
    {
        path: "/service",
        component: require("./pages/Terms_service").default,
    },

    //auth
    {
        path: "/auth",
        component: require("./pages/Auth/Authentication").default,
        children: [
            {
                path: "/",
                component: require("./pages/Auth/Login").default,
            },
            {
                path: "/auth/register",
                component: require("./pages/Auth/Register").default,
            },
            {
                path: "/auth/register-citizen",
                component: require("./pages/Auth/RegisterCitizen").default,
            },
            {
                path: "/auth/register-organization",
                component: require("./pages/Auth/RegisterOrganization").default,
            },
            {
                path: "/auth/forgot-password",
                component: require("./pages/Auth/ForgotPassword").default,
            },
        ]
    },

    //Download
    {
        path: "/download/progress/:id",
        component: require("./pages/Download").default,
    },
    {
        path: "/download/status/:id",
        component: require("./pages/DownloadStatus").default,
    },
    {
        path: "/archive_payment",
        component: require("./pages/archive_payment").default,
    },
];

export default new Router({
    base,
    routes,
    history: true,
    linkActiveClass: "act-link",
    scrollBehavior() {
        $("html,body").animate({
            scrollTop: 0
        });
    }
});
