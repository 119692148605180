<template>
  <div class="auth-form box-shadow">
    <div class="auth-form-layer">
      <div class="auth-form-layer-title-register">
        <i class="fas fa-user-plus"></i>
        <div class="register-user-title">
          <span>{{ 'register_text' | translate }}</span>
          <span class="sub-title">{{ 'reg_citizen' | translate }}</span>
        </div>
      </div>

      <el-form ref="registerFormRef" class="auth-form-layer-login register-citizen" :rules="rules"
               @keypress.enter.native="submitRegister()"
               label-position="top" size="small" :model="credentials">
        <el-form-item :label="'reg_direction' | translate" prop="direction">

          <el-select v-model="credentials.direction" :placeholder="'reg_direction_placeholder' | translate"
                     style="width: 100%">
            <el-option v-for="item in directions"
                       :key="item.value"
                       :label="`${language.short == 'mn' ? item.label_mn : item.label_en}`"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="'reg_organization_name' | translate" prop="org_name">
          <el-input prefix-icon="el-icon-office-building"
                    :placeholder="'reg_organization_name_placeholder' | translate"
                    type="text"
                    v-model="credentials.org_name"></el-input>
        </el-form-item>
        <el-form-item :label="'reg_position' | translate" prop="org_position">
          <el-input prefix-icon="el-icon-suitcase"
                    :placeholder="'reg_position_placeholder' | translate"
                    type="text"
                    v-model="credentials.org_position"></el-input>
        </el-form-item>
        <el-form-item label="ORCID" prop="orcid">
          <el-input prefix-icon="el-icon-info"
                    :placeholder="'reg_orcid_placeholder' | translate"
                    type="text"
                    v-model="credentials.orcid"></el-input>
        </el-form-item>
        <el-form-item :label="'reg_surname' | translate" prop="last_name">
          <el-input prefix-icon="el-icon-user"
                    :placeholder="'reg_surname_placeholder' | translate"
                    type="text"
                    v-model="credentials.last_name"></el-input>
        </el-form-item>
        <el-form-item :label="'reg_first_name' | translate" prop="first_name">
          <el-input prefix-icon="el-icon-s-custom"
                    :placeholder="'reg_first_name_placeholder' | translate"
                    type="text"
                    v-model="credentials.first_name"></el-input>
        </el-form-item>
        <el-form-item :label="'mail' | translate" prop="login">
          <el-input prefix-icon="el-icon-message"
                    :placeholder="'reg_email_placeholder' | translate"
                    type="email" autocomplete="off"
                    v-model="credentials.login"></el-input>
        </el-form-item>
        <el-form-item :label="'reg_phone' | translate" prop="phone">
          <el-input prefix-icon="el-icon-phone"
                    type="number"
                    :placeholder="'reg_phone_placeholder' | translate"
                    v-model="credentials.phone"></el-input>
        </el-form-item>
        <el-form-item :label="'reg_gender' | translate" prop="gender">
          <el-radio-group v-model="credentials.gender">
            <el-radio :label="1">{{ 'reg_gender_male' | translate }}</el-radio>
            <el-radio :label="2">{{ 'reg_gender_female' | translate }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="'reg_password' | translate" prop="passwd">
          <el-input type="password"
                    prefix-icon="el-icon-key"
                    :placeholder="'reg_password_placeholder' | translate"
                    :show-password=true
                    autocomplete="off"
                    v-model="credentials.passwd"></el-input>
        </el-form-item>
        <el-form-item :label="'reg_password_repeat' | translate" prop="passwdCheck">
          <el-input type="password"
                    prefix-icon="el-icon-key"
                    :placeholder="'reg_password_repeat_placeholder' | translate"
                    :show-password=true
                    autocomplete="off"
                    v-model="credentials.passwdCheck"></el-input>
        </el-form-item>
        <el-form-item :label="'reg_term_label' | translate" prop="termChecked">
          <el-checkbox v-model="credentials.termChecked" :label="'reg_term_agree' | translate"></el-checkbox>
          <el-button type="text" @click="showTerm = true" class="show-term-btn"> ({{
              'reg_term' | translate
            }})
          </el-button>
        </el-form-item>
        <div class="form-button-container">
          <el-button :disabled="loading" type="primary" @click="submitRegister()" size="medium"
                     style="width: 100%">
            <template v-if="loading">
              <i class="el-icon-loading"></i>{{ '_sending' | translate }}
            </template>
            <template v-else>
              <i class="fas fa-user-plus"></i>{{ 'register_btn_text' | translate }}
            </template>
          </el-button>
        </div>
      </el-form>
      <div class="auth-form-layer-bottom">
        <router-link to="/auth/register" class="btn-register">
          <i class="el-icon-back"></i>
          <span>{{ 'reg_back' | translate }}</span>
        </router-link>
        <router-link to="/auth/" class="btn-register">
          <i class="fal fa-sign-in-alt"></i>
          <span>{{ 'login_text' | translate }}</span>
        </router-link>
      </div>
      <div class="auth-msg success" v-if="isSuccess">
        <i class="el-icon-success"></i>
        <p>{{ 'reg_success' | translate }}</p>
      </div>
      <div class="auth-msg danger" v-if="isError">
        <i class="el-icon-error"></i>
        <!--                <p>{{ 'reg_error' | translate }}</p>-->
        <p>{{ errorMsg.Message }}</p>
      </div>
      <div class="auth-msg warning" v-if="loading">
        <i class="el-icon-loading"></i>
        <p>{{ 'reg_loading' | translate }}</p>
      </div>
    </div>
    <el-dialog
        :title="'reg_term' | translate"
        :visible.sync="showTerm"
        width="30%"
        :before-close="termClose">
      <span>{{ 'reg_term' | translate }}</span>
      <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="showTerm = false">Ok</el-button>
            </span>
    </el-dialog>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Register-Citizen",
  data() {
    const validateSpace = (rule, value, callback) => {
      let whiteSpace = /\s/g;
      if (whiteSpace.test(value)) {
        callback(new Error("Хоосон зай орсон байна"));
      } else {
        callback();
      }
    };
    const validateCheckbox = (rule, value, callback) => {
      if (value === false) {
        callback(new Error("Үйлчилгээний нөхцөл"));
      } else {
        callback();
      }
    };
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please input the password'));
      } else {
        if (this.credentials.passwdCheck !== '') {
          this.$refs.registerFormRef.validateField('passwdCheck');
        }
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please input the password again'));
      } else if (value !== this.credentials.passwd) {
        callback(new Error('Two inputs don\'t match!'));
      } else {
        callback();
      }
    };
    return {
      isValid: true,
      loading: false,
      isSuccess: false,
      isError: false,
      errorMsg: null,
      msg: null,
      showTerm: false,
      directions: [
        {
          label_mn: 'Төрийн байгууллага',
          label_en: 'Government organization',
          value: 1
        },
        {
          label_mn: 'Аж ахуйн нэгж',
          label_en: 'Business entity',
          value: 2
        },
        {
          label_mn: 'Боловсролын байгууллага',
          label_en: 'Educational institution',
          value: 3
        },
        {
          label_mn: 'Судалгааны байгууллага',
          label_en: 'Research organization',
          value: 4
        },
        {
          label_mn: 'Иргэн, хувь хүн',
          label_en: 'Citizen and individual',
          value: 5
        },
      ],
      credentials: {
        is_org: 0,
        direction: '',
        org_name: null,
        org_position: null,
        orcid: null,
        first_name: null,
        last_name: null,
        phone: null,
        gender: 1,
        login: null,
        passwd: null,
        passwdCheck: null,
        termChecked: false,

        purpose: null,
        other_purpose: null,
      },
      rules: {
        direction: [
          {required: true, message: 'Та ажлын салбар чиглэлээ сонгоно уу!', trigger: 'blur'}
        ],
        org_name: [
          {required: true, message: 'Байгууллагын нэр оруулна уу!', trigger: 'blur'}
        ],
        org_position: [
          {required: true, message: 'Албан тушаал оруулна уу!', trigger: 'blur'}
        ],
        last_name: [
          {required: true, message: 'Эцэг/эхийн нэр оруулна уу!', trigger: 'blur'}
        ],
        first_name: [
          {required: true, message: 'Өөрийн нэрээ оруулна уу!', trigger: 'blur'}
        ],
        phone: [
          {required: true, message: 'Утсаа оруулна уу!', trigger: 'blur'}
        ],
        gender: [
          {required: true, message: 'Хүйсээ оруулна уу!', trigger: 'blur'}
        ],
        login: [
          {
            type: 'email',
            required: true,
            message: 'Бүртгэлтэй имэйл хаягаа оруулна уу!',
            trigger: ["blur", "change"]
          },
          {validator: validateSpace, trigger: "blur"},
        ],
        passwd: [
          {required: true, message: 'Нууц үгээ оруулна үү!', trigger: 'blur'},
          {validator: validatePass, trigger: 'blur'}
        ],
        passwdCheck: [
          {required: true, message: 'Нууц үгээ дахин оруулна үү!', trigger: 'blur'},
          {validator: validatePass2, trigger: 'blur'}
        ],
        termChecked: [
          {validator: validateCheckbox, trigger: 'change'}
        ],
      }
    }
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters([
      'languages',
      'language',
      // ...
    ])
  },
  methods: {
    submitRegister() {
      this.$refs['registerFormRef'].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.isSuccess = false;
          this.isError = false;
          this.$http.post('/api/registerUser', this.credentials).then(({data}) => {
            console.log("LOGIN FORM:");
            console.log(data);
            if (data.status) {
              this.loading = false;
              this.isError = false;
              this.isSuccess = true;
              this.msg = data.msg.message;
              this.$store.commit('setUser', data.data);
              this.$store.commit('setUserToken', data.token);
              setTimeout(() => {
                this.$router.push("/")
                // window.location.reload();
              }, 1000);

            } else {
              // this.$store.commit('setUser', null);
              // this.$store.commit('setUserToken', null);
              this.loading = false;
              this.isError = true;
              this.errorMsg = data.msg;
              // }, 1000);
            }

            // eslint-disable-next-line no-unused-vars
          })
          //     .catch(e => {
          //     setTimeout(() => {
          //         this.$store.commit('setUser', null);
          //         this.$store.commit('setUserToken', null);
          //         this.loading = false;
          //         this.isError = true;
          //         this.isSuccess = false;
          //         this.errorMsg = "Нэвтрэх мэдээлэл буруу байна";
          //     }, 1000);
          // })
        } else {
          this.isValid = false;
        }
      })
    },
    termClose() {
      this.showTerm = false
      // this.$confirm('Are you sure to close this dialog?')
      //     .then(_ => {
      //         done();
      //     })
      //     .catch(_ => {});
    }
  }
}
</script>