<template>
  <div id="wrapper">
    <!-- content-->
    <div class="content">

      <!--  section  -->
      <section class="gray-bg main-dashboard-sec" id="sec1">
        <div class="container">
          <!-- dashboard content-->
          <div class="col-md-8">
            <div class="dashboard-title fl-wrap">
              <h3>Төрийн албаны хэрэглэгч</h3>
            </div>
            <!-- profile-edit-container-->
            <div class="profile-edit-container fl-wrap block_box">
              <el-form :model="companyForm" :rules="rules" ref="companyForm">
                <el-row :gutter="20" class="demo-ruleForm">
                  <el-col :span="12">
                    <el-form-item prop="type">
                      <el-input prefix-icon="el-icon-guide" disabled
                                v-model="user.first_name"
                                autocomplete="off"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item prop="company">
                      <el-input prefix-icon="el-icon-office-building"
                                v-model="user.last_name" disabled
                                autocomplete="off"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="24">
                    <el-form-item prop="user">
                      <el-input prefix-icon="fal fa-user"
                                v-model="user.login" disabled
                                autocomplete="off"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="24">
                    <el-form-item label="Мэдээлэл ашиглах зориулалтаа бичнэ үү." prop="purpose">
                      <el-input prefix-icon="fal fa-user"
                                type="textarea"
                                :rows="5"
                                placeholder="Бичих талбар"
                                v-model="companyForm.purpose"
                                autocomplete="off"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="24">
                    <el-upload
                        style="width: 100%;"
                        v-model="companyForm.file"
                        :action="`${$http.defaults.baseURL}/api/profile/avatar`"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess"
                        :before-upload="beforeAvatarUpload">
                      <el-button size="small" type="primary">
                        <i class="fas fa-images"></i>
                        Албан тоот оруулах
                      </el-button>
                      <div slot="tip" class="el-upload__tip">500kb-аас бага хэмжээтэй jpg/png файл оруулна уу</div>
                    </el-upload>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="24">
                    <div class="info">
                      <span>Хүсэлт баталгаажсан мэдэгдэл хэрэглэгчийн бүртгэлтэй мэйл хаягаар очих ба бүтээгдэхүүнийг ТАТАЖ АВАХ эрх үүснэ.<br>Мэдэгдэл очсоноос хойш 7 хоногийн турш 3 удаа татах эрх үүснэ.</span><br>
                      <span class="danger">Албан тоот хүсэлтийг эх хувиар хүлээн авах хүртэл хүсэлт баталгаажихгүй болохыг анхаарна уу.</span><br>
                      <el-checkbox v-model="companyForm.checked">Худалдан авалтын нөхцлийг зөвшөөрч байна.</el-checkbox>
                    </div>
                  </el-col>
                </el-row>
                <div class="home-search-buttonx vertical-center">
                  <el-button type="primary"
                             icon="el-icon-upload"
                             @click="submitForm('companyForm')"
                             v-loading.fullscreen.lock="loading"
                  >{{ 'save' | translate }}
                  </el-button>
                </div>
              </el-form>
              <el-alert v-if="isSuccess" style="margin-top: 20px"
                  title="Хүсэлт амжилттай хүлээн авлаа"
                  type="success">
              </el-alert>
              <el-alert v-if="loading" style="margin-top: 20px"
                        title="Түр хүлээнэ үү"
                        type="warning">
              </el-alert>
              <el-alert v-if="isError" style="margin-top: 20px"
                        title="Алдаа гарлаа"
                        type="danger">
              </el-alert>
            </div>
          </div>
          <!-- dashboard content end-->
          <!--  dashboard-menu-->
          <div class="col-md-4">
            <div class="mob-nav-content-btn color2-bg init-dsmen fl-wrap"><i class="fal fa-bars"></i> Dashboard menu
            </div>
            <div class="clearfix"></div>
            <div class="fixed-bar fl-wrap" id="dash_menu">
              <CartSummary></CartSummary>
            </div>
            <div class="clearfix"></div>
          </div>
        </div>
        <!-- dashboard-menu  end-->
      </section>
      <!--  section  end-->
      <div class="limit-box fl-wrap"></div>
    </div>
    <!--content end-->
  </div>
</template>

<script>
import CartSummary from "../../components/CardSummary";
import {mapGetters} from "vuex";

export default {
  name: 'CompanyForm',
  components: {
    CartSummary,
  },
  data() {
    return {
      loading: false,
      isSuccess: false,
      isError: false,
      msg:null,
      companyForm: {
        user: '',
        purpose: '',
        file: '',
        checked: false,
        basketItems: [],
        totalPrice:0
      },
      rules: {
        purpose: [
          {required: true, message: 'Please input purpose'},
        ],
        file: [
          {required: true, message: 'Please input file', trigger: 'blur'},
        ],
      }
    };
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters([
      'user',
      'userToken',
      'basketItems',
      "totalPrice"
    ])
  },

  methods: {
    handleAvatarSuccess(res) {
      this.companyForm.file = res;
    },
    beforeAvatarUpload(file) {
      const isImg = file.type === 'image/jpeg' || 'image/png';
      const isLt2M = file.size < 512 * 512;

      if (!isImg) {
        this.$message.error('Зураг оруулна уу!');
      }
      if (!isLt2M) {
        this.$message.error('Файл 500KB хэмжээнээс бага байх хэрэгтэй!');
      }
      return isImg && isLt2M;
    },
    submitForm(formName) {
      if (!this.companyForm.checked) {
        this.$alert('Худалдан авалтын нөхцөлөө зөвшөөрнө үү', 'Уучлаарай', {
          confirmButtonText: 'OK',
        });
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.companyForm.user = this.user.id;
          console.log(this.basketItems);
          this.companyForm.basketItems =JSON.stringify(this.basketItems);
          this.companyForm.totalPrice =this.totalPrice.toString();
          console.log(this.companyForm);
          this.loading = true;
          this.$http.post('/api/request/company', this.companyForm).then(({data}) => {
            console.log(data);
            if (data.status) {
              this.loading = false;
              this.isError = false;
              this.isSuccess = true;
              this.msg = data.msg;
              setTimeout(() => {
                 this.$router.push({ name: "order-list" })
               // window.location.reload();
              }, 1000);

            } else {
              // setTimeout(() => {
              console.log(data.msg);
              this.loading = false;
              this.isError = true;
              this.msg = "Алдаа гарлаа";
              // }, 1000);
            }

            // eslint-disable-next-line no-unused-vars
          }).catch(e => {
            setTimeout(() => {
              this.loading = false;
              this.isError = true;
              this.isSuccess = false;
              this.msg = "Алдаа гарлаа";
            }, 1000);
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  }
}
</script>