<template>
  <!-- dashboard content-->
  <div class="col-md-8">
    <div class="dashboard-title fl-wrap">
      <h3>{{ '_update_password' | translate }} </h3>
    </div>
    <!-- profile-edit-container-->
    <div class="profile-edit-container fl-wrap block_box">
      <el-form class="profile-form-container" :model="passForm" :rules="rules" ref="passForm">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item :label="'_password' | translate" prop="password">
              <el-input :placeholder="'_password' | translate"
                        prefix-icon="fal fa-lock"
                        type="password"
                        v-model="passForm.password"
                        autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item :label="'_newpassword' | translate" prop="newpassword">
              <el-input :placeholder="'_newpassword' | translate"
                        prefix-icon="fal fa-lock"
                        type="password"
                        v-model="passForm.newpassword"
                        autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item :label="'_confirmpassword' | translate" prop="confpassword">
              <el-input :placeholder="'_confirmpassword' | translate"
                        prefix-icon="fal fa-lock"
                        type="password"
                        v-model="passForm.confpassword"
                        autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="home-search-button">
          <el-button type="primary" icon="el-icon-upload" @click="submitForm('passForm')">{{ 'save' | translate }}</el-button>
        </div>
      </el-form>
      <div class="login-msg">
        <el-alert v-if="loading" class="auth-alert"
                  title="Түр хүлээнэ үү, Бүртгэж авах хүсэлт илгээгдэж байна"
                  type="warning">
        </el-alert>
        <el-alert v-if="isSuccess" class="auth-alert"
                  title="Амжилттай бүртгэгдлээ. Бүртгүүлсэн мэдээллээрээ нэвтэрч орно уу"
                  type="success">
        </el-alert>
        <el-alert v-if="isError" class="auth-alert"
                  :title="errorMsg"
                  type="error">
        </el-alert>
        <el-alert v-if="rePassError" class="auth-alert"
                  title="Дахин оруулсан нууц тохирохгүй байна"
                  type="error">
        </el-alert>
        <el-alert v-if="!isValid" class="auth-alert"
                  title="Бүртгэлээ бүрэн бөглөнө үү"
                  type="warning">
        </el-alert>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "ChangePassword",
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Шинэ нууц үгээ оруулна уу'));
      } else {
        if (this.passForm.confpassword !== '') {
          this.$refs.passForm.validateField('confpassword');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please input the new password again'));
      } else if (value !== this.passForm.newpassword) {
        callback(new Error('Дахин оруулсан нууц үг тохирохгүй байна'));
        this.rePassError=true;
      } else {
        callback();
      }
    };
    return {
      loading:false,
      isSuccess:false,
      isError:false,
      rePassError:false,
      isValid:true,
      errorMsg:'Алдаа гарлаа. Мэдээллээ шалгаад дахин оролдоно уу',
      passForm: {
        login:null,
        password: null,
        newpassword: null,
        confpassword: null,
      },
      rules: {
        password: [
          {  required: true, message: 'Одоо ашигладаг нууц үгээ оруулна уу', trigger: 'blur' }
        ],
        newpassword: [
          { validator: validatePass, trigger: 'blur' }
        ],
        confpassword: [
          { validator: validatePass2, trigger: 'blur' }
        ],
      }
    };
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters([
      'user'
    ])
  },
  mounted() {
  },
  methods: {
    submitForm(formRef) {
      this.$refs[formRef].validate((valid) => {
        if (valid) {
            this.isValid = valid;
            this.loading = true;
            this.isSuccess = false;
            this.isError = false;
            this.rePassError = false;
            this.passForm.login=this.user.login;
            this.$http.post('/api/changePassword', this.passForm).then(({data}) => {
              console.log("LOGIN FORM:");
              console.log(data);
              if (data.status) {
                this.loading = false;
                this.isError = false;
                this.isSuccess = true;
                this.msg = data.msg;
                setTimeout(() => {
                  this.$router.push("/")
                  // window.location.reload();
                }, 1000);

              } else {
                this.loading = false;
                this.isError = true;
                this.errorMsg = data.msg;
                // }, 1000);
              }

              // eslint-disable-next-line no-unused-vars
            }).catch(e => {
              setTimeout(() => {
                this.loading = false;
                this.isError = true;
                this.isSuccess = false;
                this.msg = "Алдаа гарлаа";
              }, 1000);
            })

        } else {
          this.isValid = false;
        }
      })
    },
  }
}
</script>