<template>
  <div class="fl-wrap ">
    <ul id="progressbar" class="no-list-style">
      <li class="active"><span class="tolt" data-microtip-position="top" :data-tooltip="languages == 'mn' ? `Төлбөрийн нөхцөл` : `Payment condition`">01.</span></li>
      <li class="active"><span class="tolt" data-microtip-position="top" :data-tooltip="languages == 'mn' ? `Төлбөр төлөх` : `Pay the fee`">02.</span></li>
      <!--                                    <li><span class="tolt" data-microtip-position="top" data-tooltip="Payment Method">03.</span></li>-->
      <li><span class="tolt" data-microtip-position="top" :data-tooltip="languages == 'mn' ? `Амжилттай` : `Success`">03.</span></li>
    </ul>
    <div class="bookiing-form-wrap block_box fl-wrap">
      <!--   list-single-main-item -->
      <div class="list-single-main-item fl-wrap hidden-section tr-sec">
        <div class="profile-edit-container">
          <div class="custom-form">
            <div class="list-single-main-item-title fl-wrap qpay-box">

              <h3>{{ 'qpay_the_pee' | translate }}</h3>

              <img class="qpay-img" v-if="qpayInvoice != null"
                   :src="`data:image/png;base64,${qpayInvoice.qPay_QRimage}`" alt="">

              <div class="p-warning">
                {{ 'use_the_qpay' | translate }}
              </div>
              <a v-if="!isPaid" href="javascript:void(0)" @click="checkPayment"
                 class="btn float-btn color3-bg submit-btn next-btn" id="submit">{{ 'check_payment' | translate }}<i class="fa fa-check"></i>
              </a>

              <div class="payment-info">
                <el-alert
                    v-if="isPaid"
                    :title="languages == 'mn' ? `Амжилттай` : `Success`"
                    :description="languages == 'mn' ? `Таны төлбөр төлөгдсөн байна. Үргэлжлүүлэх товч дээр дарна уу!` : `Your payment has been made. Click the Continue button!`"
                    type="success">
                </el-alert>

                <el-alert
                    v-if="isNotPaid"
                    :title="languages == 'mn' ? `Амжилтгүй` : `Failed`"
                    :description="languages == 'mn' ? `Та төлбөрийн мэдээллээ шалгана уу!` : `Please check your payment information!`"
                    type="error">
                </el-alert>
              </div>

              <div v-if="loading" class="loading-wrap">
                <div class="lds-ellipsis">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>

            <div class="p-action">
              <router-link to="/payment" class="previous-form action-button back-form color2-bg prev-btn">
                <i class="fa fa-arrow-left"></i>
                {{ 'reg_back' | translate }}
              </router-link>

              <Button @click="gotoNext" :disabled="isDisabled"
                      class="btn float-btn color2-bg submit-btn next-btn">{{ 'continued' | translate }}
                 <i class="fa fa-arrow-right"></i>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters, mapActions} from "vuex";

export default {
  name: 'PaymentTransaction',
  data() {
    return {
      qpayInvoice: null,
      isDisabled: true,
      billNo: "",
      token: "",
      loading: false,
      isNotPaid: false,
      isPaid: false,
    }
  },
  mounted() {
    window.initTowhub()
    window.initparallax();
    //
    this.getInvoice()
  },

  computed: {
    ...mapGetters({user: "user", totalPrice: "totalPrice", totalPriceUsd: "totalPriceUsd", basketItems: "basketItems", languages: "languages"})
  },

  methods: {
    ...mapActions({clearBasket: "clearBasket"}),
    getInvoice() {
      // this.loading = true;
      let items = this.basketItems.map((t) => {
        return {
          id: t.id,
          price: t.price_mnt,
          price_usd: t.price_usd,
        }
      })

      console.log("totalPrice:");
      console.log(this.totalPrice);
      this.$http.post("/api/generate/invoice", {
        user: this.user.id,
        invoice_type: 1,
        items: items,
        total_price: parseFloat(this.totalPrice),
        total_price_usd: this.totalPriceUsd,
      }).then(o => {
        // setTimeout(() => {
            // this.loading = false;
          this.qpayInvoice = o.data.data;
          this.billNo = o.data.invoice;
          this.token = o.data.token;
        // }, 1500)
      })
    },

    checkPayment() {
      this.isPaid = false;
      this.isNotPaid = false;
      this.loading = true;
      this.$http.get(`/api/qpay/check/${this.billNo}/${this.token}`).then((o) => {
        setTimeout(() => {
          this.loading = false;
          if (o.data.payment_info.payment_status == 'PAID') {
            this.isDisabled = false;
            this.isPaid = true;
            this.clearBasket();
          } else {
            this.isNotPaid = true;
          }
        }, 1500)
      })
    },

    gotoNext() {
      this.$router.push("/payment/status")
    }
  }
}
</script>