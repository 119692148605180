<template>
  <div id="wrapper">
    <!-- content-->
    <div class="content">
      <!--  section  -->
      <section class="parallax-section dashboard-header-sec gradient-bg" data-scrollax-parent="true">
        <div class="container">
          <div class="dashboard-breadcrumbs breadcrumbs">
            <a href="#">Нүүр</a><span>Хувийн мэдээлэл</span>
          </div>
          <!--Tariff Plan menu-->
          <div class="tfp-det">
            <p>You Are on <a href="#">Extended</a> . Use link bellow to view details or upgrade. </p>
            <a href="#" class="tfp-det-btn color2-bg">Details</a>
          </div>
          <!--Tariff Plan menu end-->
          <div class="dashboard-header_conatiner fl-wrap dashboard-header_title">
            <h1>Тавтай морилно уу: <span>М***********н</span></h1>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="gradient-bg-figure" style="right:-30px;top:10px;"></div>
        <div class="gradient-bg-figure" style="left:-20px;bottom:30px;"></div>
        <div class="circle-wrap" style="left:120px;bottom:120px;" data-scrollax="properties: { translateY: '-200px' }">
          <div class="circle_bg-bal circle_bg-bal_small"></div>
        </div>
        <div class="circle-wrap" style="right:420px;bottom:-70px;" data-scrollax="properties: { translateY: '150px' }">
          <div class="circle_bg-bal circle_bg-bal_big"></div>
        </div>
        <div class="circle-wrap" style="left:420px;top:-70px;" data-scrollax="properties: { translateY: '100px' }">
          <div class="circle_bg-bal circle_bg-bal_big"></div>
        </div>
        <div class="circle-wrap" style="left:40%;bottom:-70px;">
          <div class="circle_bg-bal circle_bg-bal_middle"></div>
        </div>
        <div class="circle-wrap" style="right:40%;top:-10px;">
          <div class="circle_bg-bal circle_bg-bal_versmall" data-scrollax="properties: { translateY: '-350px' }"></div>
        </div>
        <div class="circle-wrap" style="right:55%;top:90px;">
          <div class="circle_bg-bal circle_bg-bal_versmall" data-scrollax="properties: { translateY: '-350px' }"></div>
        </div>
      </section>
      <!--  section  end-->
      <!--  section  -->
      <section class="gray-bg main-dashboard-sec" id="sec1">
        <div class="container">
          <!--  dashboard-menu-->
          <div class="col-md-4">
            <div class="mob-nav-content-btn color2-bg init-dsmen fl-wrap"><i class="fal fa-bars"></i> Dashboard menu
            </div>
            <div class="clearfix"></div>
            <div class="fixed-bar fl-wrap" id="dash_menu">
              <div class="user-profile-menu-wrap fl-wrap block_box">
                <div class="ts-avatar">
                  <div class="dashboard-header-avatar">
                    <img v-if="this.user && this.user.avatar && this.user.avatar!=null"
                         :src="$http.defaults.baseURL+this.user.avatar" alt="">
                    <img v-else src="../../images/mris_user.jpeg" alt="">
                  </div>
                  <div class="profile-name">
                    <span>{{ user.last_name }}.{{ user.first_name }}</span>
                  </div>
                </div>
                <!-- user-profile-menu-->
                <div class="user-profile-menu">
                  <ul class="no-list-style">
                    <li>
                      <router-link v-if="user.is_org == 1" to="/profile"><i class="el-icon-office-building"></i>{{ '_organzation_information' | translate }}</router-link>
                      <router-link v-else to="/profile"><i class="fal fa-user-edit"></i>{{ '_personal_information' | translate }}</router-link>
                    </li>
                    <li>
                      <router-link to="/profile/password"><i class="fal fa-key"></i>{{ '_update_password' | translate }}
                      </router-link>
                    </li>
                    <li>
                      <router-link to="/profile/phone"><i class="fal fa-envelope"></i>{{ '_update_phone' | translate }}
                      </router-link>
                    </li>
                    <li>
                      <router-link to="/profile/order-list"><i class="fal fa-th-list"></i> {{
                          '_my_orders' | translate
                        }}<span v-if="count">{{ this.count }}</span></router-link>
                    </li>
                    <li><a href="javascript:void(0)" @click="logout()"><i
                        class="fal fa-sign-out"></i>{{ '_signout' | translate }}</a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="clearfix"></div>
          </div>
          <!-- dashboard-menu  end-->
          <!-- dashboard content-->
          <router-view></router-view>
          <!-- dashboard content end-->
        </div>
      </section>
      <!--  section  end-->
      <div class="limit-box fl-wrap"></div>
    </div>
    <!--content end-->
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "IndexProfile",
  data() {
    return {
      count: null,
    };
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters([
      'user'
    ])
  },

  created() {
    this.getProducts();
    console.log(this.user);
  },

  methods: {
    logout() {
      localStorage.removeItem('user_token');
      localStorage.removeItem('user');
      this.$store.commit('setStorageValues');
      this.$router.push("/")
      window.location.reload();
    },

    getProducts() {
      this.$http.get(`/api/user-invoice-items/${this.user.id}`).then(({data}) => {
        this.count = data['items'].length;
      })
    },
  }
}
</script>