<template>
  <div id="wrapper">
    <div class="content listing-page">
      <section class="parallax-section single-par custom-header" data-scrollax-parent="true">
        <div class="bg par-elem" data-bg="/assets/images/home-slide/home-slide-bg-1.jpg"
             data-scrollax="properties: { translateY: '30%' }"></div>
        <div class="overlay op7"></div>
        <div class="container">
          <div class="section-title">
            <h2><span><b>{{ 'saved_items1' | translate }} </b> {{ 'saved_items2' | translate }}</span></h2>
            <div class="breadcrumbs fl-wrap"><a href="#">{{ 'home' | translate }}</a><span>{{
                'saved_items' | translate
              }}</span></div>
          </div>
        </div>
      </section>

      <div class="listing-page-content container">
        <div class="p-list" style="width: 100%">
          <div class="p-list-wrap">
            <div class="p-list-wrap-inner no-saved">
              <p-list v-if="products.length > 0" :fTypes="types" :total="total"
                      :remove-item="removeItem"
                      :products="products"></p-list>
              <div class="no-product" v-else>
                <i class="far fa-meh"></i>
                <div class="no-product-text">
                  <p>{{ 'no_product_title' | translate }}</p>
                  <span>{{ 'no_product_description' | translate }}</span>
                </div>
              </div>

              <div v-if="loading" class="loading-wrap">
                <div class="lds-ellipsis">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="limit-box fl-wrap"></div>
    </div>
  </div>
</template>

<script>
import pList from "../components/p_list"
import Vue from "vue";

export default {
  components: {
    pList
  },
  data() {
    return {
      products: [],
      types: [],
      total: 0,
      loading: true
    }
  },

  created() {
    this.getType();
    this.getFavItems();
  },

  mounted() {
    window.initTowhub()
    window.initparallax();
  },


  methods: {
    removeItem(id) {
      this.loading = true;
      setTimeout(() => {
        this.products = this.products.filter((item) => item.id != id);
        setTimeout(() => {
          this.loading = false;
        }, 500)
      }, 1200)
    },

    getFavItems() {
      this.loading = true;
      if (localStorage.getItem("favorites")) {
        let favorites = localStorage.getItem("favorites");
        Vue.prototype.$http.post('/api/card/items', {items: favorites}).then((o) => {
          this.products = o.data;
          setTimeout(() => {
            this.loading = false
          })
        })
      } else {
        this.loading = false
      }
    },

    getType() {
      this.$http.get('/api/type').then(({data}) => {
        this.types = data;
      })
    },

    paginate(p) {
      this.currentPage = p;
      this.getProducts();
    },
  }
}
</script>
