<template>
  <div>
    <div id="wrapper">
      <div class="content">
        <section class="parallax-section single-par" data-scrollax-parent="true">
          <div class="bg par-elem "  data-bg="images/bg/34.jpg" data-scrollax="properties: { translateY: '30%' }"></div>
          <div class="overlay op7"></div>
          <div class="container">
            <div class="section-title center-align big-title">
              <h2><span>{{ 'hel_p' | translate }}</span></h2>
              <span class="section-separator"></span>
              <div class="breadcrumbs fl-wrap"><router-link to="/">{{ 'home' | translate }}</router-link><span>{{ 'hel_p' | translate }}</span></div>
            </div>
          </div>
          <div class="header-sec-link">
            <a href="#sec1" class="custom-scroll-link"><i class="fal fa-angle-double-down"></i></a>
          </div>
        </section>

        <section>
          <div class="container">
            <div class="help-menu">
<!--                 <ul>-->
<!--                   <li class="active-menu activeM">Алхамууд</li>-->
<!--                   <li class="active-menu ">Видео заавар</li>-->
<!--                 </ul>-->
              <el-tabs v-model="tabs" >
                <el-tab-pane :label="'steps' | translate" name="first" class="active-menu">
                  <div class="section-title" style="margin-top: 50px">
                    <h2>{{ 'step' | translate }}</h2>
                    <!--              <div class="section-subtitle">Худалдан авалт хийх алхамууд</div>-->
                    <span class="section-separator"></span>
                  </div>
                  <div class="process-wrap fl-wrap" >
                    <div class="no-list-style">
                      <div class="row">
                        <div class="col-md-4">
                          <div class="process-item-1">
                            <span class="process-count">01 </span>
                            <div class="time-line-icon">
                              <!--                        <i class="fal fa-map-marker-alt"></i>-->
                              <img src="../images/help_icon/h_icon_01.svg" alt="" class="h_icon">
                            </div>
                            <h4 style="text-align: center" v-if="language.short == 'mn'">Бүтээгдэхүүнээ сонгох</h4>
                            <h4 style="text-align: center" v-else>Find the product</h4>
                            <p v-if="language.short == 'mn'">Геологийн тайлан, баримт бичиг, вектор ба растер газрын зураг, веб сервис болон геологийн өгөгдлүүдээс сонгож, сагсандаа нэмнэ.</p>
                            <p v-else>Select from the geodata, scanned reports and related documents, vector and raster maps, web service, and add to your cart.</p>
                          </div>
                          <span class="pr-dec"></span>
                        </div>
                        <div class="col-md-4">
                          <div class="process-item-1">
                            <span class="process-count">02</span>
                            <div class="time-line-icon">
                              <!--                        <i class="fal fa-mail-bulk"></i>-->
                              <img src="../images/help_icon/h_icon_02.svg" alt="" class="h_icon">
                            </div>
                            <h4 style="text-align: center" v-if="language.short == 'mn'">Худалдан авах</h4>
                            <h4 style="text-align: center" v-else>Purchase</h4>
                            <p v-if="language.short == 'mn'">Сагсанд орсон бүтээгдэхүүний тоо, ширхэг, үнийг нягталж төлбөрийн хэрэгслээ сонгож, төлбөрөө төлнө.</p>
                            <p v-else>Check the quantity and price of the product in the cart, select the payment method and pay.</p>
                          </div>
                          <span class="pr-dec"></span>
                        </div>
                        <div class="col-md-4">
                          <div class="process-item-1">
                            <span class="process-count">03</span>
                            <div class="time-line-icon">
                              <!--                        <i class="fal fa-layer-plus"></i>-->
                              <img src="../images/help_icon/h_icon_03.svg" alt="" class="h_icon">
                            </div>
                            <h4 style="text-align: center" v-if="language.short == 'mn'">Бүтээгдэхүүнийг татаж авах</h4>
                            <h4 style="text-align: center" v-else>Download the product</h4>
                            <p v-if="language.short == 'mn'">Төлбөрийг амжилттай хүлээн авснаар танд бүтээгдэхүүнийг ТАТАЖ АВАХ эрх үүснэ. Эрх үүссэн тухай мэдэгдэл таны бүртгэлтэй мэйл хаягаар очих ба мэдэгдэл очсоноос хойш 7 хоногийн турш 3 удаа татах эрх үүснэ.</p>
                            <p v-else>Upon successful receipt of payment, you will be able to DOWNLOAD the product. The notification will have sent to your registered e-mail address, and you will be able to download it 3 times a week since receiving the notification.</p>
                            <p style="color: #FFC600; font-size: 12px; line-height: 14px" v-if="language.short == 'mn'">Олон улсын гүйлгээ ажлын 3-5 хоногт хянагдаж, баталгаажихыг анхаарна уу.</p>
                            <p style="color: #FFC600; font-size: 12px; line-height: 14px" v-else>Please note that international transactions are reviewed and confirmed within 3-5 business days.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="process-end"><i class="fal fa-check"></i></div>
                  </div>
                  <div class="section-title">
                    <h2>{{ 'goverment_officials' | translate }}</h2>
                    <!--              <div class="section-subtitle">Худалдан авалт хийх алхамууд</div>-->
                    <span class="section-separator"></span>
                  </div>
                  <div class="process-wrap fl-wrap">
                    <div class="no-list-style">
                      <div class="row">
                        <div class="col-md-3">
                          <div class="process-item">
                            <span class="process-count">01</span>
                            <div class="time-line-icon">
                              <!--                        <i class="fal fa-layer-plus"></i>-->
                              <img src="../images/help_icon/h_icon_04.svg" alt="" class="h_icon">
                            </div>
                            <h4 style="text-align: center" v-if="language.short == 'mn'">Хэрэглэгчээр бүртгүүлэх</h4>
                            <h4 style="text-align: center" v-else>Create an account</h4>
                            <p v-if="language.short == 'mn'">Төрийн болон геологийн судалгааны байгууллагад ажилладаг мэргэжилтэн, албан хаагч нь ҮГА-нд бүртгэлтэй байгууллагаа сонгож, хэрэглэгчийн мэдээллээ үнэн зөв бүртгэнэ.</p>
                            <p v-else>Specialists and employees of government and geological research institutes shall select the organization that has already registered in the DDM by NGS, and record their customer information accurately.</p>
                            <p style="color: #FFC600; font-size: 12px; line-height: 14px" v-if="language.short == 'mn'">Хэрэглэгчийн бүртгэлд байгууллагын тодорхойлолтыг хавсаргах бөгөөд эх хувийг ҮГА-нд хүргүүлсэн байна.</p>
                            <p style="color: #FFC600; font-size: 12px; line-height: 14px" v-else>A letter from the organization shall be attached to the customer registration, and a hard copy should be submitted to the NGS.</p>
                          </div>
                          <span class="pr-dec"></span>
                        </div>
                        <div class="col-md-3">
                          <div class="process-item">
                            <span class="process-count">02</span>
                            <div class="time-line-icon">
                              <!--                        <i class="fal fa-layer-plus"></i>-->
                              <img src="../images/help_icon/h_icon_05.svg" alt="" class="h_icon">
                            </div>
                            <h4 style="text-align: center" v-if="language.short == 'mn'">Бүтээгдэхүүнээ сонгох</h4>
                            <h4 style="text-align: center" v-else>Find the product</h4>
                            <p v-if="language.short == 'mn'">Геологийн тайлан, баримт бичиг, вектор ба растер газрын зураг, веб сервис болон геологийн өгөгдлүүдээс сонгож, сагсандаа нэмнэ.</p>
                            <p v-else>Select from the geodata, scanned reports and related documents, vector and raster maps, web service, and add to your cart.</p>
                          </div>
                          <span class="pr-dec"></span>
                        </div>
                        <div class="col-md-3">
                          <div class="process-item">
                            <span class="process-count">03</span>
                            <div class="time-line-icon">
                              <!--                        <i class="fal fa-layer-plus"></i>-->
                              <img src="../images/help_icon/h_icon_06.svg" alt="" class="h_icon">
                            </div>
                            <h4 style="text-align: center" v-if="language.short == 'mn'">Хүсэлт илгээх</h4>
                            <h4 style="text-align: center" v-else>Send th Request</h4>
                            <p v-if="language.short == 'mn'">Сагсанд орсон бүтээгдэхүүний тоо, ширхэг, үнийг нягталж  төлбөрийн хэрэгслээс “Төрийн байгууллагын хэрэгцээнд” сонгож, холбогдох мэдээллүүдийг бүртгэж хүсэлтээ илгээнэ.</p>
                            <p v-else>Check the quantity and price of the products in the cart, select “Only for the Governmental User” from the payment method, record the relevant information and send your request.</p>
                            <p style="color: #FFC600; font-size: 12px; line-height: 14px" v-if="language.short == 'mn'">Бүтээгдэхүүнд хандах хүсэлтэд байгууллагын албан тоот хүсэлтийг хавсаргах бөгөөд эх хувийг ҮГА-нд хүргүүлсэн байна.</p>
                            <p style="color: #FFC600; font-size: 12px; line-height: 14px" v-else>The request letter from the organization shall be attached to the customer registration, and a hard copy should be submitted to the NGS.</p>
                          </div>
                          <span class="pr-dec"></span>
                        </div>
                        <div class="col-md-3">
                          <div class="process-item">
                            <span class="process-count">04</span>
                            <div class="time-line-icon">
                              <!--                        <i class="fal fa-layer-plus"></i>-->
                              <img src="../images/help_icon/h_icon_07.svg" alt="" class="h_icon">
                            </div>
                            <h4 style="text-align: center" v-if="language.short == 'mn'">Бүтээгдэхүүнийг татаж авах</h4>
                            <h4 style="text-align: center" v-else>Download the product</h4>
                            <p v-if="language.short == 'mn'">Хүсэлт баталгаажсан мэдэгдэл хэрэглэгчийн бүртгэлтэй мэйл хаягаар очих ба бүтээгдэхүүнийг ТАТАЖ АВАХ эрх үүснэ.
                              Мэдэгдэл очсоноос хойш 7 хоногийн турш 3 удаа татах эрх үүснэ.</p>
                            <p v-else>The request confirmation will have sent to your registered e-mail address, and you will be able to DOWNLOAD the product.
                              It's been able to download it 3 times a week since receiving the notification.</p>
                            <p style="color: #FFC600; font-size: 12px; line-height: 14px" v-if="language.short == 'mn'">Албан тоот хүсэлтийг эх хувиар хүлээн авах хүртэл хүсэлт баталгаажихгүй болохыг анхаарна уу.</p>
                            <p style="color: #FFC600; font-size: 12px; line-height: 14px" v-else>Please note that the request will not confirm until the official request's hard copy has been received.</p>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div class="process-end"><i class="fal fa-check"></i></div>
                  </div>
                </el-tab-pane>
                <el-tab-pane :label="'video_tutorial' | translate" name="second" class="active-menu ">
                  <div v-if="loading" class="loading-wrap">
                    <div class="lds-ellipsis">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="colomn-text  pad-top-column-text fl-wrap">
                        <div class="colomn-text-title">
                          <h3>{{ 'video_zaawar' | translate }}</h3>
                          <ul class="video_cat">
                            <li :key="index" v-for="(cat, index) in categories">
                              <a class="item" href="javascript:void(0);" :class="[(index+1===activeCategory) ? 'visible':'']" @click="callData(cat.id)" >
                                <span v-if="language.short == 'mn'">{{ cat.video_cat_mn }}</span>
                                <span v-else>{{ cat.video_cat_en }}</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="list-single-main-media fl-wrap" style="margin-top: 60px">
                        <!--                  <img src="images/all/2.jpg" class="respimg" alt="">-->
                        <!--                  <a href="https://vimeo.com/70851162" class="promo-link   image-popup"><i class="fal fa-video"></i><span>How Townhub Works</span></a>-->
                        <div class="video_slide" v-if="h_videos.length > 0">
                          <swiper class="_swiper-container" :options="video_swiper">
                            <swiper-slide :key="index" v-for="(h_video, index) in h_videos">
                              <div class="slide">
                                <div class="videos">
                                  <youtube  :video-id="getVideo(h_video.helper_video_as_video)" player-width="100%"  player-height="340"></youtube>
                                </div>
                              </div>
                            </swiper-slide>
                            <div class="swiper-pagination" slot="pagination"></div>
                          </swiper>
                          <div class="swiper-button-prev-1" slot="button-prev"><i class="fal fa-chevron-left"></i></div>
                          <div class="swiper-button-next-1" slot="button-next"><i class="fal fa-chevron-right"></i></div>
                        </div>
                        <div v-else class="no-data">
                          {{ 'no_video' | translate }}.
                        </div>
                      </div>
                    </div>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </div>
          </div>
        </section>

<!--        <section data-scrollax-parent="true" id="sec1">-->
<!--          <div class="container">-->
<!--            <div class="section-title">-->
<!--              <h2>{{ 'step' | translate }}</h2>-->
<!--&lt;!&ndash;              <div class="section-subtitle">Худалдан авалт хийх алхамууд</div>&ndash;&gt;-->
<!--              <span class="section-separator"></span>-->
<!--            </div>-->
<!--            <div class="process-wrap fl-wrap">-->
<!--              <div class="no-list-style">-->
<!--                <div class="row">-->
<!--                  <div class="col-md-4">-->
<!--                    <div class="process-item-1">-->
<!--                      <span class="process-count">01 </span>-->
<!--                      <div class="time-line-icon">-->
<!--&lt;!&ndash;                        <i class="fal fa-map-marker-alt"></i>&ndash;&gt;-->
<!--                        <img src="../images/help_icon/h_icon_01.svg" alt="" class="h_icon">-->
<!--                      </div>-->
<!--                      <h4 style="text-align: center" v-if="language.short == 'mn'">Бүтээгдэхүүнээ сонгох</h4>-->
<!--                      <h4 style="text-align: center" v-else>Find the product</h4>-->
<!--                      <p v-if="language.short == 'mn'">Геологийн тайлан, баримт бичиг, вектор ба растер газрын зураг, веб сервис болон геологийн өгөгдлүүдээс сонгож, сагсандаа нэмнэ.</p>-->
<!--                      <p v-else>Select from the geodata, scanned reports and related documents, vector and raster maps, web service, and add to your cart.</p>-->
<!--                    </div>-->
<!--                    <span class="pr-dec"></span>-->
<!--                  </div>-->
<!--                  <div class="col-md-4">-->
<!--                    <div class="process-item-1">-->
<!--                      <span class="process-count">02</span>-->
<!--                      <div class="time-line-icon">-->
<!--&lt;!&ndash;                        <i class="fal fa-mail-bulk"></i>&ndash;&gt;-->
<!--                        <img src="../images/help_icon/h_icon_02.svg" alt="" class="h_icon">-->
<!--                      </div>-->
<!--                      <h4 style="text-align: center" v-if="language.short == 'mn'">Худалдан авах</h4>-->
<!--                      <h4 style="text-align: center" v-else>Purchase</h4>-->
<!--                      <p v-if="language.short == 'mn'">Сагсанд орсон бүтээгдэхүүний тоо, ширхэг, үнийг нягталж төлбөрийн хэрэгслээ сонгож, төлбөрөө төлнө.</p>-->
<!--                      <p v-else>Check the quantity and price of the product in the cart, select the payment method and pay.</p>-->
<!--                    </div>-->
<!--                    <span class="pr-dec"></span>-->
<!--                  </div>-->
<!--                  <div class="col-md-4">-->
<!--                    <div class="process-item-1">-->
<!--                      <span class="process-count">03</span>-->
<!--                      <div class="time-line-icon">-->
<!--&lt;!&ndash;                        <i class="fal fa-layer-plus"></i>&ndash;&gt;-->
<!--                        <img src="../images/help_icon/h_icon_03.svg" alt="" class="h_icon">-->
<!--                      </div>-->
<!--                      <h4 style="text-align: center" v-if="language.short == 'mn'">Бүтээгдэхүүнийг татаж авах</h4>-->
<!--                      <h4 style="text-align: center" v-else>Download the product</h4>-->
<!--                      <p v-if="language.short == 'mn'">Төлбөрийг амжилттай хүлээн авснаар танд бүтээгдэхүүнийг ТАТАЖ АВАХ эрх үүснэ. Эрх үүссэн тухай мэдэгдэл таны бүртгэлтэй мэйл хаягаар очих ба мэдэгдэл очсоноос хойш 7 хоногийн турш 3 удаа татах эрх үүснэ.</p>-->
<!--                      <p v-else>Upon successful receipt of payment, you will be able to DOWNLOAD the product. The notification will have sent to your registered e-mail address, and you will be able to download it 3 times a week since receiving the notification.</p>-->
<!--                      <p style="color: #FFC600; font-size: 12px; line-height: 14px" v-if="language.short == 'mn'">Олон улсын гүйлгээ ажлын 3-5 хоногт хянагдаж, баталгаажихыг анхаарна уу.</p>-->
<!--                      <p style="color: #FFC600; font-size: 12px; line-height: 14px" v-else>Please note that international transactions are reviewed and confirmed within 3-5 business days.</p>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="process-end"><i class="fal fa-check"></i></div>-->
<!--            </div>-->
<!--            <div class="section-title">-->
<!--              <h2>{{ 'goverment_officials' | translate }}</h2>-->
<!--&lt;!&ndash;              <div class="section-subtitle">Худалдан авалт хийх алхамууд</div>&ndash;&gt;-->
<!--              <span class="section-separator"></span>-->
<!--            </div>-->
<!--            <div class="process-wrap fl-wrap">-->
<!--              <div class="no-list-style">-->
<!--                <div class="row">-->
<!--                  <div class="col-md-3">-->
<!--                    <div class="process-item">-->
<!--                      <span class="process-count">01</span>-->
<!--                      <div class="time-line-icon">-->
<!--                        &lt;!&ndash;                        <i class="fal fa-layer-plus"></i>&ndash;&gt;-->
<!--                        <img src="../images/help_icon/h_icon_04.svg" alt="" class="h_icon">-->
<!--                      </div>-->
<!--                      <h4 style="text-align: center" v-if="language.short == 'mn'">Хэрэглэгчээр бүртгүүлэх</h4>-->
<!--                      <h4 style="text-align: center" v-else>Create an account</h4>-->
<!--                      <p v-if="language.short == 'mn'">Төрийн болон геологийн судалгааны байгууллагад ажилладаг мэргэжилтэн, албан хаагч нь ҮГА-нд бүртгэлтэй байгууллагаа сонгож, хэрэглэгчийн мэдээллээ үнэн зөв бүртгэнэ.</p>-->
<!--                      <p v-else>Specialists and employees of government and geological research institutes shall select the organization that has already registered in the DDM by NGS, and record their customer information accurately.</p>-->
<!--                      <p style="color: #FFC600; font-size: 12px; line-height: 14px" v-if="language.short == 'mn'">Хэрэглэгчийн бүртгэлд байгууллагын тодорхойлолтыг хавсаргах бөгөөд эх хувийг ҮГА-нд хүргүүлсэн байна.</p>-->
<!--                      <p style="color: #FFC600; font-size: 12px; line-height: 14px" v-else>A letter from the organization shall be attached to the customer registration, and a hard copy should be submitted to the NGS.</p>-->
<!--                    </div>-->
<!--                    <span class="pr-dec"></span>-->
<!--                  </div>-->
<!--                  <div class="col-md-3">-->
<!--                    <div class="process-item">-->
<!--                      <span class="process-count">02</span>-->
<!--                      <div class="time-line-icon">-->
<!--                        &lt;!&ndash;                        <i class="fal fa-layer-plus"></i>&ndash;&gt;-->
<!--                        <img src="../images/help_icon/h_icon_05.svg" alt="" class="h_icon">-->
<!--                      </div>-->
<!--                      <h4 style="text-align: center" v-if="language.short == 'mn'">Бүтээгдэхүүнээ сонгох</h4>-->
<!--                      <h4 style="text-align: center" v-else>Find the product</h4>-->
<!--                      <p v-if="language.short == 'mn'">Геологийн тайлан, баримт бичиг, вектор ба растер газрын зураг, веб сервис болон геологийн өгөгдлүүдээс сонгож, сагсандаа нэмнэ.</p>-->
<!--                      <p v-else>Select from the geodata, scanned reports and related documents, vector and raster maps, web service, and add to your cart.</p>-->
<!--                    </div>-->
<!--                    <span class="pr-dec"></span>-->
<!--                  </div>-->
<!--                  <div class="col-md-3">-->
<!--                    <div class="process-item">-->
<!--                      <span class="process-count">03</span>-->
<!--                      <div class="time-line-icon">-->
<!--                        &lt;!&ndash;                        <i class="fal fa-layer-plus"></i>&ndash;&gt;-->
<!--                        <img src="../images/help_icon/h_icon_06.svg" alt="" class="h_icon">-->
<!--                      </div>-->
<!--                      <h4 style="text-align: center" v-if="language.short == 'mn'">Хүсэлт илгээх</h4>-->
<!--                      <h4 style="text-align: center" v-else>Send th Request</h4>-->
<!--                      <p v-if="language.short == 'mn'">Сагсанд орсон бүтээгдэхүүний тоо, ширхэг, үнийг нягталж  төлбөрийн хэрэгслээс “Төрийн байгууллагын хэрэгцээнд” сонгож, холбогдох мэдээллүүдийг бүртгэж хүсэлтээ илгээнэ.</p>-->
<!--                      <p v-else>Check the quantity and price of the products in the cart, select “Only for the Governmental User” from the payment method, record the relevant information and send your request.</p>-->
<!--                      <p style="color: #FFC600; font-size: 12px; line-height: 14px" v-if="language.short == 'mn'">Бүтээгдэхүүнд хандах хүсэлтэд байгууллагын албан тоот хүсэлтийг хавсаргах бөгөөд эх хувийг ҮГА-нд хүргүүлсэн байна.</p>-->
<!--                      <p style="color: #FFC600; font-size: 12px; line-height: 14px" v-else>The request letter from the organization shall be attached to the customer registration, and a hard copy should be submitted to the NGS.</p>-->
<!--                    </div>-->
<!--                    <span class="pr-dec"></span>-->
<!--                  </div>-->
<!--                  <div class="col-md-3">-->
<!--                    <div class="process-item">-->
<!--                      <span class="process-count">04</span>-->
<!--                      <div class="time-line-icon">-->
<!--                        &lt;!&ndash;                        <i class="fal fa-layer-plus"></i>&ndash;&gt;-->
<!--                        <img src="../images/help_icon/h_icon_07.svg" alt="" class="h_icon">-->
<!--                      </div>-->
<!--                      <h4 style="text-align: center" v-if="language.short == 'mn'">Бүтээгдэхүүнийг татаж авах</h4>-->
<!--                      <h4 style="text-align: center" v-else>Download the product</h4>-->
<!--                      <p v-if="language.short == 'mn'">Хүсэлт баталгаажсан мэдэгдэл хэрэглэгчийн бүртгэлтэй мэйл хаягаар очих ба бүтээгдэхүүнийг ТАТАЖ АВАХ эрх үүснэ.-->
<!--                        Мэдэгдэл очсоноос хойш 7 хоногийн турш 3 удаа татах эрх үүснэ.</p>-->
<!--                      <p v-else>The request confirmation will have sent to your registered e-mail address, and you will be able to DOWNLOAD the product.-->
<!--                        It's been able to download it 3 times a week since receiving the notification.</p>-->
<!--                      <p style="color: #FFC600; font-size: 12px; line-height: 14px" v-if="language.short == 'mn'">Албан тоот хүсэлтийг эх хувиар хүлээн авах хүртэл хүсэлт баталгаажихгүй болохыг анхаарна уу.</p>-->
<!--                      <p style="color: #FFC600; font-size: 12px; line-height: 14px" v-else>Please note that the request will not confirm until the official request's hard copy has been received.</p>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->

<!--              </div>-->
<!--              <div class="process-end"><i class="fal fa-check"></i></div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </section>-->

<!--        <section class="gradient-bg hidden-section" data-scrollax-parent="true">-->
<!--          <div v-if="loading" class="loading-wrap">-->
<!--            <div class="lds-ellipsis">-->
<!--              <div></div>-->
<!--              <div></div>-->
<!--              <div></div>-->
<!--              <div></div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="container">-->
<!--            <div class="row">-->
<!--              <div class="col-md-6">-->
<!--                <div class="colomn-text  pad-top-column-text fl-wrap">-->
<!--                  <div class="colomn-text-title">-->
<!--                    <h3>{{ 'video_zaawar' | translate }}</h3>-->
<!--                    <ul class="video_cat">-->
<!--                      <li :key="index" v-for="(cat, index) in categories">-->
<!--                        <a class="item" href="javascript:void(0);" :class="[(index+1===activeCategory) ? 'visible':'']" @click="callData(cat.id)" >-->
<!--                          <span v-if="language.short == 'mn'">{{ cat.video_cat_mn }}</span>-->
<!--                          <span v-else>{{ cat.video_cat_en }}</span>-->
<!--                        </a>-->
<!--                      </li>-->
<!--                    </ul>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="col-md-6">-->
<!--                <div class="list-single-main-media fl-wrap">-->
<!--&lt;!&ndash;                  <img src="images/all/2.jpg" class="respimg" alt="">&ndash;&gt;-->
<!--&lt;!&ndash;                  <a href="https://vimeo.com/70851162" class="promo-link   image-popup"><i class="fal fa-video"></i><span>How Townhub Works</span></a>&ndash;&gt;-->
<!--                  <div class="video_slide" v-if="h_videos.length > 0">-->
<!--                    <swiper class="_swiper-container" :options="video_swiper">-->
<!--                      <swiper-slide :key="index" v-for="(video, index) in h_videos">-->
<!--                        <div class="slide">-->
<!--                          <div class="videos">-->
<!--                            <youtube  :video-id="video.helper_video_as_video" player-width="100%"  player-height="340"></youtube>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </swiper-slide>-->
<!--                      <div class="swiper-pagination" slot="pagination"></div>-->
<!--                    </swiper>-->
<!--                    <div class="swiper-button-prev-1" slot="button-prev"><i class="fal fa-chevron-left"></i></div>-->
<!--                    <div class="swiper-button-next-1" slot="button-next"><i class="fal fa-chevron-right"></i></div>-->
<!--                  </div>-->
<!--                  <div v-else class="no-data">-->
<!--                     Видео линк оруулаагүй байна !-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="gradient-bg-figure" style="right:-30px;top:10px;"></div>-->
<!--          <div class="gradient-bg-figure" style="left:-20px;bottom:30px;"></div>-->
<!--          <div class="circle-wrap" style="left:270px;top:120px;" data-scrollax="properties: { translateY: '-200px' }">-->
<!--            <div class="circle_bg-bal circle_bg-bal_small"></div>-->
<!--          </div>-->
<!--          <div class="circle-wrap" style="right:420px;bottom:-70px;" data-scrollax="properties: { translateY: '150px' }">-->
<!--            <div class="circle_bg-bal circle_bg-bal_big"></div>-->
<!--          </div>-->
<!--          <div class="circle-wrap" style="left:420px;top:-70px;" data-scrollax="properties: { translateY: '100px' }">-->
<!--            <div class="circle_bg-bal circle_bg-bal_big"></div>-->
<!--          </div>-->
<!--          <div class="circle-wrap" style="left:40%;bottom:-70px;"  >-->
<!--            <div class="circle_bg-bal circle_bg-bal_middle"></div>-->
<!--          </div>-->
<!--          <div class="circle-wrap" style="right:40%;top:-10px;"  >-->
<!--            <div class="circle_bg-bal circle_bg-bal_versmall" data-scrollax="properties: { translateY: '-350px' }"></div>-->
<!--          </div>-->
<!--          <div class="circle-wrap" style="right:55%;top:90px;"  >-->
<!--            <div class="circle_bg-bal circle_bg-bal_versmall" data-scrollax="properties: { translateY: '-350px' }"></div>-->
<!--          </div>-->
<!--        </section>-->

<!--        <div class="limit-box fl-wrap"></div>-->
      </div>
      <!--content end-->
    </div>
  </div>
</template>

<script>
import {getIdFromURL} from 'vue-youtube-embed';
import {mapGetters} from "vuex";
import {GET_VIDEO_CAT, GET_VIDEO_BY_ID,} from "../graphql/queries";
export default {
  name: "Faq",
  data () {
    return {
      videoId: 'https://www.youtube.com/watch?v=YAFIAGMDTf0&list=PLdlrEZcPDIpA1qJZFkCLPRsoXyKCs4_P6&t=19s',
      getVideo: getIdFromURL,
      tabs: 'first',
      video_swiper: {
        slidesPerView: 1,
        spaceBetween: 15,
        navigation: {
          nextEl: '.swiper-button-next-1',
          prevEl: '.swiper-button-prev-1'
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        breakpoints: {
          1024: {
            slidesPerView: 1,
            spaceBetween: 10
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 10
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 10
          },
          414: {
            slidesPerView: 1,
            spaceBetween: 10
          },
          375: {
            slidesPerView: 1,
            spaceBetween: 10
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 10
          }
        }
      },
      categories: [],
      h_videos: [],
      activeCategory: 1,
      loading: true,
    }
  },
  computed: {
    ...mapGetters([
      'languages',
      'language',
      // ...
    ])
  },
  mounted() {
    window.initTowhub()
    window.initparallax();
    this.callData();
    this.getCategory();
  },
  methods:{
    getCategory(){
      this.loading = true;
      this.$apollo.query({ query: GET_VIDEO_CAT }).then(({data}) => {
        this.categories = data.lut_helper_video_cat;
        setTimeout(()=>{
          this.loading = false;
          console.log(this.categories)
        })
      })
    },
    callData(cat_id){
      this.currentCat = cat_id;
      this.activeCategory = cat_id
      this.loading = true;
      if (this.currentCat) {
        this.$apollo.query({ query: GET_VIDEO_BY_ID, variables: { cat_id: this.currentCat.toString() }}).then(({data}) => {
          this.h_videos = data.ds_video_cat;
          setTimeout(()=>{
            this.loading = false;
          }, 500)
        });
      }else{
        this.activeCategory = 1;
        this.$apollo.query({ query: GET_VIDEO_BY_ID, variables: { cat_id: this.activeCategory.toString() }}).then(({data}) => {
          this.h_videos = data.ds_video_cat;
          setTimeout(()=>{
            this.loading = false;
          }, 500)
        });
      }
    },

  },
}
</script>

<style scoped>

</style>
