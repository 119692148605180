<template>
  <div :class="`p-list-content scrollable ${layout === 1 ? 'product-list' : ''}`">
    <archive v-for="item in products" :key="item.id" :fTypes="fTypes" :product="item" :layout="layout"></archive>
  </div>
</template>

<script>
import archive from "../components/archiveItem";

export default {
  name: "p_list",
  props: ['products', 'fTypes', "total", "layout"],
  components: {
    archive,
  },
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>
