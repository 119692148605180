export default {
    "home": "Эхлэл",
    "list": "Цахим сан",
    "article": "Нийтлэл",
    "contact_us": "Холбоо барих",
    "contact": "Холбоо",
    "us": "барих",
    "profile": "Хувийн мэдээлэл",
    "order": "Захиалга",
    "search": "Хайлт",
    "searches": "Хайх",
    "share": "хуваалцах",
    "niitlel_search": "Нийтлэл хайх",
    "other": "Бусад",
    "bvleg": "Бүлэг",
    "cat": "Ангилал",
    "cat_all": "Бүх ангилал",
    "prev": "Өмнөх",
    "next": "Дараах",
    "read_more": "Дэлгэрэнгүй",
    "login": "Нэвтрэх",
    "add": "Нэмэх",
    "save": "Хадгалах",
    "saved": "Хадгалсан",
    "price": "Үнэ",
    "action": "Үйлдэл",
    "free": "Үнэгүй",
    "other_information": "Өгөгдлийн төрөл",
    "mongeomin": "МонГеоМин",
    "mongeocol": "МонГеоКол",
    "mongeocat": "МонГеоКат",
    "mongeonet": "МонГеоНет",
    "mongeocat_webgis_desc": "Газарзүйн мэдээллийн систем",
    "mongeocat_geonet_desc": "Метадата-каталогийн систем",
    "mongeomin_desc": "Нөөцийн бүртгэлийн систем",
    "mongeocol_desc": "Хээрийн судалгаа, албан томилолтын мэдээлэл цуглуулагч",
    "national_geologal_survey": "ҮНДЭСНИЙ ГЕОЛОГИЙН АЛБА © 2021 . БҮХ ЭРХ ХУУЛИАР ХАМГААЛАГДСАН",
    "link": "Холбоос",
    "systems": "Холбоотой системүүд",
    "terms_conditions": "Үйлчилгээний нөхцөл",
    "hel_p": "Тусламж",
    "answer_quistion": "Түгээмэл асуулт хариулт",
    "detail_": "Бүгдийг үзэх",
    "eh_surwalj": "Эх сурвалж",
    "about": "Бидний тухай",
    "_address": "Хаяг байршил",
    "_network": "Олон нийтийн сүлжээ",
    "additional_pictures": "Хавсралт зургууд",
    "_maps": "Газрын зураг",
    "registration_number": "Тайлангийн дугаар",
    "information_typ": "Мэдээллийн төрөл",
    "_format": "Өгөгдлийн төрөл",
    "add_cart": "Сагсанд нэмэх",
    "remove_cart": "Сагснаас хасах",
    "subscription": "Захиалгын хуудас",
    "start_page": "Эхлэх хуудас",
    "end_page": "Дуусах хуудас",
    "cancel": "Цуцлах",
    "geodata": "Эрдэс баялаг -> Геологийн өгөгдөл",
    "report": "Тайлан",
    "web_serve": "Судалгаа -> Веб сервис",
    "vector_and_raster_maps": "Байрзүйн зураг -> Вектор ба растер газрын зураг",
    "thematic": "Сэдэвчилсэн",
    "_type": "Төрөл",
    "province_city": "Аймаг / Нийслэл",
    "soum_district": "Сум / Дүүрэг",
    "author": "Зохиогч",
    "result": "Үр дүн",
    "_order": "Эрэмбэ",
    "related_products": "Төстэй бараанууд",
    "thumbnail": "Хүснэгтээр харах",
    "list_view": "Жагсаалтаар харах",
    "_cat": "Бүлэг -> Ангилал",
    "contact_information": "Холбоо барих мэдээлэл",
    "phone": "Утас",
    "mail": "И-мэйл хаяг",
    "send_feedback": "Санал хүсэлт илгээх",
    "warning": "Та өөрийн гомдол санал хүсэлтээ энд бичиж бидэнд илгээнэ үү.",
    "name": "Нэр:",
    "your_name": "Таны нэр:",
    "your_mail": "И-мэйл хаяг:",
    "your_phone": "Утасны дугаар:",
    "your_lastname": "Таны Овог",
    "comments": "Санал хүсэлт:",
    "email_address": "Цахим шуудан",
    "_input_your_name": "Жишээ: Ганболд",
    "_input_your_phone": "Жишээ: 9900xxxx",
    "_input_email_address": "ganbold@gmail.com",
    "_input_feedback": "Санал хүсэлтээ энд бичнэ үү ...",
    "_sent": "Илгээх",
    "_sending": "Илгээж байна...",
    "_contact_map": "Байршлын зураг",
    "clean": "Цэвэрлэх",
    "_a": "Н",
    "rticle": "ийтлэл",
    "_research_work": "Судалгааны ажил",
    "news": "Мэдээлэл",
    "book": "Book",
    "magazine": "Magazine",
    "registration_mineral_resources": "Ашигт малтмалын нөөцийг бүртгэсэн тухай",
    "product": "Бүтээгдэхүүн",
    "details": "Дэлгэрэнгүй",
    "labels": "Шошгууд",
    "free_download": "Үнэгүй татах",
    "paid": "Төлбөртэй",
    "sanhvvjvvlegch": "Cанхүүжүүлэгч байгууллага EBRD",
    "ishlel": "Ишлэл",
    "magazine_information": "Сэтгүүлийн мэдээлэл",
    "last_name": "Овог",
    "hariya_bailguullaga": "ХАРЪЯА БАЙГУУЛЛАГА",
    "o_number": "Сэтгүүлийн дугаар",
    "tvgeemel": "Түгээмэл",
    "a_q": "асуулт хариулт",
    "terms": "Үйлчилгээний",
    "conditions": "нөхцөл",
    "step": "Худалдан авалт хийх алхамууд",
    "goverment_officials": "Төрийн байгууллагын албан хаагч, мэргэжилтэн",
    "video_zaawar": "Систем ашиглах видео заавар",
    "magazine_name": "Тэмдэглэгдсэн хэвлэл",
    "article_name": "Нийтлэлийн нэр",
    "page_num": "Хуудас дугаар",
    "issn": "ISSN/Сэтгүүл/, ISBN/ном/ дугаар",
    "eISSN_duygaar": "eISSN дугаар",
    "hewlegdsen_on": "Хэвлэгдсэн он",
    "hurliin_name": "Хурлын нэр",
    "tusul_name": "Төслийн нэр",
    "date": "Огноо",
    "country": "Улс",
    "content": "Агуулга",
    "city": "Хот",
    "a_link": "Холбогдох линк",
    "_location": "Байршил",
    "file_size": "Файлын хэмжээ",
    "organization": "Байгууллага",
    "year": "Он",
    "tuluv": "Төлөв",
    "dio_number": "DOI дугаар",
    "authors": "Зохиолч",
    "magazine_number": "Сэтгүүлийн дугаар",
    "no_imformation": "Мэдээлэл оруулаагүй байна.",
    "archive": "Баримтын сан",
    "archive_information": "Архивын үйлчилгээний төлбөрийн мэдээлэл",
    "no_video": "Видео оруулаагүй байна",
    "steps": "Алхамууд",
    "a4": "A4 хэмжээтэй",
    "_pdf": "PDF форматтай",
    "imageFile": "Зурган файл агуулсан",
    "extra_info": "Нэмэлт мэдээлэл",
    "yourCart": "Таны сагсанд",
    "close": "Хаах",
    "total": "Нийт дүн",
    "seeList": "Жагсаалт харах",
    "pay": "Тооцоо хийх",
    "currency": "₮",
    "sendReguest": "Хүсэлт илгээх",
    "video_tutorial": "Видео",
    "payment": "Төлбөр",
    "toPay": "төлөх",
    "cart": "Сагс",
    "typePayment": "Төлбөрийн төрөл",
    "fileType": "Файлын төрөл",
    "quantity": "Тоо ширхэг",
    "totalSize": "Нийт хэмжээ",
    "pleaseSelectPaymentType": "Төлбөрийн төрлөө сонгоно уу",
    "transfers": "Төрийн сан шилжүүлэг",
    "continued": "Үргэлжлүүлэх",
    "madeAfterLoging": "Нэвтэрч орсны дараа гүйлгээ хийх боломжтой",

    "choosePayment": "Төлбөрийн нөхцөлөө сонгоно уу!",
    "qpay_the_pee":"Qpay төлөлт",
    "use_the_qpay":"Та өөрийн ашигладаг Qpay дэмждэг банкны аппликейныг ашиглаж дараах QR кодыг уншуулж төлбөрөө хийнэ үү.",
    "check_payment":"Төлбөр шалгах",
    "transfer_treasury":"Төрийн сангаар шилжүүлэх",
    "transfer_account":"Шилжүүлэх данс",
    "recipient":"Хүлээн авагч",
    "nat_geological_survey":"Үндэсний Геологийн Алба",
    "transaction_value":"Гүйлгээний утга",
    "successfully_paid":"Амжилттай төлөгдлөө",
    "order_list":"Захиалгын жагсаалт",
    "_test":"Уучлаарай, шинэчлэлт хийгдэж байна !",
    "file_type":"Файлын төрөл",




    // home page words
    "national_geological": "ҮНДЭСНИЙ ГЕОЛОГИЙН АЛБА",
    "national_geological_survey": "Үндэсний геологийн албаны мэдээллийн нэгдсэн систем.",
    "information_systems": "Геологи эрдэс баялгийн мэдээллийн системүүд",
    "reports_and_documents": "Тайлан, баримт бичгүүд",
    "metadat_catelogy_sistem": "Метадата-каталогийн систем",
    "resource_registration_system": "Метадата-каталогийн систем",
    "heeriin_sudalgaa": "Хээрийн судалгаа, албан томилолтын мэдээлэл цуглуулагч",
    "location": "Байршлаар",
    "search_word": "Та хайх утгаа бичнэ үү",
    "category": "Ангиллаар",
    "new_add": "Шинээр нэмэгдсэн",
    "geo_desc": "Геологи, тектоник, геоморфологи, дөрөвдөгчийн зураг, тайлбар бичиг, мэдээллийн сан тайлбар бичиг, мэдээллийн сан.",
    "more": "Цааш",
    "view": "үзэх",
    "vector_maps": "Геологийн дижитал бүтээгдэхүүнүүд",
    "geophysics": "Геофизик",
    "tyectonics": "Тектоник",
    "mineral_resources": "Ашигт малтмал",
    "geology": "Геологи",
    "partners": "Хамтрагч байгууллагууд",
    "total_map": "Нийт газрын зураг",
    "total_documentation": "Нийт тайлан, баримт бичиг",
    "total_web_service": "Нийт веб сервис",
    "public_doc": "Нээлттэй материал",
    "showAll": "Бүгд",
    "non_location": "Байршил тодорхойлоогүй",



    // Saved product
    "saved_items": "Хадгалсан бүтээгдэхүүн",
    "saved_items1": "Хадгалсан",
    "saved_items2": "бүтээгдэхүүн",
    "no_product_title": "Уучлаарай. Танд хадгалсан бүтээгдэхүүний жагсаалт алга байна",
    "no_product_description": "Та цахим сангаас өөрт таалагдсан бүтээгдэхүүнээ сонгож хадгална уу",
    "no_product_title_list": "Уучлаарай. Таны хайсан бүтээгдэхүүн олдсонгүй",
    "no_product_description_list": "Та цахим сангаас өөр сонголтоор шүүж, хайна уу",
    //profile

    "_firstname": "Өөрийн нэр",
    "_lastname": "Эцгийн/эхийн нэр",
    "_gender": "Хүйс",
    "_email": "Цахим хаяг",
    "_birthday": "Төрсөн огноо",
    "_select_birthday": "Төрсөн огноогоо сонгоно уу",
    "_phone": "Утасны дугаар",
    "_addres": "Гэрийн хаяг",
    "_website": "Вэб сайт",
    "_comment": "Сэтгэгдэл",
    "_comment_write": "Сэтгэгдэл бичих",
    "_password": "Нууц үг",
    "_newpassword": "Шинэ нууц үг",
    "_signin_password": "Нэвтрэх нууц үг",
    "_confirmpassword": "Шинэ нууц үг давтах",
    "_personal_information": "Хувийн мэдээлэл",
    "_organzation_information": "Байгууллагын мэдээлэл",
    "_civil_service_users": "Төрийн албаны хэрэглэгч",
    "_update_password": "Нууц үг шинэчлэх",
    "_update_phone": "Нэвтрэх мэдээлэл солих",
    "_update_phone_send": "Нэвтрэх мэдээлэл илгээх",
    "_my_orders": "Миний захиалгууд",
    "_signout": "Гарах",
    "_rule_firstname": "Та өөрийн нэрээ оруулна уу",
    "_rule_lastname": "Та эцэг/эхийн нэрээ оруулна уу",
    "_rule_mail": "Та и-мэйл хаягаа оруулна уу",
    "_select": "Сонгох",
    "_pay_the_fee": "Төлбөр төлөх",
    "_expected": "Хүлээгдэж буй",
    "_approved": "Батлагдсан",
    "_canceled": "Цуцалсан",
    "_file_download": "Файл татах",
    "_which_low": "Дуусах хуудас эхлэх хуудаснаас бага байна",
    "_select_type": "Төрлөө сонгоно уу",

    //auth
    "login_text":"Нэвтрэх",
    "login_btn_text":"Нэвтрэх",
    "login_btn_text_loading":"Нэвтрэч байна...",
    "login_email_placeholder": "Бүртгэлтэй имэйл хаягаа оруулна уу",
    "login_password_placeholder": "Нууц үгээ оруулна уу",
    "register_text":"Шинээр бүртгүүлэх",
    "register_btn_text":"Бүртгүүлэх",
    "forgot_password": "Нууц үг сэргээх?",
    "dan_label":"Төрийн танилт нэвтрэлтийн ДАН систем ашиглаж нэвтрэх",
    "dan_btn_text":"Дан нэвтрэлт",
    "login_error": "Нэвтрэх явцад алдаа гарлаа. Нэвтрэх мэдээллээ шалгаад дахин оролдоно уу!",
    "login_success": "Амжилттай нэвтэрлээ",
    "reg_citizen": "Иргэн, хувь хүн",
    "reg_organization": "Төрийн байгууллага",
    "reg_back": "Буцах",
    "reg_loading": "Түр хүлээнэ үү, Шинээр бүртгүүлэх хүсэлт илгээгдэж байна.",
    "reg_success": "Амжилттай бүртгэгдлээ. Бүртгүүлсэн мэдээллээрээ нэвтэрч орно уу.",
    "reg_error": "Бүртгэх явцад алдаа гарлаа. Мэдээллээ шалгаад дахин оролдоно уу.",
    "reg_term_label": "Үйлчилгээний нөхцөл",
    "reg_term": "Үйлчилгээний нөхцөлтэй танилцах",
    "reg_term_agree": "Би зөвшөөрч байна",
    "reg_term_agree_org": "Манай байгууллага зөвшөөрч байна",
    "reg_rePassError": "Дахин оруулсан нууц тохирохгүй байна.",
    "reg_invalid": "Бүртгэлээ бүрэн бөглөнө үү.",
    "reg_surname": "Эцэг/эхийн нэр",
    "reg_surname_placeholder": "Эцэг/эхийн нэр оруулна уу",
    "reg_first_name": "Таны нэр",
    "reg_first_name_placeholder": "Өөрийн нэрээ оруулна уу",
    "reg_phone": "Таны утасны дугаар",
    "reg_phone_placeholder": "Утасны дугараа оруулна уу",
    "reg_gender": "Таны хүйс",
    "reg_gender_male": "Эрэгтэй",
    "reg_gender_female": "Эмэгтэй",
    "reg_email_placeholder": "Таны имэйл хаяг нэвтрэх нэр болно",
    "reg_password": "Нууц үг",
    "reg_password_placeholder": "Нууц үг оруулна уу",
    "reg_password_repeat": "Нууц үг батлах",
    "reg_password_repeat_placeholder": "Нууц үг дахин оруулна уу",
    "reg_official_letter": "Албан тоот",
    "reg_official_letter_desc": "500kb-аас бага хэмжээтэй jpg/png файл оруулна уу",
    "reg_official_letter_btn": "Албан тоот оруулах",
    "reg_position": "Албан тушаал",
    "reg_position_placeholder": "Албан тушаалаа оруулна уу",
    "reg_organization_name": "Байгууллага",
    "reg_organization_name_placeholder": "Байгууллагын нэрээ оруулна уу",
    "reg_purpose": "Систем ашиглах зорилго",
    "reg_purpose_1": "Геологийн судалгаа",
    "reg_purpose_2": "Эрэл хайгуул",
    "reg_purpose_3": "Эрдэм шинжилгээ",
    "reg_purpose_4": "Танин мэдэхүй",
    "reg_purpose_other": "Бусад зорилго",
    "reg_purpose_other_placeholder": "Систем ашиглах бусад зорилгоо оруулана уу",
    "reg_direction": "Таны ажлын салбар чиглэл",
    "reg_direction_placeholder": "Та ажлын салбар чиглэлээ сонгоно уу",
    "reg_orcid_placeholder": "Та ORCID оруулна уу",
    "reg_org_type": "Байгууллагын төрөл, чиглэл",
    "reg_org_type_placeholder": "Байгууллагын төрөл, чиглэлээ сонгоно уу",
    "reg_org_sub_type": "Байгууллагын дэд төрөл, чиглэл",
    "reg_org_sub_type_placeholder": "Байгууллагын дэд төрлөө сонгоно уу",
    "reg_org_name_warning": "Хэрвээ танай байгууллага сонголтонд байхгүй бол \"Үндэсний геологийн алба\"-нд хандаж, байгууллагын эрхээ шалгуулна уу.",
    "basemap":"Суурь зураг",
    "complementary_maps":"Нэмэлт мэдээлэл",
    "hurliin_name_ognoo": "Хурлын нэр, огноо",
    "tusul_name_ognoo": "Төслийн нэр, огноо ",
    "published_language": "Нийтлэл хэвлэгдсэн хэл",
    "published_organization": "Дугаарыг эрхэлсэн байгууллага ",
    "contact_email": "Цахим шуудан",
    "contact_phone": "Утас",
    "keyword": "Түлхүүр үг",
    "name_content": "Нэр, сэдэв, агуулга",
};
