import Vue from 'vue'
import MapView from '@arcgis/core/views/MapView';
import WebMap from '@arcgis/core/WebMap';
import WebTileLayer from '@arcgis/core/layers/WebTileLayer';
import Sceneview from '@arcgis/core/views/SceneView';
import Basemap from '@arcgis/core/Basemap';
// import esriConfig from '@arcgis/core/config';
// import CoordinateConversion from '@arcgis/core/widgets/CoordinateConversion';
import Locate from '@arcgis/core/widgets/Locate';
import LayerList from '@arcgis/core/widgets/LayerList';
import BasemapGallery from '@arcgis/core/widgets/BasemapGallery';
import LocalBasemapsSource from '@arcgis/core/widgets/BasemapGallery/support/LocalBasemapsSource';
import Expand from "@arcgis/core/widgets/Expand";
import Print from '@arcgis/core/widgets/Print';
import Bookmarks from "@arcgis/core/widgets/Bookmarks";
import TileLayer from '@arcgis/core/layers/TileLayer';
import MapImageLayer from '@arcgis/core/layers/MapImageLayer';
import GroupLayer from '@arcgis/core/layers/GroupLayer';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import IdentityManager from "@arcgis/core/identity/IdentityManager";
import Credential from "@arcgis/core/identity/Credential";
import "babel-polyfill"
import {getRerender} from './helper';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';


import Graphic from "@arcgis/core/Graphic";

import { getLayerShow } from './utils';
import { getMonGeoCatConnection } from '@/utils/mongeocat';
export const doCluster = stored('doCluster') ? stored('doCluster') : 'true';

function stored(key) {
    return localStorage.getItem(key);
}

/**
 * Map, View, Config
 * */
// esriConfig.request.corsEnabledServers.push(
//     'http://mt0.google.com',
//     'http://mt1.google.com',
//     'http://mt2.google.com',
//     'http://mt3.google.com',
//     'http://a.tile.openstreetmap.org',
//     'http://b.tile.openstreetmap.org',
//     'http://c.tile.openstreetmap.org',
// );
export const Graphic_ = Graphic;
export const baseMaps = [new Basemap({
    baseLayers: [
        new WebTileLayer({
            urlTemplate: 'https://{subDomain}.google.com/vt/lyrs=m&x={col}&y={row}&z={level}',
            subDomains: ['mt0', 'mt1', 'mt2', 'mt3'],
        }),
    ],
    title:'Google Street' ,
    id: 'google Streets',
    thumbnailUrl: '/images/baseMaps/googleStreets.jpg',
}),
    new Basemap({
        baseLayers: [
            new WebTileLayer({
                urlTemplate: 'https://{subDomain}.google.com/vt/lyrs=s,h&x={col}&y={row}&z={level}',
                subDomains: ['mt0', 'mt1', 'mt2', 'mt3'],
            }),
        ],
        title: 'Google satellite',
        id: 'googleSatellite',

        thumbnailUrl: '/images/baseMaps/googleSatellite.jpg',
    }),
    new Basemap({
        baseLayers: [
            new WebTileLayer({
                urlTemplate:
                    'https://{subDomain}.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{level}/{row}/{col}',
                subDomains: ['server', 'services'],
            }),
        ],
        title: 'Satellite',
        id: 'imagery',
        thumbnailUrl: '/images/baseMaps/tempimagery.jpg',
    }),
    new Basemap({
        baseLayers: [
            new WebTileLayer({
                urlTemplate: 'https://{subDomain}.tile.openstreetmap.org/{level}/{col}/{row}.png',
                subDomains: ['a', 'b', 'c'],
            }),
        ],
        title: 'Open Street Map',
        id: 'OpenStreetMap',
        thumbnailUrl: '/images/baseMaps/openstreet.jpg',
    })];
export const mapMode = localStorage.getItem('mode');
export const webmap = new WebMap({
    basemap: baseMaps[0],
});
export const view =
    mapMode == '3D'
        ? new Sceneview({
            map: webmap,
            center: [106.917782, 47.9187],
            zoom: 6,
        })
        : new MapView({
            map: webmap,
            center: [106.917782, 47.9187],
            zoom: 6,
        });

export const removeAll = () => {
    if (SearchResultLayer.graphics.length >= 1) {
        SearchResultLayer.graphics.removeAll();
    }
};
export const selectedSymbol = {
    type: 'simple-fill', // autocasts as new SimpleFillSymbol()
    color: [53, 128, 216, 0.1],
    outline: {
        // autocasts as new SimpleLineSymbol()
        color: [53, 128, 216],
        width: 1,
    },
};
export const addGraphic = (geometry) => {
    view.goTo(geometry.extent);
    removeAll();
    const newGraphic = new Graphic_({
        geometry: geometry,
        symbol: selectedSymbol,
    });

    SearchResultLayer.graphics.add(newGraphic);
};

/**
 * Map Widgets
 * */
// const ccWidget = new CoordinateConversion({
//   view: view,
// });
// view.ui.add(ccWidget, 'bottom-left');
const locateBtn = new Locate({
    view: view,
});
const localSource = new LocalBasemapsSource({
    basemaps: baseMaps,
});
const DDMRE = {
    type: 'simple',
    symbol: {
        type: 'simple-fill',
        color: [53, 128, 216, 0.5],
        outline: {
            width: '1px',
            color: [53, 128, 216],
            opacity: 1,
        },
    },
};
async function getPopupDDM(feature) {
    let MonGeoCatUrl = ""

    if(feature.graphic.attributes.report_number){
        let connection = await getMonGeoCatConnection(feature.graphic.attributes.report_number);

        if(connection != null){

            MonGeoCatUrl = `<span class="mongeocat">${window.init.languge.short == 'mn' ? 'МонГеоКат холбоос' : 'Link of MonGeoCat'}</span>
<a target="_blank" href="${connection.webGISURL}">${window.init.languge.short == 'mn' ? 'Газарзүйн мэдээллийн систем' : 'WEB GIS'} <i class="far fa-arrow-alt-circle-right"></i></a><br>
<a target="_blank" href="${window.init.languge.short == 'mn' ? connection.mnURL : connection.enURL}">${window.init.languge.short == 'mn' ? 'Метадата-каталогийн систем' : 'Metadata Catalogue'} <i class="far fa-arrow-alt-circle-right"></i></a>`
        }
    }
    return `{${window.init.languge.short == 'mn' ? 'title_mn' : 'title_en'}}<br>${MonGeoCatUrl}`
}
export const DDMPopupTemlate = {
    title: 'DDM',
    content: getPopupDDM,
};
export const DDM = new FeatureLayer({
    url: 'https://gismap.mris.mn/arcgis/rest/services/NGS/geosystems_points/FeatureServer/6',
    title: 'DDM',
    visible: true,
    popupTemplate: DDMPopupTemlate,
    outFields: ['*'],
    renderer: DDMRE,
});
const layerGroupDDM = new GroupLayer({
    legendClass: `group-legend DDM`,
    title: 'DDM',
    visible: true,
    listMode: 'show',
    visibilityMode: 'independent',
    layers: [DDM],
});
view.ui.add(
    [
        new Expand({
            content: new LayerList({view: view}),
            view: view,
            group: "top-right",
            expandTooltip: "Layer"
        }),
        new Expand({
            content: new BasemapGallery({
                view: view,
                source: localSource,
            }),
            view: view,
            expandIconClass: "esri-icon-basemap",
            group: "top-right",
            expandTooltip: "Basemap"
        }),
        locateBtn
    ],
    "top-right"
);
view.ui.add(
    [

        new Expand({
            content: new Bookmarks({
                view: view,
                // allows bookmarks to be added, edited, or deleted
                editingEnabled: true
            }),
            view: view,
            group: "bottom-right",
            expandTooltip: "Bookmark"
        }),
        new Expand({
            content: new Print({
                view: view,
                printServiceUrl:
                    'https://dms.ulaanbaatar.mn/arcgis/rest/services/Utilities/PrintingTools/GPServer/Export%20Web%20Map%20Task',
            }),
            view: view,
            expandIconClass: "esri-icon-printer",
            group: "bottom-right",
            expandTooltip: "Print"
        }),

    ],
    "bottom-right"
);


var mapLayers = [];
/**
 * Map layers
 * */
const setLayers = () => {
    /**
     * local base maps
     * */
    const localBaseMaps = [];
    let currentLocale = Vue.i18n.locale();

    window.init.baseMaps.map(baseMap => {
        if (baseMap.is_dynamic) {
            const mapImageLayer = new MapImageLayer({
                url: baseMap.url,
                title: baseMap.layerName[currentLocale],
                visible: baseMap.show == '1' ? true : false,
                // listMode:'hide'
            });
            localBaseMaps.push(mapImageLayer);
        } else {
            const tileLayer = new TileLayer({
                url: baseMap.url,
                title: baseMap.layerName[currentLocale],
                visible: baseMap.show == '1' ? true : false,
                // listMode:'hide'
            });
            localBaseMaps.push(tileLayer);
        }
    });

    if (localBaseMaps.length >= 1) {
        const localBaseMapGroup = new GroupLayer({
            legendClass: ``,
            title: window.init.languge.short == 'mn' ? 'Бусад суур зураг' : 'Other Basemaps',
            visible: false,
            visibilityMode: 'independent',
            layers: localBaseMaps,
        });

        webmap.add(localBaseMapGroup);
    }

    // const sheet = document.createElement('style');
    // let styles = '';

    /*aditional layers start*/




    /*aditional layers end*/

    window.init.categories.reverse().map((category, index) => {
        // const layers = [];
        let children = [];


        category.children.reverse().map((child, childIndex) => {

            let mapLayer = null;

            if (child.layer_type == 'feature') {

                let Renderer;

                if (child.legends.length >= 2) {


                    const uniqueRenderer = {
                        type: 'unique-value', // autocasts as new UniqueValueRenderer()
                        field: child.style_field,
                        uniqueValueInfos: [],
                    };

                    child.legends.map(legend => {
                        const symbol = getRerender(legend);

                        const uniSymbol = {
                            value: legend.style_value,
                            symbol: symbol.symbol,
                            label: legend.title[currentLocale],
                        };
                        uniqueRenderer.uniqueValueInfos.push(uniSymbol);
                    });

                    // uniqueRenderer.defaultLabel = childIndex + '-';
                    // uniqueRenderer.defaultSymbol = uniqueRenderer.uniqueValueInfos[0].symbol;

                    Renderer = uniqueRenderer;


                } else {

                    let legend = child.legends[0];



                    Renderer = getRerender(legend);

                }







                let fcPopupTemplate = { // autocasts as new PopupTemplate()
                    title: child.name[currentLocale],
                    content: (feature)=>getPopup(feature, child.info_template, child.id)

                };




                let labelingInfo = null

                if(child.show_label == 1 && child.label_field){
                    labelingInfo = {
                        // autocasts as new LabelClass()
                        symbol: {
                            type: "text",  // autocasts as new TextSymbol()
                            color: child.label_color,
                            font: {  // autocast as new Font()
                                family: "Arial",
                                size: 10,
                                weight: "normal"
                            }
                        },
                        minScale:2800000,

                        labelPlacement: "above-center",
                        labelExpressionInfo: {
                            expression: "$feature."+child.label_field
                        }
                    };
                }
                let clusterConfig = null;
                if(child.do_cluster == 1 && doCluster == "true"){
                    clusterConfig = {
                        type: "cluster",
                        clusterRadius: "100px",
                        // {cluster_count} is an aggregate field containing
                        // the number of features comprised by the cluster
                        popupTemplate: {
                            content: "Нийт элементийн тоо: {cluster_count}.",
                            fieldInfos: [
                                {
                                    fieldName: "cluster_count",
                                    format: {
                                        places: 0,
                                        digitSeparator: true
                                    }
                                }
                            ]
                        },
                        clusterMinSize: "24px",
                        clusterMaxSize: "60px",
                        labelingInfo: [
                            {

                                deconflictionStrategy: "none",
                                labelExpressionInfo: {
                                    expression: "Text($feature.cluster_count, '#,###')"
                                },
                                symbol: {
                                    type: "text",
                                    color: "#004a5d",
                                    font: {
                                        weight: "bold",
                                        family: "Noto Sans",
                                        size: "12px"
                                    }
                                },
                                labelPlacement: "center-center"
                            }
                        ]
                    }

                    // Renderer = {
                    //     type: "simple",
                    //     field: "mag",
                    //     symbol: {
                    //         type: "simple-marker",
                    //         size: 4,
                    //         color: "#69dcff",
                    //         outline: {
                    //             color: "rgba(0, 139, 174, 0.5)",
                    //             width: 5
                    //         }
                    //     }
                    // };
                }


                mapLayer = new FeatureLayer({
                    url: child.layer_url,
                    labelingInfo: labelingInfo,
                    featureReduction: clusterConfig ? clusterConfig : undefined,
                    title: child.name[currentLocale],
                    visible: getLayerShow(child.id, child.show, null, false),
                    layerName: `${index}${childIndex}`,
                    renderer: Renderer,
                    outFields: ["*"],
                    popupTemplate: fcPopupTemplate,
                    style_field: child.style_field,
                    isMultipleLegend: child.legends.length >= 2,
                    multipleLegends: child.legends.length >= 2 ? child.legends : [],
                });


            } else {

                mapLayer = new MapImageLayer({
                    url: child.layer_url,
                    title: child.name[currentLocale],
                    visible: getLayerShow(child.id, child.show, null, false),
                    layerName: `${index}${childIndex}`,
                    listMode: 'hide-children',

                });
            }
            if (mapLayer)
                children.push(mapLayer);
            // if(child.id == 17){
            //     console.log(JSON.stringify({
            //         layer: mapLayer,
            //         type: child.layer_type,
            //         uid: mapLayer.uid,
            //         legends: child.legends,
            //         searchResultFileds: child.search_result_fields,
            //         id: child.id
            //
            //     }))
            // }

            mapLayers.push({
                layer: mapLayer,
                type: child.layer_type,
                uid: mapLayer.uid,
                legends: child.legends,
                searchResultFileds: child.search_info,
                id: child.id

            });

        });

        const layerGroup = new GroupLayer({
            // legendClass: `group-legend category_${category.id}`,
            title: category.name[currentLocale],
            visible: category.show == 1 ? true : false,
            visibilityMode: 'independent',
            layers: children,
        });

//     styles =
//         styles +
//         `
//         .category_${category.id}::before{
//             background: url("${category.icon}");
//             background-size: cover;
//         }
// `;

        webmap.add(layerGroup);

    });

    webmap.add(layerGroupDDM);
    // sheet.innerHTML = styles;
    // document.body.appendChild(sheet);
};
export const getPopup = async (feature, template, layer_id) => {

    if (layer_id == 10 || layer_id == '10') {


       return `{${window.init.languge.short == 'mn' ? 'title_mn' : 'title_en'}}<br><span class="mongeocat">${window.init.languge.short == 'mn' ? 'МонГеоКат холбоос' : 'Link of MonGeoCat'}</span><a target="_blank" href="https://webgis.mris.mn/#/gis/5313">${window.init.languge.short == 'mn' ? 'Газарзүйн мэдээллийн систем' : 'WEB GIS'} <i class="far fa-arrow-alt-circle-right"></i></a><br><a target="_blank" href="https://geonet.mris.mn/geonetwork/srv/mon/catalog.search#/metadata/076618fd-eeb1-473a-a320-6aa7d57b276c">${window.init.languge.short == 'mn' ? 'Метадата-каталогийн систем' : 'Metadata Catalogue'} <i class="far fa-arrow-alt-circle-right"></i></a>`
    }
    return template;
};

function getClusterPopup() {

    return "Нийт бүртгэл тоо: {cluster_count}. {cluster_type_db_id_}"

    // return `Нийт цэгийн тоо: <b>{cluster_count}</b>.`;
}

export const ClusterPOPUP = (title) => {
    return {
        title: title,
        content: getClusterPopup,
        fieldInfos: [
            {
                fieldName: "cluster_count",
                format: {
                    places: 0,
                    digitSeparator: true
                }
            },
            {
                fieldName: "cluster_type_db_id_",

            }
        ]
    }
};

export const SearchResultLayer = new GraphicsLayer({
    title: 'Хайлтын үр дүн',
    listMode: 'hide',
});
webmap.add(SearchResultLayer);




export const initialize = (container, tokenData) => {



    Credential.token = tokenData.token;
    Credential.tokenExpiration = tokenData.expires;

    IdentityManager.registerToken({
        token: tokenData.token,
        expires: tokenData.expires,
        server: "https://gismap.mris.mn/arcgis/rest/services"
    })
    setLayers();



    view.container = container;

    view.when()
        .then(() => {


        })
        .catch(error => {
            console.warn('An error in creating the map occured:', error);
        });
};
