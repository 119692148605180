<template>
    <div id="wrapper">
        <div class="content listing-page">
            <div class="filter">
                <div class="list-main-wrap-header">
                    <div class="filter-row">
                        <div class="filter-item input">
                            <el-input :placeholder="'name_content' | translate" class="plain" @change="getArticles"
                                      v-model="filterData.search">
                                <i slot="prefix" class="el-input__icon fal fa-keyboard"></i>
                            </el-input>
                        </div>
<!--                        <div class="filter-item input">-->
<!--                            <el-input :placeholder="'content' | translate" class="plain" @change="getArticles"-->
<!--                                      v-model="filterData.content">-->
<!--                                <i slot="prefix" class="el-input__icon fal fa-keyboard"></i>-->
<!--                            </el-input>-->
<!--                        </div>-->
                        <div class="filter-item select first">
                            <el-select class="plain" v-model="filterData.type" clearable @change="getArticles"
                                       :placeholder="'_type' | translate">
                                <el-option
                                        v-for="item in categories"
                                        :key="item.index"
                                        :label="`${language.short == 'mn' ? item.type_mn : item.type_en}`"
                                        :value="item.id">
                                </el-option>
                                <i slot="prefix" class="el-input__icon el-icon-s-operation"></i>
                            </el-select>
                        </div>
                        <div class="filter-item select second">
                            <el-select class="plain long" v-model="filterData.countery" filterable clearable :placeholder="'published_language' | translate"
                                       @change="getArticles">
                                <el-option
                                        v-for="item in ulsuud"
                                        :key="item.index"
                                        :label="item.language"
                                        :value="item.language">
                                </el-option>
                                <i slot="prefix" class="el-input__icon el-icon-location-information"></i>
                            </el-select>
                        </div>
                        <div class="filter-item select third">
                            <el-select class="plain long" v-model="filterData.year" filterable clearable :placeholder="'year' | translate"
                                       @change="getArticles">
                                <el-option
                                        v-for="item in years"
                                        :key="item.index"
                                        :label="item.publishment_year"
                                        :value="item.publishment_year">
                                </el-option>
                                <i slot="prefix" class="el-input__icon fal fa-clock"></i>
                            </el-select>
                        </div>
                        <div class="filter-item select fourth">
                            <el-select class="plain" v-model="filterData.author" clearable :placeholder="'authors' | translate" filterable
                                       @change="getArticles">
                                <el-option
                                        v-for="item in authors"
                                        :key="item.index"
                                        :label="`${language.short === 'mn' ? item.author_mn : item.author_mn}`"
                                        :value="item.author_mn">
                                </el-option>
                                <i slot="prefix" class="el-input__icon el-icon-user"></i>
                            </el-select>
                        </div>
                        <div class="reset-btn">
                            <a href="javascript:void(0)" @click="cleanFilter">
                                <i class="el-icon-refresh"></i>
                                <span>{{ 'clean' | translate }}</span>
                            </a>
                        </div>
                        <div class="filter-row-left"></div>
                        <div class="filter-row-right"></div>
                    </div>
                </div>

            </div>
            <div class="listing-page-content">
                <div class="p-archive-list">
                    <div class="p-list-wrap">
                        <div :class="`p-list-header ${sticky} ${archive_style}`">
                            <div class="list-main-wrap-title">
                                <h2>{{ 'result' | translate }} : <span>{{ 'showAll' | translate }} ({{ total }})</span></h2>
                            </div>

                            <div class="change-layout">
                                <el-pagination
                                        @current-change="paginate"
                                        layout="prev, pager, next"
                                        :page-size="8"
                                        :total="total">
                                </el-pagination>
                                <el-select size="small" v-model="filterData.sort" :placeholder="'_order' | translate"
                                           @change="getArticles">
                                    <el-option
                                            v-for="item in sortOptions"
                                            :key="item.index"
                                            :label="item.label"
                                            :value="item.value">
                                    </el-option>
                                </el-select>
                                <el-tooltip :content="'thumbnail' | translate" placement="top"
                                            :class="`change-layout-btn ${viewType === 0 ? 'active-layout-btn' : ''}`">
                                    <a href="javascript:void(0)" @click="viewType=0">
                                        <i class="fal fa-th"></i>
                                    </a>
                                </el-tooltip>
<!--                                <el-tooltip :content="'list_view' | translate" placement="top">-->
<!--                                    <a href="javascript:void(0)" @click="viewType=1"-->
<!--                                       :class="`change-layout-btn ${viewType === 1 ? 'active-layout-btn' : ''}`">-->
<!--                                        <i class="fal fa-list"></i>-->
<!--                                    </a>-->
<!--                                </el-tooltip>-->
                                <a href="https://webgis.mris.mn/?article=true" class="top-bar-button" target="_blank"><i class="fas fa-map-marker-alt"></i><span>Газарзүйн холболт</span></a>
                            </div>
                        </div>
                        <div class="p-list-wrap-inner no-saved">
                            <newsList v-if="articles.length > 0" :fTypes="categories" :total="total"
                                    :articles="articles" :layout="viewType"></newsList>
                            <div class="no-product" v-else>
                                <i class="far fa-meh"></i>
                                <div class="no-product-text">
                                    <p>{{ 'no_product_title_list' | translate }}</p>
                                    <span>{{ 'no_product_description_list' | translate }}</span>
                                </div>
                            </div>

                            <div v-if="loading" class="loading-wrap">
                                <div class="lds-ellipsis">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="limit-box fl-wrap"></div>
        </div>
    </div>
</template>

<script>
import gql from 'graphql-tag';
import {GET_PULISHMEND_LUTS} from "../graphql/queries";
import newsList from "../components/news_list.vue";
import {mapGetters} from "vuex";

export default {
    components: {

        newsList
    },
    data() {
        return {
            articles: [],
            total: 0,
            loading: true,
            ulsuud: [],
            years: [],
            sumuud: [],
            categories: [],
            sources: [],
            authors: [],
            currentPage: 1,
            q: '',
            filterData: {
                type: '',
                countery: '',
                year: '',
                author: '',
                sort: 'desc',
                search: '',
                content: '',
            },
            sortOptions: [
                {
                    value: 'desc',
                    label: 'Сүүлд нэмэгдсэн'
                }, {
                    value: 'asc',
                    label: 'Эхэнд нэмэгдсэн'
                }
            ],
            viewType: 0,
            archive_style: "",
        }
    },
    computed: {
        // mix the getters into computed with object spread operator
        ...mapGetters(["sticky", "language"]),
        myQuery(){
            let filters = [];
            let filtersString = "";

            if(this.filterData.type !== "" && this.filterData.type !==  null){
                filters.push({ column: "publish_category_id", condition: 'equals', value: this.filterData.type })
            }
            if(this.filterData.search !== "" && this.filterData.search !==  null){
                if(this.language.short === 'mn'){
                    filters.push({ column: "publish_content_mn", condition: 'contains', value: this.filterData.search })
                } else {
                    filters.push({ column: "publish_content_en", condition: 'contains', value: this.filterData.search })
                }


                if(this.language.short === 'mn'){
                    filters.push({ column: "publishing_mn", condition: 'contains', value: this.filterData.search })
                    filters.push({ column: "magazine_name", condition: 'contains', value: this.filterData.search })
                } else {
                    filters.push({ column: "publishing_en", condition: 'contains', value: this.filterData.search })
                    filters.push({ column: "magazine_name_en", condition: 'contains', value: this.filterData.search })
                }

            }

            if(this.filterData.author !== "" && this.filterData.author !==  null){
                if(this.language.short === 'mn'){
                    filters.push({ column: "mn_aouter", condition: 'contains', value: this.filterData.author })
                } else {
                    filters.push({ column: "en_aouter", condition: 'contains', value: this.filterData.author })
                }

            }

            if(this.filterData.year !== "" && this.filterData.year !==  null){
                filters.push({ column: "publishment_year", condition: 'equals', value: this.filterData.year })
            }

            if(this.filterData.countery !== "" && this.filterData.countery !==  null){
                filters.push({ column: "published_language", condition: 'equals', value: this.filterData.countery })
            }
            console.log(filters)

            if(filters.length >= 1){
                //[{ column: "publish_category_id", condition: equals, value: $publish_category_id }]

                filters.forEach(filter=>{
                  filtersString = filtersString+`${filtersString !== '' ? ',' : ''}{ column: "${filter.column}", condition: ${filter.condition}, value: "${filter.value}" }`

                })

                filtersString = `, filters: [${filtersString}]`

            }

            console.log(`query paginate($page:Int!, $size:Int!){
  paginate(sorts: [{ column: "created_at", order: ${this.filterData.sort} }]${filtersString}, page: $page, size: $size)
  {
    page
    total
    last_page
    ds_publishment {
          book_pub
          city_name
          conference_name
          countery_id
          created_at
          date
          deleted_at
          dio_num
          eissn_number
          id
          issn
          keyword
          link
          magazine_name
          magazine_photo
          page_num
          photos
          project_name
          publish_category_id
          publish_name_en
          publish_name_mn
          publishing_en
          publishing_mn
          publishment_date
          share
          social_link
          updated_at
          volume_num
          publishment_year

       }
   }
}`)

            return gql`query paginate($page:Int!, $size:Int!){
  paginate(sorts: [{ column: "created_at", order: ${this.filterData.sort} }]${filtersString}, page: $page, size: $size)
  {
    page
    total
    last_page
    ds_publishment {
          book_pub
          city_name
          conference_name
          countery_id
          created_at
          date
          deleted_at
          dio_num
          eissn_number
          id
          issn
          keyword
          link
          magazine_name
          magazine_photo
          page_num
          photos
          project_name
          publish_category_id
          publish_name_en
          publish_name_mn
          publishing_en
          publishing_mn
          publishment_date
          share
          social_link
          updated_at
          volume_num
          publishment_year

       }
   }
}`
        }
    },
    created() {
        this.checkRoute();
        this.getCategory();


    },
    mounted() {
        window.initTowhub()
        window.initparallax();

        if(this.$route.query.ref === 'home'){
            this.filterData.search = this.$route.query.q;
            this.filterData.aimag = this.$route.query.region;
            this.filterData.type = this.$route.query.type;
            this.getArticles();
        }else{
            this.getArticles();
        }
    },
    methods: {
        getCategory(){
            this.loading = true;
            this.$apollo.query({ query: GET_PULISHMEND_LUTS }).then(({data}) => {
                this.categories = data.lut_publishment_type;
                this.ulsuud = data.lut_publishment_language;
                this.years = data.ds_publishment_years;
                this.authors = data.ds_publishment_author_grouped;
                this.loading = false;
            })
        },

        checkRoute() {
            if (this.$route.path == "/news") {
                this.archive_style = "archive-style";
            }
        },

        closePage() {
            this.pageName = "";
            this.showPage = false;
        },

        getArticles() {
            this.loading = true;
            // this.$http.post(`/api/archive?page=${this.currentPage}`, this.filterData).then(({data}) => {
            //     this.articles = data.data;
            //     //console.log(this.articles)
            //     this.total = data.total
            //     setTimeout(() => {
            //         this.loading = false;
            //     }, 1000)
            // })
            // console.log(this.filterData)

            this.loading = true;
            this.$apollo.query({ query: this.myQuery, variables:{page: this.currentPage, size: 8},fetchPolicy: 'no-cache' }, ).then(({data}) => {
                this.articles = data.paginate.ds_publishment;

                this.total = data.paginate.total;
                setTimeout(()=>{
                    this.loading = false;
                })
            })
        },

        getType() {
            this.$http.get('/api/archive-type').then(({data}) => {
                this.types = data.data;
            })
        },

        choiceAimag() {
            this.getSum(this.filterData.aimag);
            this.getArticles()
        },

        getSum(id) {
            this.$http.get('/api/sum/' + id).then(({data}) => {
                this.sumuud = data;
            })
        },

        getSource() {
            this.$http.get('/api/source').then(({data}) => {
                this.sources = data;
            })
        },

        paginate(p) {
            this.currentPage = p;
            this.getArticles();
        },

        cleanFilter() {
            this.filterData = {
                type: '',
                countery: '',
                year: '',
                author: '',
                sort: 'desc',
                search: '',
                content: '',
            };
            this.getArticles();
        },
    }
}
</script>
