<template>
    <el-dropdown trigger="click" class="lang-wrap-top top-bar-button" placement="bottom">
      <div class="el-dropdown-link lang-btn">
          <i class="fal fa-globe-europe"></i>
          <span class="strong">{{ language.mid }}</span>
          <i class="el-icon-arrow-down el-icon--right"></i>
      </div>
        <el-dropdown-menu slot="dropdown" class="lang-item">
            <el-dropdown-item v-for="locale in languages" :key="locale.index" :class="locale.short == language.short ? 'current-lan' : ''">
                <a href="#" :data-lantext="locale.mid" @click="changeLocale(locale)">{{ locale.long }}</a>
            </el-dropdown-item>
        </el-dropdown-menu>
    </el-dropdown>
</template>


<script>
import Vue from 'vue';
import {mapGetters} from 'vuex'

export default {
    name: "languageSwitch",
    data() {
        return {
            langShow: false
        }
    },
    methods: {
        changeLocale(locale) {
            Vue.i18n.set(locale.short);
            this.$store.commit('setLanguage', locale);
        },
        closeLang() {
            this.langShow = false;
            // console.log(this.langShow);
        }
    },
    computed: {
        // mix the getters into computed with object spread operator
        ...mapGetters([
            'languages',
            'language',
            'isAuthenticated'
            // ...
        ])
    }
}
</script>
