<template>
  <footer class="main-footer fl-wrap">
    <!--    <div class="footer-header fl-wrap grad ient-dark">-->
    <!--      <div class="container">-->
    <!--        <div class="row">-->
    <!--          <div class="col-md-5">-->
    <!--            <div class="subscribe-header">-->
    <!--              <h3>Шинэ мэдээлэл <span>АВАХ</span></h3>-->
    <!--              <p>Шинэ мэдээллийг цаг алдалгүй авахыг хүсвэл, Имейл хаягаа бүртгүүлээрэй.</p>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="col-md-7">-->
    <!--            <div class="subscribe-widget">-->
    <!--              <div class="subcribe-form">-->
    <!--                <form id="subscribe">-->
    <!--                  <input class="enteremail fl-wrap" name="email" id="subscribe-email"-->
    <!--                         placeholder="Имейл хаягаа бичнэ үү" spellcheck="false" type="text">-->
    <!--                  <button type="submit" id="subscribe-button" class="subscribe-button"><i class="fal fa-envelope"></i>-->
    <!--                  </button>-->
    <!--                  <label for="subscribe-email" class="subscribe-message"></label>-->
    <!--                </form>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->

    <div class="footer-inner fl-wrap">
      <div class="container main-inner">
        <div class="row">
          <div class="col-md-3">
            <div class="footer-widget fl-wrap">
              <h3>{{ 'systems' | translate }}</h3>
              <div class="footer-logo">

                <ul class="w-links">
                  <li>
                    <a href="https://webgis.mris.mn/api/locale/MNG" target="_blank">
                      <img src="assets/images/webgis.png" alt="">
                      <div class="w-name">
                        <p>{{ 'mongeocat' | translate }}</p>
                        <small>{{ 'mongeocat_webgis_desc' | translate }}</small>
                      </div>
                    </a>
                  </li>

                  <li>
                    <a href="https://geonet.mris.mn/" target="_blank">
                      <img src="assets/images/geonet.png" alt="">
                      <div class="w-name">
                        <p>{{ 'mongeocat' | translate }}</p>
                        <small>{{ 'mongeocat_geonet_desc' | translate }}</small>
                      </div>
                    </a>
                  </li>

                  <li>
                    <a href="https://publicgeomin.mris.mn/" target="_blank">
                      <img src="assets/images/geomin.png" alt="">
                      <div class="w-name">
                        <p>{{ 'mongeomin' | translate }}</p>
                        <small>{{ 'mongeomin_desc' | translate }}</small>
                      </div>
                    </a>
                  </li>

                  <li>
                    <a href="https://mongeocol.mris.mn/portal/apps/sites/#/mongeocol" target="_blank">
                      <img src="assets/images/mongeocall1.png" alt="">
                      <div class="w-name">
                        <p>{{ 'mongeocol' | translate }}</p>
                        <small>{{ 'mongeocol_desc' | translate }}</small>
                      </div>
                    </a>
                  </li>

                </ul>

<!--                <a class="logo" href="/">-->
<!--                  <div class="EBRD">-->
<!--                    <img src="../assets/EBRD_logo.png" alt="">-->
<!--                  </div>-->
<!--                  <div>-->
<!--                    <span>{{ 'sanhvvjvvlegch' | translate }}</span>-->
<!--                  </div>-->
<!--                </a>-->
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="footer-widget fl-wrap">
              <h3> {{ 'link' | translate }}</h3>
              <div class="footer-widget-posts fl-wrap">
                <ul class="no-list-style">
                  <li class="clearfix">
                    <router-link to="/ask_question">
                      {{ 'answer_quistion' | translate }}
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/service">
                      {{ 'terms_conditions' | translate }}
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/help">
                      {{ 'hel_p' | translate }}
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/news">
                     {{ 'article' | translate }}
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/contact">
                      {{ 'contact_us' | translate }}
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/archive_payment">
                      {{ 'archive_information' | translate }}
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="footer-widget fl-wrap">
              <h3>{{ '_address' | translate }}</h3>
              <div class="footer-contacts-widget fl-wrap">
                <ul class="footer-contacts fl-wrap no-list-style">
                  <li><span><i class="fal fa-envelope"></i></span><a href="#" target="_blank">{{ contact.mail }}</a>
                  </li>
                  <li><span><i class="fal fa-globe"></i></span><a href="#" target="_blank">{{ contact.page }}</a>
                  </li>
                  <li><span><i class="fal fa-phone"></i></span><a href="#">{{ contact.phone_one }}, {{ contact.phone_one }}</a></li>
                </ul>
                <p v-if="language.short == 'mn'">{{ contact.address_mn }}</p>
                <p v-else>{{ contact.address_en }}</p>
                <div class="footer-social">
                  <span>{{ '_network' | translate }}: </span>
                  <ul class="no-list-style">
                    <li><a :href="contact.facebook" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                    <li><a :href="contact.twiiter" target="_blank"><i class="fab fa-twitter"></i></a></li>
                    <li><a :href="contact.linkedin" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!-- footer-widget end-->
        </div>
      </div>
      <!-- footer bg-->

      <div class="container bg-inner">
        <div class="footer-bg"></div>
      </div>
      <!-- footer bg  end-->
    </div>

    <div class="sub-footer fl-wrap">
      <div class="container">
        <div class="copyright">{{ 'national_geologal_survey' | translate }}.</div>
      </div>
    </div>
  </footer>
</template>
<script>

import {GET_CONTACT_ORGANIZATION} from "../graphql/queries";
import {mapGetters} from 'vuex'

export default {
  name: "AppFooter",
  data() {
    return {
      showAdvancedSearch:false,
      contact: {}
    }
  },
  methods:{
    getContact(){
      this.$apollo.query({ query: GET_CONTACT_ORGANIZATION }).then(({data}) => {
        this.contact = data.contact_organization[0];
      })
    },
  },

  mounted() {
    this.getContact();
  },

  computed: {
    ...mapGetters([
      'languages',
      'language',
      // ...
    ])
  }
}

</script>
