<template>
  <div id="wrapper">
    <!-- content-->
    <div class="content">
      <section class="parallax-section single-par custom-header" data-scrollax-parent="true">
        <div class="bg par-elem"  data-scrollax="properties: { translateY: '30%' }"></div>
        <div class="overlay op7"></div>
        <div class="container">
          <div class="section-title">
            <h2><span><b>{{ 'payment' | translate }} </b>{{ 'toPay' | translate }}</span></h2>
            <div class="breadcrumbs fl-wrap"><router-link to="/">{{ 'home' | translate }}</router-link><a href="#">{{ 'cart' | translate }}</a><span>{{ 'typePayment' | translate }}</span></div>
          </div>
        </div>
      </section>

      <section class="gray-bg no-top-padding-sec" id="sec1">
        <div class="container">
          <div class="fl-wrap ">
            <div class="row">
              <div class="col-md-8">
                <router-view></router-view>
              </div>

              <div class="col-md-4">
                <card-summary/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <!--content end-->
  </div>
</template>
<script>
import CardSummary from "../../components/CardSummary";
import {mapGetters} from "vuex";
export default {
  name: "Payment",
  components: {
    CardSummary
  },
  data() {
    return {
      selectedBank: null,
      isError: false
    }
  },
  computed: {
    ...mapGetters([
      'languages',
      'language',
      // ...
    ])
  },
  mounted() {
    window.initTowhub()
    window.initparallax();
  },

  methods: {
    bankSelected(val) {
      console.log(val);
      this.selectedBank = val;
    },

    gotoNext() {
      if(this.selectedBank == null) {
        this.isError = true
      }else{
        this.$router.push("/payment/qpay/transaction");
      }
    }
  }
}
</script>
