<template>
  <div>
    <div id="wrapper">
      <div class="content">
        <section class="parallax-section single-par custom-header" data-scrollax-parent="true">
          <div class="bg par-elem" data-bg="" data-scrollax="properties: { translateY: '30%' }"></div>
          <div class="overlay op7"></div>
          <div class="container">
            <div class="section-title">
              <h2><span><b>{{ 'terms' | translate }} </b> {{ 'conditions' | translate }}</span></h2>
              <div class="breadcrumbs fl-wrap"><router-link to="/">{{ 'home' | translate }}</router-link><span>{{ 'terms_conditions' | translate }}</span></div>
            </div>
          </div>
        </section>
        <section class="gray-bg" id="sec3">
          <div v-if="loading" class="loading-wrap">
            <div class="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <div class="container" v-else>
            <div class="post-container fl-wrap" >
              <div class="row">
                <div class="col-md-3">
                  <div class="faq-nav help-bar scroll-init">
                    <ul class="no-list-style">
                      <li  :key="index" v-for="(cat, index) in term_cat">
                        <a class="item" href="javascript:void(0);" :class="[(index+1===activeCategory) ? 'visible':'']" @click="callData(cat.nohtsol_id)">
                          <!--                          <a href="javascript:void(0);" @click="callData(`${cat.id}`)">-->
                          <i class="fal fa-border-right"></i>
                          <span v-if="language.short == 'mn'">{{ cat.term_nohtsol }}</span>
                          <span v-else>{{ cat.term_eng }}</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-9">
<!--                                    <div class="faq-title faq-title_first fl-wrap" id="fq1">test</div>-->
                  <div class="faq-section fl-wrap" >
                    <!-- accordion-->
                    <div class="accordion"  :key="index" v-for="(payment_term, index) in GET_SUB_TERM_BY_ID">
                      <a class="toggle "  :class="[(activeItem == payment_term.id) ? 'act-accordion':'']" @click="itemActive(payment_term.id)" href="#" >
                        <div v-if="language.short == 'mn'"> {{ payment_term.zaalt }}</div>
                        <div v-else> {{ payment_term.zaalt_eng }}</div>
                        <span></span></a>
                      <div class="accordion-inner" :class="[(activeItem == payment_term.id) ? 'visible':'']">
                        <p v-if="language.short == 'mn'" v-html="payment_term.term_mn"></p>
                        <p v-else v-html="payment_term.term_en"></p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- blog sidebar end -->
              </div>
            </div>
          </div>
        </section>

        <div class="limit-box fl-wrap"></div>
      </div>
      <!--content end-->
    </div>
  </div>
</template>

<script>
import {
  GET_SUB_TERM_BY_ID,
  GET_TERM,
  //GET_SUB_TERM
} from "../graphql/queries";
import {mapGetters} from "vuex";

export default {
  name: "Faq",
  data() {
    return {
      //currentCat: 1,
      term_cat: [],
      GET_SUB_TERM_BY_ID: [],
      activeItem:'0',
      loading: true,
      activeCategory: 1
    }
  },
  computed: {
    ...mapGetters([
      'languages',
      'language',
      // ...
    ])
  },
  methods:{
    getTERM(){
      this.loading = true;
      this.$apollo.query({ query: GET_TERM }).then(({data}) => {
        this.term_cat = data.ds_term;
        //console.log(this.term_cat);
        this.loading = false;
      })
    },
    itemActive(p) {this.activeItem = p;},
    callData(cat_id){
      this.currentCat = cat_id;
      this.activeCategory = cat_id
      //console.log(this.currentCat);
      this.loading = true;
      if (this.currentCat) {
        this.$apollo.query({ query: GET_SUB_TERM_BY_ID, variables: { nohtsol_id: this.currentCat.toString() }}).then(({data}) => {
          this.GET_SUB_TERM_BY_ID = data.sub_term;
          setTimeout(()=>{
            this.loading = false;
          })
        });
      }else{
        this.activeCategory = 1;
        this.$apollo.query({ query: GET_SUB_TERM_BY_ID, variables: { nohtsol_id: this.activeCategory.toString() }}).then(({data}) => {
          this.GET_SUB_TERM_BY_ID = data.sub_term;
          setTimeout(()=>{
            this.loading = false;
          })
        });
      }
    },
  },
  mounted() {
    this.getTERM();
    this.callData();
    window.initTowhub()
    window.initparallax();
  }
}
</script>

<style scoped>

</style>