<template>
  <div class="fl-wrap ">
    <ul id="progressbar" class="no-list-style">
      <li class="active"><span class="tolt" data-microtip-position="top" :data-tooltip="languages == 'mn' ? `Төлбөрийн нөхцөл` : `Payment condition`">01.</span></li>
      <li class="active"><span class="tolt" data-microtip-position="top" :data-tooltip="languages == 'mn' ? `Төлбөр төлөх` : `Pay the fee`">02.</span></li>
      <!--                                    <li><span class="tolt" data-microtip-position="top" data-tooltip="Payment Method">03.</span></li>-->
      <li class="active"><span class="tolt" data-microtip-position="top" :data-tooltip="this.languages == 'mn' ? `Амжилттайd` : `Success`">03.</span></li>
    </ul>
    <div class="bookiing-form-wrap block_box fl-wrap">
      <!--   list-single-main-item -->
      <div class="list-single-main-item fl-wrap hidden-section tr-sec">
        <div class="profile-edit-container">
          <div class="custom-form">
            <div class="payment-done">

              <div class="status-box">
                <div class="check-wrap">
                  <i class="el-icon-check"></i>
                </div>
                <h3>{{ 'successfully_paid' | translate }}</h3>
              </div>

              <div class="p-action-end">
                <router-link to="/profile/order-list" class="btn float-btn color4-bg submit-btn next-btn"
                             id="submit">
                  {{ 'order_list' | translate }} <i class="fa fa-arrow-right"></i>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PaymentStatus',
  mounted() {
    window.initTowhub()
    window.initparallax();
  },
}
</script>