<template>
  <div>
    <div id="map"></div>
  </div>

</template>

<script>
import {removeAll, DDM, view, webmap} from "./map";
import {mapGetters} from "vuex";


export default {
  name: 'WebMap',
  props: ["filterData", "report_number"],
  components: {},
  computed: {
    ...mapGetters([
      'languages',
      'language',
      // ...
    ])
  },
  data() {
    return {
      layerView: null
    }
  },
  methods: {
    clear() {
      removeAll();
    },

    async getArcGISTOKEN() {
      return await this.$http.get('/api/arcgis-token?type=' + process.env.NODE_ENV);
    },
    DoFilter() {

      if (this.layerView) {
        let query = '';
        if (this.filterData.source != "") {

          if (query != "") {
            query = `${query} AND source_id = ${this.filterData.source}`;
          } else {
            query = `source_id = ${this.filterData.source}`;
          }
        }
        if (this.filterData.sum != "") {

          if (query != "") {
            query = `${query} AND sub_region_id = ${this.filterData.sum}`;
          } else {
            query = `sub_region_id = ${this.filterData.sum}`;
          }
        }
        if (this.filterData.type != "") {

          if (query != "") {
            query = `${query} AND product_type_id = ${this.filterData.type}`;
          } else {
            query = `product_type_id = ${this.filterData.type}`;
          }
        }
        if (this.filterData.aimag != "") {

          if (query != "") {
            query = `${query} AND province_id = ${this.filterData.aimag}`;
          } else {
            query = `province_id = ${this.filterData.aimag}`;
          }
        }


          this.layerView.filter = {
            where: query,
          };

      }
    },
    async initMap(){
      const app = await import('./map.js');
      const response = await this.getArcGISTOKEN();
      if (response.data.token) {

        app.initialize(document.getElementById("map"), response.data);

        view.whenLayerView(DDM).then(layerView => {
          this.layerView = layerView;

          if(this.report_number){
            this.layerView.filter = {
              where: `report_number = ${this.report_number}`,
            };
            const query = DDM.createQuery();
            query.outSpatialReference = { latestWkid: 3857, wkid: 102100 };
            query.orderByFields = 'report_number';
            query.where = `report_number = ${this.report_number}`

            DDM.queryExtent(query).then(response => {
              if(response.count >= 1){
                view.goTo(response.extent);
              }
            });

            // view.goTo
          } else {

            const query = DDM.createQuery();
            query.outSpatialReference = { latestWkid: 3857, wkid: 102100 };
            query.orderByFields = 'report_number';
            DDM.queryExtent(query).then(response => {

              view.goTo(response.extent);
            });
          }

        });


      }
    }

  },

  async mounted() {
    await this.initMap();
  },
  beforeDestroy(){
    webmap.removeAll();
  },
  watch: {
    filterData: {
      handler() {
        this.DoFilter();
      },
      deep: true
    },
    async language(){
      webmap.removeAll();
      await this.initMap();
    }
  }
};
</script>

