<template>
  <div id="wrapper">
    <!-- content-->
    <div class="content">
      <!--  section  -->
      <section class="parallax-section single-par" data-scrollax-parent="true">
        <div class="bg par-elem "  data-bg="images/bg/34.jpg" data-scrollax="properties: { translateY: '30%' }"></div>
        <div class="overlay op7"></div>
        <div class="container">
          <div class="section-title center-align big-title">
<!--            <h2><span> {{ 'archive_information' | translate }}</span></h2>-->
<!--            <span class="section-separator"></span>-->
            <div class="breadcrumbs fl-wrap"><router-link to="/">{{ 'home' | translate }}</router-link><a href="#">{{ 'archive_information' | translate }}</a></div>
          </div>
        </div>
        <div class="header-sec-link">
          <a href="#sec1" class="custom-scroll-link"><i class="fal fa-angle-double-down"></i></a>
        </div>
      </section>
      <!--  section  end-->
      <section   id="sec1" data-scrollax-parent="true">
        <div class="container">
          <div class="section-title">
            <h2>{{ 'archive_information' | translate }}</h2>
            <div class="section-subtitle">{{ 'archive' | translate }}</div>
<!--            <span class="section-separator"></span>-->
<!--            <p>Explore some of the best tips from around the city from our partners and friends.</p>-->
          </div>
<!--          <div class="pricing-switcher">-->
<!--            <div class="fieldset color-bg">-->
<!--              <input type="radio" name="duration-1"  id="monthly-1" class="tariff-toggle" checked>-->
<!--              <label for="monthly-1">Monthly Tariff</label>-->
<!--              <input type="radio" name="duration-1" class="tariff-toggle"  id="yearly-1">-->
<!--              <label for="yearly-1">Yearly Tariff</label>-->
<!--              <span class="switch"></span>-->
<!--            </div>-->
<!--          </div>-->
        <div class="pricing-wrap fl-wrap">
          <div class="row ">
            <div class="col-md-4 ">
              <div class="archive-price-item">
                <div class="_bg">
                  <div class="title">Ном, тайлан баримт бичиг</div>
                  <p>Улсын төсвийн хөрөнгөөр хийгдсэн геологийн судалгааны бүх үе шатны ажлын тайлангаас хуулбар хийх:</p>
                  <table class="table">
                    <thead>
                    <tr>
                      <th colspan="2">А4</th>
                      <th>Хүснэгт</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>1-50 хуудас</td>
                      <td>50-дээш</td>
                      <td>1 хуудас</td>
                    </tr>
                    <tr>
                      <td>1000 ₮</td>
                      <td>2000 ₮</td>
                      <td>1500 ₮</td>
                    </tr>
                    </tbody>
                  </table>
                  <p>Хувийн хөрөнгөөр хийгдсэн  судалгааны ажлын тайлангаас тусгай зөвшөөрлийн дагуу хуулбар хийх:</p>
                  <table class="table">
                    <thead>
                    <tr>
                      <th colspan="2">А4</th>
                      <th>Хүснэгт</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>1-50 хуудас</td>
                      <td>50-дээш</td>
                      <td>1 хуудас</td>
                    </tr>
                    <tr>
                      <td>1000 ₮</td>
                      <td>2000 ₮</td>
                      <td>1500 ₮</td>
                    </tr>
                    </tbody>
                  </table>
                  <p>Улсын нөөц баталсан даргын тушаал, протокол, ЭБМЗ-ийн дүгнэлт:</p>
                  <table class="table">
                    <thead>
                    <tr>
                      <th colspan="2">А4</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>1 хуудас</td>
                      <td>5000 ₮</td>
                    </tr>
                    </tbody>
                  </table>
                  <p>Файлаар илгээх болон CD-нд бичих</p>
                  <table class="blue-bg-table">
                    <tbody>
                    <tr>
                      <td>1 хуудас</td>
                      <td>2000 ₮</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-md-4 ">
              <div class="archive-price-item top-item">
                <div class="_bg">
                  <div class="title">Зургаас хуулбар олгох</div>
                  <p>Тайлангийн бүх төрлийн зургаас хуулбар хийх:</p>
                  <table class="table">
                    <thead>
                    <tr>
                      <th>А4</th>
                      <th>А3</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>1000 ₮</td>
                      <td>1500 ₮</td>
                    </tr>
                    </tbody>
                  </table>
                  <p>Байр зүйн 1:500000, 1:200000-ны масштабын зургаас хуулбар хийх</p>
                  <table class="table">
                    <tbody>
                    <tr>
                      <td>1 лист</td>
                      <td>2000 ₮</td>
                    </tr>
                    </tbody>
                  </table>
                  <p>Бусад зургаас хуулбар хийх</p>
                  <table class="table">
                    <thead>
                    <tr>
                      <th>А4</th>
                      <th>А3</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>2000 ₮</td>
                      <td>2500 ₮</td>
                    </tr>
                    </tbody>
                  </table>
                  <p>Шинээр олгох хайгуулын болон ашиглалтын тусгай зөвшөөрлийн талбайн зураг хэвлэх</p>
                  <table class="table">
                    <thead>
                    <tr>
                      <th>А4</th>
                      <th>Зураг</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td colspan="2">2000 ₮</td>
                    </tr>
                    </tbody>
                  </table>
                  <p>Хэсэгчлэн болон бүхэлд нь буцаах болон шилжүүлэх талбайн зураг хэвлэх</p>
                  <table class="table">
                    <thead>
                    <tr>
                      <th>А4</th>
                      <th>Зураг</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td colspan="2">2000 ₮</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-md-4 ">
              <div class="archive-price-item">
                <div class="_bg">
                  <div class="title">Зураг скайнердаж, файлаар олгох, өнгөтөөр хэвлэх</div>
                  <p>Геологийн бүх төрлийн судалгааны зураг скайнердах</p>
                  <table class="table">
                    <thead>
                    <tr>
                      <th>А4</th>
                      <th>А3</th>
                      <th>А2-А0</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>10000 ₮</td>
                      <td>15000 ₮</td>
                      <td>30000 ₮</td>
                    </tr>
                    </tbody>
                  </table>
                  <p>Сансарын зураг скайнердах</p>
                  <table class="table">
                    <thead>
                    <tr>
                      <th>А4</th>
                      <th>А3</th>
                      <th>А2-А0</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>10000 ₮</td>
                      <td>15000 ₮</td>
                      <td>30000 ₮</td>
                    </tr>
                    </tbody>
                  </table>
                  <p>Байр зүйн зураг скайнердах, хэвлэх</p>
                  <table class="table">
                    <tbody>
                    <tr>
                      <td>1 лист</td>
                      <td>10000 ₮</td>
                    </tr>
                    </tbody>
                  </table>
                  <p>Геологийн бүх төрлийн судалгааны /картграмм/ зураг скайнердаж CD-д бичих</p>
                  <table class="table">
                    <thead>
                    <tr>
                      <th>А4</th>
                      <th>А3</th>
                      <th>А2-А0</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>5000 ₮</td>
                      <td>10000 ₮</td>
                      <td>30000 ₮</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

<!--          <div class="pricing-wrap fl-wrap">-->
<!--            &lt;!&ndash; price-item&ndash;&gt;-->
<!--            <div class="archive-price-item">-->

<!--            </div>-->
<!--            &lt;!&ndash; price-item end&ndash;&gt;-->
<!--            &lt;!&ndash; price-item&ndash;&gt;-->
<!--            <div class="price-item best-price">-->
<!--              <div class="price-head   gradient-bg">-->
<!--                <h3>Extended</h3>-->
<!--                <div class="price-num col-dec-2 fl-wrap">-->
<!--                  <div class="price-num-item"><span class="mouth-cont"><span class="curen">$</span>109</span><span class="year-cont"><span class="curen">$</span>1010</span></div>-->
<!--                  <div class="clearfix"></div>-->
<!--                  <div class="price-num-desc"><span class="mouth-cont">Per  Month</span><span class="year-cont">Per Year</span></div>-->
<!--                </div>-->
<!--                <div class="circle-wrap" style="right:20%;top:70px;"  >-->
<!--                  <div class="circle_bg-bal circle_bg-bal_versmall"></div>-->
<!--                </div>-->
<!--                <div class="circle-wrap" style="right:70%;top:40px;"  >-->
<!--                  <div class="circle_bg-bal circle_bg-bal_versmall" data-scrollax="properties: { translateY: '-150px' }"></div>-->
<!--                </div>-->
<!--                <div class="footer-wave">-->
<!--                  <svg viewbox="0 0 100 25">-->
<!--                    <path fill="#fff" d="M0 60 V2 Q30 17 55 12 T100 11 V30z" />-->
<!--                  </svg>-->
<!--                </div>-->
<!--                <div class="footer-wave footer-wave2">-->
<!--                  <svg viewbox="0 0 100 25">-->
<!--                    <path fill="#fff" d="M0 90 V16 Q30 7 45 12 T100 5 V30z" />-->
<!--                  </svg>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="price-content fl-wrap">-->
<!--                <div class="price-desc fl-wrap">-->
<!--                  <ul class="no-list-style">-->
<!--                    <li>Ten Listings</li>-->
<!--                    <li>Lifetime Availability</li>-->
<!--                    <li>Featured In Search Results</li>-->
<!--                    <li>24/7 Support</li>-->
<!--                  </ul>-->
<!--                  <a href="#" class="price-link rec-link color-bg">Choose Extended</a>-->
<!--                  <div class="recomm-price">-->
<!--                    <i class="fal fa-check"></i>-->
<!--                    Recommended-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            &lt;!&ndash; price-item end&ndash;&gt;-->
<!--            &lt;!&ndash; price-item&ndash;&gt;-->
<!--            <div class="price-item">-->
<!--              <div class="price-head green-gradient-bg  ">-->
<!--                <h3>Professional</h3>-->
<!--                <div class="price-num col-dec-1 fl-wrap">-->
<!--                  <div class="price-num-item"><span class="mouth-cont"><span class="curen">$</span>149</span><span class="year-cont"><span class="curen">$</span>1430</span></div>-->
<!--                  <div class="clearfix"></div>-->
<!--                  <div class="price-num-desc"><span class="mouth-cont">Per  Month</span><span class="year-cont">Per Year</span></div>-->
<!--                </div>-->
<!--                <div class="circle-wrap" style="right:20%;top:50px;"  >-->
<!--                  <div class="circle_bg-bal circle_bg-bal_versmall" data-scrollax="properties: { translateY: '50px' }"></div>-->
<!--                </div>-->
<!--                <div class="circle-wrap" style="right:75%;top:90px;"  >-->
<!--                  <div class="circle_bg-bal circle_bg-bal_versmall"  ></div>-->
<!--                </div>-->
<!--                <div class="footer-wave">-->
<!--                  <svg viewbox="0 0 100 25">-->
<!--                    <path fill="#fff" d="M0 30 V12 Q30 17 55 12 T100 11 V30z" />-->
<!--                  </svg>-->
<!--                </div>-->
<!--                <div class="footer-wave footer-wave2">-->
<!--                  <svg viewbox="0 0 100 25">-->
<!--                    <path fill="#fff" d="M0 90 V12 Q30 7 45 12 T100 11 V30z" />-->
<!--                  </svg>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="price-content fl-wrap">-->
<!--                <div class="price-desc fl-wrap">-->
<!--                  <ul class="no-list-style">-->
<!--                    <li>Unlimited Listings</li>-->
<!--                    <li>Lifetime Availability</li>-->
<!--                    <li>Featured In Search Results</li>-->
<!--                    <li>24/7 Support</li>-->
<!--                  </ul>-->
<!--                  <a href="#" class="price-link green-bg">Choose Professional</a>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            &lt;!&ndash; price-item end&ndash;&gt;-->
<!--          </div>-->

        </div>
      </section>
    </div>
    <!--content end-->
  </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: 'News',
  data() {
    return {

    }
  },
  mounted() {

  },
  computed: {
    ...mapGetters([
      'languages',
      'language',
      // ...
    ])
  },
  methods:{

  },
}
</script>
