<template>
  <div :class="`p-list-content scrollable ${layout === 1 ? 'product-list' : ''}`">
    <NewsItem v-for="item in articles" :key="item.id" :fTypes="fTypes" :article="item" :layout="layout"></NewsItem>
  </div>
</template>

<script>
import NewsItem from "./NewsItem.vue";

export default {
  name: "p_list",
  props: ['articles', 'fTypes', "total", "layout"],
  components: {
      NewsItem,
  },
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>
