<template>
  <div id="wrapper">
    <div class="content ">
      <section class="parallax-section single-par custom-header" data-scrollax-parent="true">
        <div class="bg par-elem" data-bg="/assets/images/home-slide/home-slide-bg-1.jpg"
             data-scrollax="properties: { translateY: '30%' }"></div>
        <div class="overlay op7"></div>
        <div class="container">
          <div class="section-title">
            <h2><span><b>Файл </b> татах</span></h2>
            <div class="breadcrumbs fl-wrap"><a href="#">{{ 'home' | translate }}</a><span>файл татах</span></div>
          </div>
        </div>
      </section>

      <section id="sec1" class="contact-main">
        <div class="container">
          <div class="download" >
            <h2 class="title">Файл татах</h2>

            <div class="file-info" v-if="isGenerated">
              <img src="images/all/62.jpg" alt="">
              <div class="inner-info">
                <h3>File name</h3>
                <div class="inner-info-sub">
                  <ul v-if="oneTimeLink">
                    <li>
                      <span>Файлын хэмжээ</span>
                      <span>{{ oneTimeLink.file_size }}</span>
                    </li>
                    <li>
                      <span>Файлын төрөл</span>
                      <span> {{ oneTimeLink.file_extention }}</span>
                    </li>
                    <li>
                      <span>Файлын татах боломж</span>
                      <span>{{ oneTimeLink.downloaded_count }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="prepare-section" v-if="isLoading && isGenerating==false">
              <div class="lds-spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>

              <div class="load-txt" href="javascript:void(0)" @click="downloadFile()">
                Татах холбоос шалгаж байна...
              </div>
            </div>
            <div class="prepare-section" v-if="isLoading && isGenerating==true">
              <div class="lds-spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>

              <div class="load-txt" href="javascript:void(0)" @click="downloadFile()">
                Татах холбоос үүсгэж байна...
              </div>
            </div>

            <div v-else>

              <a v-if="!isLoading && isGenerated" :class="`load-button ${isDownloading ? 'loading' : ''}`" @click="downloadFile()">
                <span v-if="isDownloading" class="spinner"></span>
                <div class="btn-txt" v-else>
                  <span>Файл татах</span>
                  <i class="fa fa-download"></i>
                </div>
              </a>
              <div class="fail-info" v-else-if="!isError && isGenerating && noLink">
                <el-alert
                    title="Татах холбоос"
                    type="warning"
                    :closable="false"
                    description="Татах холбоос үүсгэж дуустал түр хүлээнэ үү.">
                </el-alert>
              </div>
              <div class="fail-info" v-else-if="isError">
                <el-alert
                    title="Алдаа гарлаа"
                    type="error"
                    :closable="false"
                    description="Татах холбоосыг үүсгэхэд алдаа гарлаа.">
                </el-alert>
                <a href="javascript:void(0)" @click="checkOneTimeLink()">Дахин оролдох <i
                    class="fa fa-react"></i></a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
export default {
  name: "Download",
  data() {
    return {
      oneTimeLink: null,
      isLoading: true,
      isError: false,
      isGenerated: false,
      isGenerating: false,
      isDownloading: false,
      isDownloadSuccess: false,
      noLink: false,
    }
  },

  computed: {
    ...mapGetters([
      'user'
    ])
  },
  mounted() {
    window.initTowhub()
    window.initparallax();
    this.checkOneTimeLink();
  },

  methods: {
    checkOneTimeLink() {
      let id = this.$route.params.id
      this.isLoading = true;
      this.generateLink = {
        id: parseInt(id),
        user_id: this.user.id,
      };
      //нэг удаагийн татах холбоос үүссэн эсэх
      this.$http.post('/api/check-one-time-link', this.generateLink).then(({data}) => {
        if (data.status) {
          setTimeout(() => {
            this.isLoading = false;
            this.isGenerated = true;
            this.oneTimeLink = data.data;
          }, 1000)
        } else {
          setTimeout(() => {
            this.isLoading = false;
            if (data.type == "nodownloadlink") {
               this.noLink=true;
               this.isGenerating=true;
               //Татах холбоос үүсээгүй тул шинээр үүсгэж байна
               this.generateOneTimeLink();
            } else {
              this.isGenerated = false;
              this.isError=true;
            }
            // this.noLink = true;
            // this.isGenerating = true;
            // this.isLoading=true;
          }, 1000)
        }
      }).catch(e => {
        console.log(e)
        setTimeout(() => {
          this.isLoading = false;
          this.isGenerated = false;
        }, 1000)
      })
    },
    //Шинээр татах холбоос үүсгэж байна
    generateOneTimeLink() {
      let id = this.$route.params.id

      this.isLoading = true;

      let expireDate = new Date();
      console.log("expireDate");
      console.log(expireDate);
      expireDate.setDate(expireDate.getDate() + 1 * 7);
      console.log("expireDate after:");
      console.log(expireDate);

      this.generateLink = {
        id: parseInt(id),
        user_id: this.user.id,
        expire_date: expireDate
      };
      //Нэг удаагийн татах холбоос үүсгэх
      this.$http.post('/api/one-time-link', this.generateLink).then(({data}) => {
        if (data.status) {
          setTimeout(() => {
            this.isLoading = false;
            this.isGenerated = true;
            this.oneTimeLink = data.data;
            window.location.reload();
          }, 1000)
        } else {
          setTimeout(() => {
            this.isLoading = false;
            this.isGenerated = false;
            this.isError=true;
            if (data.type != "error") {
                 console.log("error");
            }
          }, 1000)
        }
      }).catch(e => {
        console.log(e)
        setTimeout(() => {
          this.isLoading = false;
          this.isGenerated = false;
        }, 1000)
      })
    },
    downloadFile() {
      this.isDownloading = true;
      this.$http({
        url: '/api/file-download/' + this.oneTimeLink.id, //your url
        method: 'POST',
      }).then((response) => {
        console.log(response.data);
        const url = this.$http.defaults.baseURL+response.data.file;
        const link = document.createElement('a');
        link.href = url;
        link.target="_blank";
        link.setAttribute('download', 'mris_file'+response.data.file_extention); //or any other extension
        document.body.appendChild(link);
        link.click();

        setTimeout(() => {
          this.isDownloading = false;
          this.$message({
            message: 'Файл амжилттай татагдлаа',
            type: 'success'
          });
          this.checkOneTimeLink();
        }, 1000)
      }).catch(e => {
        console.log(e)
        this.$message({
          message: 'Файл татах үед алдаа гарлаа. Та дахин оролдоно уу!',
          type: 'error'
        });
      })
    },
  }
}
</script>