<template>
  <div class="element-item article-item element-archive-item">
    <router-link :to="`/newsDetails/${article.id}`" class="item-img">
<!--      <img v-if="article.magazine_photo" :src="`https://mris.mn${article.magazine_photo}`" alt="">-->
      <img src="images/all/52.jpg" alt="">
    </router-link>

    <div class="card-product-date isotope">
      <i class="fal fa-clock"></i>
      <span>{{ article.publishment_year == null ? 'хоосон' : article.publishment_year }}</span>
      <!--            <span>{{ article.publish_name_mn_pro }}</span>-->
    </div>
<!--    <div class="item-price">-->
<!--      {{ article.price_mnt }}₮-->
<!--    </div>-->
    <div class="item-content">
      <h3 class="title article-title" v-if="this.$store.state.i18n.locale === 'mn'">
          {{ article.publish_name_mn }}
      </h3>
      <h3 class="title article-title" v-else>
          {{ article.publish_name_en ? article.publish_name_en : article.publish_name_mn }}
      </h3>
<!--      <div class="item-location">-->
<!--        <i class="fas fa-map-marker-alt"></i>-->
<!--        <span>{{ article.city_name }}</span>-->
<!--      </div>-->
        <p class="description article-description" v-if="language.short == 'mn'" v-html="article.publishing_mn"></p>
        <p class="description article-description" v-else v-html="article.publishing_en"></p>
    </div>
    <div class="artilce-read-more">
        <router-link :to="'/newsDetails/' + article.id"   class="btn color2-bg btn-small">{{ 'read_more' | translate }}<i class="fal fa-angle-right"></i></router-link>
    </div>

  </div>
</template>

<script>
import {checkFavorites, toggleFavorites} from "../utils/helper";
import {mapGetters} from "vuex";

export default {
  props: ["article", "removeItem", "fTypes", "layout"],
  data() {
    return {
      isFavorite: false,
    };
  },
  mounted() {
    this.checkFavorites();
  },
  computed: {
    ...mapGetters([
      'languages',
      'language',
      // ...
    ])
  },
  methods: {
    toggleFavorites() {
      toggleFavorites(this, this.article.id.toString());
    },

    checkFavorites() {
      checkFavorites(this, this.article.id.toString())
    },

    getTypeName(val) {
      let type = this.$props.fTypes.find(item => item.id == val)
      if (type) {
        return type.t_name;
      }
      return "";
    },

    getTypeIcon(val) {
      switch (val) {
        case 1:
          return 'fas fa-file-alt';
        case 2:
          return 'fas fa-bezier-curve';
        case 3:
          return 'far fa-images';
        case 4:
          return 'fas fa-atlas';
      }
    },
    getTypeIconColor(val) {
      switch (val) {
        case 1:
          return 'document';
        case 2:
          return 'vector';
        case 3:
          return 'web-images';
        case 4:
          return 'geo';
      }
    },
    getProvince(val) {
      console.log(val);
    }
  },
};
</script>
<style>
.article-title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
    text-transform: capitalize;
}
.article-description{
    margin-bottom: 0;
}
.article-description {
    max-height: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0;
}
.article-item{
    min-height: 380px !important;
    max-height: 380px !important;
}
.artilce-read-more {
    position: absolute;
    display: block;
    align-items: end;
    justify-content: space-between;
    width: auto;
    right: 0px;
    text-align: right;
    bottom: 0;
    padding: 5px;
}
.product-list .article-item{
    min-height: 300px !important;
    max-height: 300px !important;
}
.product-list .article-description {
    max-height: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0;
}
</style>