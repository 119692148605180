import Vue from "vue";
import Vuex from "vuex";
import {getBasketItems} from "../utils/helper";

Vue.use(Vuex);

let state = {
    user_token: localStorage.getItem('user-token'),
    user: JSON.parse(localStorage.getItem('user')),
    language: null,
    languages: [
        {
            short: "mn",
            mid: "МОН",
            long: "Монгол"
        },
        {
            short: "en",
            mid: "ENG",
            long: "English"
        },
    ],
    basketItems: [],
    totalPrice: 0,
    totalPriceUsd: 0,
    isInBasket: false,
    sticky: false
};
const savedLocale = localStorage.getItem('language');
if(savedLocale){
    let localeIndex = state.languages.findIndex(locale=>locale.short == savedLocale);
    if(localeIndex >= 0){
        state.language = state.languages[localeIndex];
    } else {
        state.language = state.languages[0];
    }

} else {
    state.language = state.languages[0];
}

window.init.languge = state.language;
const store = new Vuex.Store({
    state: state,
    mutations: {
        setStorageValues(state) {
            state.user = JSON.parse(localStorage.getItem('user'));
            state.user_token = localStorage.getItem('user_token');
        },
        setLanguage(state, lang) {
            state.language = lang;
            window.init.languge = state.language;
            localStorage.setItem('language', lang.short);
        },
        setSticky(state, sticky) {
            state.sticky = sticky;
        },
        setUser(state, user) {
            state.user = user;
            localStorage.setItem('user', JSON.stringify(user));
        },

        setUserToken(state, token) {
            state.user_token = token;
            localStorage.setItem('user_token', token);
        },

        setBasketItems(state, payload) {
            state.basketItems = payload
            let ids = "";
            state.basketItems.forEach(item => {
                state.totalPrice += parseFloat(item.price_mnt)
                state.totalPriceUsd += parseFloat(item.price_usd)

                if (ids === "") {
                    ids += item.id;
                } else {
                    ids += "," + item.id;
                }
            })

            console.log("setting count", state.basketItems.length);
            localStorage.setItem("basket", ids)
        },

        clearBasket(state) {
            state.basketItems = []
            state.totalPrice = 0
            state.totalPriceUsd = 0
            state.isInBasket = false
            localStorage.removeItem("basket")
        },

        setInBasket(state, payload) {
            let isExist = state.basketItems.find(item => item.id == payload);
            console.log(isExist, state.basketItems, payload);
            if(isExist){
                state.isInBasket = true
            }else {
                console.log("removing from basket")
                state.isInBasket = false
            }
        },

        setInBasketForce(state, payload){
            state.isInBasket = payload
        },
    },
    getters: {
        languages() {
            return state.languages
        },
        language() {
            return state.language
        },
        sticky() {
            return state.sticky
        },
        user() {
            return state.user
        },
        userToken() {
            return state.user_token
        },
        basketItems: (state) => state.basketItems,
        totalPrice: (state) => state.totalPrice,
        totalPriceUsd: (state) => state.totalPriceUsd,
        isInBasket: (state) => state.isInBasket,
    },

    actions: {
        setSticky({commit}, val) {
            state.sticky = val;
            commit("setSticky")
        },
        clearBasket({commit}) {
            commit("clearBasket")
        },

        toggleBasketItem({commit}, id) {
            if (localStorage.getItem("basket")) {
                let favorites = localStorage.getItem("basket");
                let favArr = favorites.split(",");
                let isExist = favArr.find((item) => item == id);
                if (isExist) {
                    favArr = favArr.filter((item) => item != id);
                    commit("setInBasketForce", false)
                } else {
                    favArr.push(id);
                    commit("setInBasketForce", true)
                }
                let favStr = favArr.join(",");
                localStorage.setItem("basket", favStr);
            } else {
                let favArr = [id];
                let favStr = favArr.join(",");
                localStorage.setItem("basket", favStr);
                commit("setInBasketForce", true)
            }

            let items = localStorage.getItem("basket")
            if (items !== "") {
                Vue.prototype.$http.post('/api/card/items', {items: items}).then((o) => {
                    commit("setBasketItems", o.data)
                })
            }else{
                commit("clearBasket")
            }
        },

        setBasketItems({commit}) {
            let items = getBasketItems();
            if (items !== "") {
                Vue.prototype.$http.post('/api/card/items', {items: items}).then((o) => {
                    commit("setBasketItems", o.data)
                })
            }
        },
        setInBasket({commit}, id){
            commit("setInBasket", id)
        }
    },

    modules: {},
});

export default store;
