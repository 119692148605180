<template>
  <div id="wrapper">
    <div class="content">
      <section class="parallax-section single-par custom-header" data-scrollax-parent="true">
        <div class="bg par-elem" data-bg="images/bg/29.jpg"
             data-scrollax="properties: { translateY: '30%' }"></div>
        <div class="overlay op7"></div>
        <div class="container">
          <div class="section-title">
            <h2>
              <i class="fa fa-file-archive"></i>
              <span v-if="language.short == 'mn'">{{ details.title_mn }}</span>
              <span v-else>{{ details.title_en }}</span>
            </h2>
            <div class="breadcrumbs fl-wrap"><a href="#">{{ 'home' | translate }}</a><a
                href="#">{{ 'product' | translate }}</a><span>{{ 'details' | translate }}</span>
            </div>
          </div>
        </div>
      </section>

      <section class="gray-bg small-padding page-product-detail" id="sec1">
        <div class="container">
          <div class="share-holder hid-share sing-page-share top_sing-page-share">
            <div class="share-container  isShare"></div>
          </div>
          <div class="mob-nav-content-btn  color2-bg show-list-wrap-search ntm fl-wrap"><i
              class="fal fa-filter"></i>
            Filters
          </div>

          <div class="fl-wrap">
            <div class="fl-wrap block_box product-header">
              <div class="product-header-Дэлгэрэнгүй">
                <!--                <span class="phd_sale green-bg">Хямдрал 30%</span>-->
                <div class="row p-dt-header">
                  <div class="col-md-6">
                    <h3 v-if="language.short == 'mn'">{{ details.title_mn }}</h3>
                    <h3 v-else>{{ details.title_en }}</h3>
                  </div>

                  <div class="col-md-6">
                    <div class="p-dt-action">

                      <div v-if="details.price_mnt != 0">
                        <span class="product-price" v-if="language.short == 'mn'">{{ details.price_mnt }}₮</span>
                        <span class="product-price" v-else>{{ details.price_en }}$</span>
                      </div>
                      <div v-else>
                        <span class="product-price">{{ 'free' | translate }}</span>
                      </div>
                      <a href="javascript:void(0)" :class="`basket-btn ${isInBasket ? 'active-basket' : ''}`"
                         @click="toggleBasketItem(details.id)">
                        <i class="el-icon-shopping-bag-2"></i>
                        <span v-if="isInBasket">{{ 'remove_cart' | translate }}</span>
                        <span v-else>{{ 'add_cart' | translate }}</span>
                      </a>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <p>{{ details.description }}</p>


                    <div class="p-ext-link" v-if="MonGeoCatConnection != null">
                      <a target="_blank" :href="MonGeoCatConnection.webGISURL">
                        {{ 'mongeocat_webgis_desc' | translate }} <i class="far fa-arrow-alt-circle-right"></i>
                      </a>

                      <a target="_blank"  :href="language.short == 'mn' ? MonGeoCatConnection.mnURL : MonGeoCatConnection.enURL">
                        {{ 'mongeocat_geonet_desc' | translate }} <i class="far fa-arrow-alt-circle-right"></i>
                      </a>
                    </div>
                    <br>
                    <ul class="no-list-style shop-list fl-wrap">
                      <li><span>{{ 'registration_number' | translate }}:</span>{{ details.report_number }}</li>
                      <li><span>{{ '_location' | translate }}:</span><ul class="item_list"><li :key="index" v-for="(sub, index) in details.ds_sub_state">{{ sub.aimagname }}, {{ sub.sumname }}</li></ul></li>
                      <li><span>{{ 'file_type' | translate }}: </span><ul class="item_list"><li :key="index" v-for="(format, index) in details.ds_sub_product_format"><div v-if="language.short == 'mn'">{{format.data_type_mn}},</div><div v-else>{{format.data_type_en}},</div></li></ul></li>
                      <li><span>{{ 'year' | translate }}: </span><ul class="item_list"><li :key="index" v-for="(year, index) in details.sub_product_year">{{year.product_year}}</li></ul></li>
<!--                      <li><span>{{ 'file_size' | translate }}: </span> mb</li>-->
                      <li><span>{{ 'tuluv' | translate }}: </span>
                        <span v-if="language.short == 'mn'">{{details.info_status_mn}}</span>
                        <span v-else>{{details.info_status_en}}</span>

                      </li>
                      <li><span>{{ '_type' | translate }}: </span> {{details.title_mn_pro}}</li>
<!--                      <li><span>{{ 'organization' | translate }}: </span> Citation Organization 2</li>-->
                    </ul>
                  </div>
                  <div class="col-md-6">
                    <!--                    <div v-if="hasMap" class="single-slider-wrap shop-media-img">-->
                    <!--                      <div class="single-slider fl-wrap">-->
                    <!--                        <div class="swiper-container">-->
                    <!--                          <div class="swiper-wrapper lightgallery">-->
                    <!--                            <div class="swiper-slide hov_zoom">-->
                    <!--                              <img src="images/shop/10.jpg" alt="">-->
                    <!--                              <a-->
                    <!--                                href="images/shop/10.jpg"-->
                    <!--                                class="box-media-zoom   popup-image"><i-->
                    <!--                                class="fal fa-search"></i></a></div>-->
                    <!--                            <div class="swiper-slide hov_zoom"><img src="images/shop/9.jpg"-->
                    <!--                                                                    alt=""><a-->
                    <!--                                href="images/shop/9.jpg"-->
                    <!--                                class="box-media-zoom   popup-image"><i-->
                    <!--                                class="fal fa-search"></i></a></div>-->
                    <!--                            <div class="swiper-slide hov_zoom"><img src="images/shop/11.jpg"-->
                    <!--                                                                    alt=""><a-->
                    <!--                                href="images/shop/11.jpg"-->
                    <!--                                class="box-media-zoom   popup-image"><i-->
                    <!--                                class="fal fa-search"></i></a></div>-->
                    <!--                          </div>-->
                    <!--                        </div>-->
                    <!--                      </div>-->
                    <!--                      <div class="listing-carousel_pagination">-->
                    <!--                        <div class="listing-carousel_pagination-wrap">-->
                    <!--                          <div class="ss-slider-pagination"></div>-->
                    <!--                        </div>-->
                    <!--                      </div>-->
                    <!--                      <div class="ss-slider-cont ss-slider-cont-prev color2-bg"><i-->
                    <!--                          class="fal fa-long-arrow-left"></i></div>-->
                    <!--                      <div class="ss-slider-cont ss-slider-cont-next color2-bg"><i-->
                    <!--                          class="fal fa-long-arrow-right"></i></div>-->
                    <!--                    </div>-->
                    <div class="mini-imgs">
                      <h2 class="sub-title">
                        <i class="el-icon-camera"></i>
                        <span>{{ 'additional_pictures' | translate }}</span>
                      </h2>
                      <div v-if="getExt(details.photo) == 'pdf'">
                        <a href="javscript:void(0)" class="pdf-view" @click="showPdf(details.photo)">
                          <img src="/images/pdf.png" alt="">
                        </a>
                      </div>
                      <viewer v-else>
                        <img :src="IMAGE_URL+details.photo" alt="" key="2">
                      </viewer>
                    </div>
                    <div class="map-main-wrap">
                      <h2 class="sub-title">
                        <i class="el-icon-map-location"></i>
                        <span>{{ '_maps' | translate }}</span>
                      </h2>
                      <div class="map-wrap">
                        <WebMap :report_number="details.report_number"/>
                      </div>
                    </div>

                    <div class="list-single-tags tags-stylwrap">
                      <span class="tags-title"><i class="fas fa-tag"></i> {{ 'labels' | translate }} : </span>
                      <a href="#">{{ 'geodata' | translate }}</a>
                      <a href="#">{{ 'web_serve' | translate }}</a>
                      <a href="#">{{ 'report' | translate }}</a>
                      <a href="#">{{ 'vector_and_raster_maps' | translate }}</a>
                      <a href="#">{{ 'thematic' | translate }}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

<!--            <div class="list-single-main-item fl-wrap block_box similar-products">-->
<!--              <h3>{{ 'related_products' | translate }}</h3>-->

<!--              <div class="listing-slider-wrap">-->
<!--                <div class="listing-slider">-->
<!--                  <div class="swiper-container">-->
<!--                    <div class="swiper-wrapper">-->

<!--                      <div class="swiper-slide" :key="index" v-for="(product, index) in products">-->
<!--                        <div class="listing-slider-item">-->
<!--                          <div class="listing-item listing_carditem">-->
<!--                            <article class="geodir-category-listing fl-wrap">-->
<!--                              <div class="geodir-category-img">-->
<!--                                <div class="geodir-js-favorite_btn">-->
<!--                                  <i class="fal fa-save"></i>-->
<!--                                  <span>{{ 'save' | translate }}</span>-->
<!--                                </div>-->
<!--                                <router-link :to="'/product-detail/' + product.id" class="geodir-category-img-wrap fl-wrap">-->
<!--                                  <img :src="IMAGE_URL+product.photo" alt=""/>-->
<!--                                </router-link>-->
<!--                                <div class="geodir_status_date gsd_open">-->
<!--                                  <i class="fal fa-lock-open"></i>{{ 'free_download' | translate }}-->
<!--                                </div>-->
<!--                                <div class="geodir-category-opt">-->
<!--                                  <div class="geodir-category-opt_title">-->
<!--                                    <h4>-->
<!--                                      <router-link :to="'/product-detail/' + product.id">-->
<!--                                        <span class="card__title" v-if="language.short == 'mn'">{{ product.title_mn }}</span>-->
<!--                                        <span class="card__title" v-else>{{ product.title_en }}</span>-->
<!--                                      </router-link>-->
<!--                                    </h4>-->
<!--                                    <div class="geodir-category-location">-->
<!--                                      <a href="#">-->
<!--                                        <i class="fas fa-map-marker-alt"></i>-->
<!--                                        <span style="margin-right: 5px" :key="index" v-for="(sub, index) in product.ds_sub_state">{{ sub.aimagname }}, {{ sub.sumname }}, </span>-->
<!--                                      </a>-->
<!--                                    </div>-->
<!--                                  </div>-->
<!--                                  <div class="listing_carditem_footer fl-wrap">-->
<!--                                    <a class="listing-item-category-wrap" href="#">-->
<!--                                      <div class="listing-item-category red-bg">-->
<!--                                        <i class="fas fa-search-location"></i>-->
<!--                                      </div>-->
<!--                                      <span>Региональ судалгаа</span>-->
<!--                                    </a>-->
<!--                                    <div class="post-author">-->
<!--                                      <a href="#">-->
<!--                                        <img src="images/logo/info-sys-logo.png"-->
<!--                                             alt="">-->
<!--                                        <span>Геологийн архив</span>-->
<!--                                      </a>-->
<!--                                    </div>-->
<!--                                  </div>-->
<!--                                </div>-->
<!--                              </div>-->
<!--                            </article>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->

<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="listing-carousel-button listing-carousel-button-next2"><i-->
<!--                      class="fas fa-caret-right"></i></div>-->
<!--                  <div class="listing-carousel-button listing-carousel-button-prev2"><i-->
<!--                      class="fas fa-caret-left"></i></div>-->
<!--                </div>-->
<!--                <div class="tc-pagination_wrap">-->
<!--                  <div class="tc-pagination2"></div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
          </div>
        </div>
      </section>

      <div class="limit-box fl-wrap"></div>

      <el-dialog
          :title="`${language.short == 'mn' ? details.title_mn : details.title_en}`"
          :visible.sync="viewPdf"
          width="60%"
          height="95%"
          top="2vh"
          class="pdf-viewer">
        <div class="dialog-body">
          <object :data="IMAGE_URL+currentUrl" type="application/pdf"  height="100%" width="100%">
            <iframe :src="IMAGE_URL+currentUrl" frameborder="0" height="100%" width="100%"/>
          </object>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import WebMap from "../map/WebMap";
import {GET_PRODUCT_LISTS, GET_PROPDUCT_BY_ID, IMAGE_URL} from "../graphql/queries";
import {getMonGeoCatConnection} from "../utils/mongeocat";
import {mapActions, mapGetters} from "vuex"

export default {
  name: "ProductDetail",
  props: ['product_Id'],
  components: {
    WebMap
  },
  data() {
    return {
      loading: true,
      hasMap: false,
      details: {},
      sub_state: [],
      sub_product_year: [],
      ds_sub_product_format: [],
      web_map_service_features_services: [],
      IMAGE_URL: IMAGE_URL,
      MonGeoCatConnection:null,
      viewPdf: false,
      currentUrl:null
    }
  },

  async created() {
    let id = this.$route.params.id.toString();
    await this.getProductDetails(id);
  },

  mounted() {
    window.initTowhub()
    window.initparallax();
  },
  computed: {
    ...mapGetters([
      'languages',
      'language',
      'basketItems',
      'isInBasket'
    ])
  },
  methods: {
    ...mapActions({
      setBasketItems: "setBasketItems",
      toggleBasketItem: "toggleBasketItem",
      setInBasket: "setInBasket",
    }),

    getProduct(){
      this.$apollo.query({ query: GET_PRODUCT_LISTS}).then(({data}) => {
        this.products = data.ds_product;
      })
    },

    getExt(fileName) {
      if(fileName){
        return fileName.split('.').pop();
      } else {
        return [];
      }

    },

    showPdf(url) {
      this.currentUrl = url;
      this.viewPdf = true;
    },

    async getProductDetails(id) {
      this.loading = true;
      this.$apollo.query({query: GET_PROPDUCT_BY_ID, variables: {product_id: id}}).then(async ({data}) => {
        this.details = data.ds_product[0];
        console.log(this.details)
        this.loading = false;
        this.setInBasket(id)
        this.MonGeoCatConnection = await getMonGeoCatConnection(this.details.report_number);

      })
    },
  },
}
</script>
