import axios from "axios";

export const getMonGeoCatConnection = async (report_no)=>{
    if(report_no){
        if(!window.init.connections){
            let response = await axios.get("https://webgis.mris.mn/mongeocat/connections.json");

            window.init.connections = response.data;
        }

        let connectionIndex = window.init.connections.findIndex(connection=>connection.report_no == report_no);

        if(connectionIndex >= 0){

            return {
                mnURL: getMetaURL("mon", window.init.connections[connectionIndex].meta_mon),
                enURL: getMetaURL("eng", window.init.connections[connectionIndex].meta_eng),
                webGISURL: `https://webgis.mris.mn/#/gis/${window.init.connections[connectionIndex].report_no}`
            };
        }
    }

    return null;
}

export const getMetaURL =(locale, uuid)=>{
    return `https://geonet.mris.mn/geonetwork/srv/${locale}/catalog.search#/metadata/${uuid}`
};