<template>
    <div id="auth" class="container-fluid">
        <div class="row g-0">
            <div class="col-12 col-xxl-4 col-xl-4 col-lg-5 col-md-6">
                <div class="auth-left">
                    <div class="top-menu">
                        <router-link to="/" class="top-bar-button home">
                            <i class="fas fa-home"></i>
                            <span>{{ 'home' | translate }}</span>
                        </router-link>
                        <LanguageSwitch/>
                    </div>

                    <router-link to="/" class="auth-header-wrap">
                        <img alt="NGS" src="assets/images/main-logo.png">
                    </router-link>
                    <router-view></router-view>
                </div>
            </div>
            <div class="col-8 col-xxl-8 col-xl-8 col-lg-7 col-md-6 gradient-bg">
                <div class="auth-right">
                    <!--ms-container-->
                    <div class="slideshow-container" data-scrollax="properties: { translateY: '300px' }" >
                        <div class="swiper-container">
                            <div class="swiper-wrapper">
                                <!--ms_item-->
                                <div class="swiper-slide">
                                    <div class="ms-item_fs fl-wrap full-height">
                                        <div class="bg" data-bg="images/bg/29.jpg"></div>
                                        <div class="overlay op6"></div>
                                    </div>
                                </div>
                                <!--ms_item end-->
                                <!--ms_item-->
                                <div class="swiper-slide ">
                                    <div class="ms-item_fs fl-wrap full-height">
                                        <div class="bg" data-bg="images/bg/13.jpg"></div>
                                        <div class="overlay op6"></div>
                                    </div>
                                </div>
                                <!--ms_item end-->
                                <!--ms_item-->
                                <div class="swiper-slide">
                                    <div class="ms-item_fs fl-wrap full-height">
                                        <div class="bg" data-bg="images/bg/35.jpg"></div>
                                        <div class="overlay op6"></div>
                                    </div>
                                </div>
                                <!--ms_item end-->
                            </div>
                        </div>
                    </div>
                    <!--ms-container end-->
                    <div class="login-promo-container">
                        <div class="container">
                            <div class="video_section-title fl-wrap">
                                <h4>Бидний тухай</h4>
                                <h2>Эрдэс баялгийн судалгааны ажил, үр дүн. <br> Геологийн судалгааны өнөөгийн түвшин</h2>
                            </div>
                            <a href="https://www.youtube.com/watch?v=dwtLFX7AQak" class="promo-link big_prom   image-popup"><i class="fal fa-play" style="text-align: center"></i><span>Танилцуулга видео</span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LanguageSwitch from "../../components/languageSwitch"

export default {
  name: 'AuthPage',
  components:{LanguageSwitch},
  mounted() {
    window.initTowhub()
    window.initparallax();
  }
}
</script>
