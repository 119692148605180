import Vue from 'vue'
import router from "./router"
import App from './App.vue'
import axios from 'axios';
import VueApollo from 'vue-apollo';
import "./scss/style.scss"
import vuexI18n from "vuex-i18n";
import ElementUI from 'element-ui';
import './scss/theme_mris/index.css';
import lang from 'element-ui/lib/locale/lang/mn'
import locale from 'element-ui/lib/locale'
import VuePageTransition from 'vue-page-transition'
import VueViewer from 'v-viewer'
import store from "./store";
import en from "./locales/en";
import mn from "./locales/mn";
import VueYouTubeEmbed from 'vue-youtube-embed';
import loading from 'vuejs-loading-screen'
import {apolloClient} from "./vue-apollo.js"
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/swiper.scss';
import 'swiper/swiper-bundle.css';
import Swiper, { Navigation, Pagination } from 'swiper';


import {getNumber} from "./utils/number";
import {getDate, getYear, getyear} from "./utils/date";

import VueSplide from '@splidejs/vue-splide';


Vue.use(vuexI18n.plugin, store);
locale.use(lang)
Vue.use(ElementUI);
Vue.i18n.add("en", en);
Vue.i18n.add("mn", mn);
Vue.use(VueApollo);
const savedLocale = localStorage.getItem('language');
if(savedLocale){
    Vue.i18n.set(savedLocale);
} else {
    Vue.i18n.set('mn');
}

Vue.config.productionTip = false
Vue.use(VuePageTransition)
Vue.use(VueViewer)
Vue.use(VueYouTubeEmbed);
Vue.use(VueAwesomeSwiper, /* { default global options } */);
Vue.use( VueSplide );
Swiper.use([Navigation, Pagination]);
Vue.use(loading, {
    bg: 'rgb(0, 0, 0, 0.4)',
    // bg: '#ffffff',
    icon: 'spinner',
    size: 3,
    icon_color: '#4d4de3',
});

Vue.prototype.$user = null;
Vue.prototype.$http = axios;

axios.defaults.baseURL = "https://mris.mn"

//axios.defaults.baseURL = 'http://localhost:1336';

Vue.filter("date", (value) => {
    return getDate(value);
});
Vue.filter("year", (value) => {
    return getYear(value);
});
Vue.filter("year", (value) => {
    return getyear(value);
});

Vue.filter("number", (value) => {
    return getNumber(value);
});

const apolloProvider = new VueApollo({
    defaultClient: apolloClient
});

new Vue({
    router,
    store,
    apolloProvider,
    render: h => h(App),
}).$mount('#app')