import gql from 'graphql-tag';
export const IMAGE_URL = "https://mris.mn/";

export const GET_PRODUCT_LISTS = gql`{
ds_product{
    author_id
    created_at
    deleted_at
    description
    id
    product_report_document_id
    price_en
    price_mnt
    product_file_type
    title_en
    title_mn
    info_status_mn
    photo
    report_number
    title_mn_pro
    ds_sub_product_format {
      format_id
      id
      product_id
      data_type_en
      data_type_mn
    }
    ds_sub_state {
      id
      region_id
      province_id
      aimagname
      sumname
    }
  }
}
`;
export const GET_PROPDUCT_BY_ID = gql`
query ds_product($product_id: String!){
  ds_product(
    filters: [{ column: "id", condition: equals, value: $product_id }]
  ){
    author_id
    created_at
    deleted_at
    description
    id
    product_report_document_id
    price_en
    price_mnt
    product_file_type
    title_en
    title_mn
    photo
    product_web_geo_url
    product_web_map_url
    report_number
    status_id
    info_status_mn
    info_status_en
    title_mn_pro
    mongeocat_code
    ds_sub_product_format {
      format_id
      id
      product_id
      data_type_en
      data_type_mn
    }
    ds_sub_state {
      id
      region_id
      province_id
      aimagname
      sumname
    }
    sub_product_year{
      id
      product_id
      product_year
    }
  }
}
`;

export const GET_PROPDUCT_VEKTOR_RASTER_MAP = gql`
query ds_product($product_vr_map_cat_id: String!, $product_file_type: String! ){
  ds_product(
    filters: [{ column: "product_vr_map_cat_id", condition: equals, value: $product_vr_map_cat_id },  { column: "product_file_type", condition: equals, value: $product_file_type },]
  ){
    author_id
    created_at
    deleted_at
    description
    id
    product_report_document_id
    price_en
    price_mnt
    product_file_type
    title_en
    title_mn
    photo
    product_web_geo_url
    product_web_map_url
    report_number
    status_id
    info_status_mn
    title_mn_pro
    mongeocat_code
    ds_sub_product_format {
      format_id
      id
      product_id
      data_type_en
      data_type_mn
    }
    ds_sub_state {
      id
      region_id
      province_id
      aimagname
      sumname
    }
    sub_product_year{
      id
      product_id
      product_year
    }
  }
}
`;

export const GET_PARTNER_ORGANIZATION = gql`{
  partner_organization {
    id
    link
    logo
    organization_name_en
    organization_name_mn
    updated_at
  }
}
`;

export const GET_STATIC_WORDS = gql`{
  static_words {
    id
    keyword_mn
    keyword_en
  }
}
`;

export const GET_CONTACT_ORGANIZATION = gql`{
  contact_organization {
    address_en
    address_mn
    created_at
    deleted_at
    facebook
    id
    linkedin
    logo
    mail
    organization_name_en
    organization_name_mn
    page
    phone_one
    phone_two
    twiiter
    updated_at
  }
}
`;

export const GET_ANSWER_QUESTION = gql`{
  fqa {
    answer_en
    answer_mn
    created_at
    deleted_at
    id
    photos
    question_category_id
    question_en
    question_mn
    updated_at
  }
}
`;

export const GET_ANSWER_QUESTION_BY_ID = gql`
query fqa($question_category_id: String!){
  fqa(
    filters: [{ column: "question_category_id", condition: equals, value: $question_category_id }]
  ){
    answer_en
    answer_mn
    created_at
    deleted_at
    id
    photos
    question_category_id
    question_en
    question_mn
    updated_at
  }
}
`;
export const GET_ANSWER_CAT = gql`{
  ds_fqa_lut_view {
    created_at
    deleted_at
    id
    question_type_en
    question_type_mn
    updated_at
  }
}
`;
export const GET_ANSWER_CAT_BY_ID = gql`
query ds_fqa_lut_view($cat_id: String!){
  ds_fqa_lut_view(
    filters: [{ column: "id", condition: equals, value: $cat_id }]
  ){
    created_at
    deleted_at
    id
    question_type_en
    question_type_mn
    updated_at
  }
}
`;
// <-- start terms of service -->

export const GET_TERM = gql`{
  ds_term(sorts: [{ column: "nohtsol_id", order: asc }],) {
    nohtsol_id
    term_eng
    term_nohtsol
    updated_at
  }
}
`;
export const GET_SUB_TERM = gql`{
  sub_term {
    created_at
    deleted_at
    id
    term_en
    term_id
    term_mn
    updated_at
    zaalt
    zaalt_eng
  }
}
`;
export const GET_SUB_TERM_BY_ID = gql`
query sub_term($nohtsol_id: String!){
  sub_term(
    filters: [{ column: "term_id", condition: equals, value: $nohtsol_id }]
  ){
    created_at
    deleted_at
    id
    term_en
    term_id
    term_mn
    updated_at
    zaalt
    zaalt_eng
  }
}
`;

// <-- end terms of service -->
export const GET_ARTICLE_CAT = gql`{
  lut_publishment_type {
    created_at
    deleted_at
    id
    type_en
    type_mn
    updated_at
  }
}
`;
export const GET_PULISHMEND_LUTS = gql`
{
  lut_publishment_type {
    created_at
    deleted_at
    id
    type_en
    type_mn
    updated_at
  }
  lut_publishment_language {
    language
  }
  ds_publishment_years {
    publishment_year
  }
  ds_publishment_author_grouped {
    author_mn
  }
}

`;

export const GET_ARTICLE = gql`{
  publishing(limit: 21, sorts:[{column:"created_at", order:desc}]) {
    book_pub
    city_name
    conference_name
    countery_id
    created_at
    date
    deleted_at
    dio_num
    eissn_number
    id
    issn
    keyword
    link
    magazine_name
    magazine_photo
    page_num
    photos
    project_name
    publish_category_id
    publish_name_en
    publish_name_mn
    publishing_en
    publishing_mn
    publishment_date
    share
    social_link
    updated_at
    volume_num
    lut_publishment_author {
      author_en
      author_mn
      city
      country_id
      id
      lastname_en
      lastname_mn
      orcid
      org_en
      org_mn
      others
      publishment_id
    }
  }
}
`;
export const GET_ARTICLE_LIMIT_BY_ID = gql`
query ds_publishment_view($cat_id: String!){
  ds_publishment_view(
    sorts: [{ column: "id", order: desc }], limit: 3
    filters: [{ column: "publish_category_id", condition: equals, value: $cat_id }]
  ){
    id
    publishing_en
    publishing_mn
    publish_name_mn
    publish_name_en
    type_mn
    project_name
    photo
    photos
    magazine_name
    magazine_photo
    book_pub
    publish_category_id
  }
}
`;
export const GET_ARTICLE_LIMIT = gql`{
  ds_publishment_view(sorts: [{ column: "id", order: desc }], limit: 5) {
    id
    publishing_en
    publishing_mn
    publish_name_mn
    publish_name_en
    type_mn
    project_name
    photo
    photos
    magazine_name
    magazine_photo
    author_mn
    book_pub
    publish_category_id
  }
}
`;

export const GET_ARTICLE_PAGE = gql`
query paginate($page:Int!, $size:Int!){
  paginate(sorts: [{ column: "project_name", order: desc }], page: $page, size: $size)
  {
    page
    total
    last_page
    publishing {
          book_pub
          city_name
          conference_name
          countery_id
          created_at
          date
          deleted_at
          dio_num
          eissn_number
          id
          issn
          keyword
          link
          magazine_name
          magazine_photo
          page_num
          photos
          project_name
          publish_category_id
          publish_name_en
          publish_name_mn
          publishing_en
          publishing_mn
          publishment_date
          share
          social_link
          updated_at
          volume_num
          lut_publishment_author {
            author_en
            author_mn
            city
            country_id
            id
            lastname_en
            lastname_mn
            orcid
            org_en
            org_mn
            others
            publishment_id
          }
       }
   }
}
`;

export const GET_ARTICLE_CATEGORY_PAGE = gql`
query paginate($page:Int!, $size:Int!, $publish_category_id: String!){
  paginate(
  sorts: [{ column: "project_name", order: desc }], page: $page, size: $size,
  filters: [{ column: "publish_category_id", condition: equals, value: $publish_category_id }]
  )
  {
    page
    total
    last_page
    publishing {
          book_pub
          city_name
          conference_name
          countery_id
          created_at
          date
          deleted_at
          dio_num
          eissn_number
          id
          issn
          keyword
          link
          magazine_name
          magazine_photo
          page_num
          photos
          project_name
          publish_category_id
          publish_name_en
          publish_name_mn
          publishing_en
          publishing_mn
          publishment_date
          share
          social_link
          updated_at
          volume_num
          lut_publishment_author {
            author_en
            author_mn
            city
            country_id
            id
            lastname_en
            lastname_mn
            orcid
            org_en
            org_mn
            others
            publishment_id
          }
       }
   }
}
`;

export const GET_ARTICLE_BY_ID = gql`
query ds_publishment_view($id: String!){
  ds_publishment_view(
    filters: [{ column: "id", condition: equals, value: $id }]
  ){
    
    book_pub
    city_name
    city_name_en
    conference_name
    countery_id
    countery
    countery_en
    created_at
    date
    deleted_at
    dio_num
    eissn_number
    id
    issn
    keyword

    link
    magazine_name
    magazine_name_en
    magazine_photo
 
    page_num
    photos
    project_name
    publish_category_id
    publish_name_en
    publish_name_mn
    publishing_en
    publishing_mn
    publishment_date
    share
    social_link
    type_mn
    updated_at
    volume_num
    publishment_number
    map_element_type
    published_language
    language_en
    published_organization
    contact_email
    contact_phone
  }
}
`;

export const GET_ARTICLE_BY_NUMBER = gql`
query ds_publishment_view($id: String!){
  ds_publishment_view(
    filters: [{ column: "publishment_number" condition: equals, value: $id }]
  ){
  
    book_pub
    city_name
    city_name_en
    conference_name
    countery_id
    countery
    countery_en
    created_at
    date
    deleted_at
    dio_num
    eissn_number
    id
    issn
    keyword

    link
    magazine_name
    magazine_name_en
    magazine_photo
 
    page_num
    photos
    project_name
    publish_category_id
    publish_name_en
    publish_name_mn
    publishing_en
    publishing_mn
    publishment_date
    share
    social_link
    type_mn
    updated_at
    volume_num
    publishment_number
    map_element_type
    published_language
    language_en
    published_organization
    contact_email
    contact_phone
  }
}
`;

export const GET_ARTICLE_LUT_PUBLISHMENT_AUTHER_ID = gql`
query lut_publishment_author($id: String!){
  lut_publishment_author(
    filters: [{ column: "publishment_id", condition: equals, value: $id }]
  ){
      author_en
      author_mn
      city
      country_id
      id
      lastname_en
      lastname_mn
      orcid
      org_en
      org_mn
      others
      publishment_id
  }
}
`;

export const POST_FEEDBACK = gql`
    mutation createFeedback($name: String!, $mail: String!, $phone: String!, $comment: String!){
        createFeedback(input:{name:$name,  mail:$mail, phone:$phone, comment:$comment, status_id:1, photo: "", answer: "", check: ""}){
            answer
            check
            name
            mail
            phone
            comment
            photo
            status_id
        }
    }
`;

export const GET_PRODUCT_COUNT = gql`{
  ds_product_count_by_type {
    product_file_type
    t_name
    t_name_eng
    too_count
  }
}
`;

export const GET_LUT_MAP_CATEGORY_all = gql`{
  lut_map_category {
    id
    map_category_en
    map_category_mn
  }
}
`;

export const GET_LUT_MAP_CATEGORY = gql`
query lut_map_category($cat_id: String!){
  lut_map_category(
    filters: [{ column: "id", condition: equals, value: $cat_id }]
  ){
    id
    map_category_en
    map_category_mn
  }
}
`;

export const GET_VIDEO_CAT = gql`{
  lut_helper_video_cat {
    id
    video_cat_en
    video_cat_mn
  }
}
`;
export const GET_OTHER_MN = gql`query ds_view_publishment_with_auters($author: String!, $publishmentID: String!){
  ds_view_publishment_with_auters(
   sorts: [{ column: "publishment_id", order: desc }],
   
  filters: [{ column: "mn_aouter", condition: contains, value: $author }, {column: "publishment_id", condition:notEqual, value:$publishmentID}]
  )
  {
    
   

         publishment_id
     publish_name_en
      publish_name_mn
      en_aouter
      mn_aouter
   
   }
}
`;
export const GET_OTHER_EN = gql`query ds_view_publishment_with_auters($author: String!, $publishmentID: String!){
  ds_view_publishment_with_auters(
   sorts: [{ column: "publishment_id", order: desc }],
   
  filters: [{ column: "mn_aouter", condition: contains, value: $author }, {column: "publishment_id", condition:notEqual, value:$publishmentID}]
  )
  {
    
   

         publishment_id
      publish_name_en
      publish_name_mn
      en_aouter
      mn_aouter
   
   }
}
`;

export const GET_VIDEO_BY_ID = gql`
query ds_video_cat($cat_id: String!){
  ds_video_cat(
    filters: [{ column: "helper_video_as_cat_id", condition: equals, value: $cat_id }]
  ){
    helper_video_as_cat_id
    helper_video_as_id
    helper_video_as_plus
    helper_video_as_title_en
    helper_video_as_title_mn
    helper_video_as_video
    lut_helper_video_cat_as_video_cat_mn
  }
}
`;

// ARCHIVE PRODUCT

export const GET_ARCHIVE_PRODUCT_LIST_PAGE = gql`
query paginate($page:Int!, $size:Int!, $type: String!){
  paginate(
  sorts: [{ column: "project_name", order: desc }], page: $page, size: $size,
  filters: [{ column: "publish_category_id", condition: equals, value: $type }]
  )
  {
   page
   total
   last_page
   ds_archive_information {
    aimagname
    archive_type_id
    author_id_geo
    countery_check
    created_at
    data_source_mn
    description
    id
    info_status_mn
    photo
    product_type
    product_type_id
    province_id
    release_date
    report_number
    soum_id
    source_id
    status_id
    sumname
    title_en
    title_mn
    }
  }
}
`;



