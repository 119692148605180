<template>
  <div class="auth-form box-shadow">
    <div class="auth-form-layer">
      <div class="auth-form-layer-title-register">
        <i class="fas fa-building"></i>
        <div class="register-user-title">
          <span>{{ 'register_text' | translate }}</span>
          <span class="sub-title">{{ 'reg_organization' | translate }}</span>
        </div>
      </div>

      <el-form ref="registerFormRef" class="auth-form-layer-login register-citizen" :rules="rules"
               @keypress.enter.native="submitRegister()"
               label-position="top" size="small" :model="credentials">
        <el-form-item :label="'reg_purpose' | translate" prop="purpose">
          <el-checkbox-group v-model="credentials.purpose">
            <el-checkbox :label="'reg_purpose_1' | translate"></el-checkbox>
            <el-checkbox :label="'reg_purpose_2' | translate"></el-checkbox>
            <el-checkbox :label="'reg_purpose_3' | translate"></el-checkbox>
            <el-checkbox :label="'reg_purpose_4' | translate"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item :label="'reg_purpose_other' | translate" prop="other_purpose">
          <el-input :placeholder="'reg_purpose_other_placeholder' | translate"
                    type="textarea"
                    maxlength="1500"
                    show-word-limit
                    :rows="3"
                    v-model="credentials.other_purpose"></el-input>
        </el-form-item>
        <el-form-item :label="'reg_org_type' | translate" prop="org_type">
          <el-select v-model="credentials.org_type" :placeholder="'reg_org_type_placeholder' | translate"
                     style="width: 100%" @change="getSubTypes($event)">
            <el-option v-for="item in org_types"
                       :key="item.id"
                       :label="item.lut_res_org_type"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="'reg_org_sub_type' | translate">
          <el-select v-model="credentials.org_subType" :placeholder="'reg_org_sub_type_placeholder' | translate"
                     style="width: 100%" :disabled="disabled" @change="getOrgs($event)">
            <el-option v-for="item in org_subTypes"
                       :key="item.id"
                       :label="`${language.short == 'mn' ? item.sub_res_org_mn : item.sub_res_org_en}`"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item :label="'reg_organization_name' | translate" prop="org_name">
          <!--          <el-input prefix-icon="el-icon-office-building"-->
          <!--                    :placeholder="'reg_organization_name_placeholder' | translate"-->
          <!--                    type="text"-->
          <!--                    v-model="credentials.org_name"></el-input>-->

          <el-select v-model="credentials.org_name"
                     :placeholder="'reg_organization_name_placeholder' | translate"
                     :disabled="disabledOrg"
                     style="width: 100%"
                     clearable>
            <el-option v-for="item in orgs"
                       :key="item.id"
                       :label="`${language.short == 'mn' ? item.org_title : item.org_title_en}`"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <div v-if="!disabledOrg">
            <div v-if="credentials.org_name == null || credentials.org_name == ''" class="auth-msg warning" style="max-width: 100%; margin-bottom: 10px">
                <i class="el-icon-warning"></i>
                <p>{{'reg_org_name_warning' | translate}}</p>
            </div>
        </div>

        <el-form-item :label="'reg_position' | translate" prop="org_position">
          <el-input prefix-icon="el-icon-suitcase"
                    :placeholder="'reg_position_placeholder' | translate"
                    type="text"
                    v-model="credentials.org_position"></el-input>
        </el-form-item>
        <el-form-item :label="'reg_surname' | translate" prop="last_name">
          <el-input prefix-icon="el-icon-user"
                    :placeholder="'reg_surname_placeholder' | translate"
                    type="text"
                    v-model="credentials.last_name"></el-input>
        </el-form-item>
        <el-form-item :label="'reg_first_name' | translate" prop="first_name">
          <el-input prefix-icon="el-icon-s-custom"
                    :placeholder="'reg_first_name_placeholder' | translate"
                    type="text"
                    v-model="credentials.first_name"></el-input>
        </el-form-item>
        <el-form-item :label="'reg_phone' | translate" prop="phone">
          <el-input prefix-icon="el-icon-phone"
                    :placeholder="'reg_phone_placeholder' | translate"
                    v-model="credentials.phone"></el-input>
        </el-form-item>
        <el-form-item :label="'mail' | translate" prop="login">
          <el-input prefix-icon="el-icon-message"
                    :placeholder="'reg_email_placeholder' | translate"
                    type="email" autocomplete="off"
                    v-model="credentials.login"></el-input>
        </el-form-item>
        <el-form-item :label="'reg_password' | translate" prop="passwd">
          <el-input type="password"
                    prefix-icon="el-icon-key"
                    :placeholder="'reg_password_placeholder' | translate"
                    :show-password=true
                    autocomplete="off"
                    v-model="credentials.passwd"></el-input>
        </el-form-item>
        <el-form-item :label="'reg_password_repeat' | translate" prop="passwdCheck">
          <el-input type="password"
                    prefix-icon="el-icon-key"
                    :placeholder="'reg_password_repeat_placeholder' | translate"
                    :show-password=true
                    autocomplete="off"
                    v-model="credentials.passwdCheck"></el-input>
        </el-form-item>
        <el-form-item :label="'reg_official_letter' | translate" prop="org_document">
          <el-upload
              style="width: 100%;"
              v-model="credentials.org_document"
              :action="`${$http.defaults.baseURL}/api/profile/avatar`"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload">
            <el-button size="small" type="primary">
              <i class="fas fa-images"></i>
              {{ 'reg_official_letter_btn' | translate }}
            </el-button>
            <div slot="tip" class="el-upload__tip">{{ 'reg_official_letter_desc' | translate }}
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item :label="'reg_term_label' | translate" prop="termChecked">
          <el-checkbox v-model="credentials.termChecked" :label="'reg_term_agree_org' | translate"></el-checkbox>
          <el-button type="text" @click="showTerm = true" class="show-term-btn"> ({{
              'reg_term' | translate
            }})
          </el-button>
        </el-form-item>
        <div class="form-button-container">
          <el-button :disabled="!credentials.termChecked" type="primary" @click="submitRegister()" size="medium"
                     style="width: 100%">
            <template v-if="loading">
              <i class="el-icon-loading"></i>{{ '_sending' | translate }}
            </template>
            <template v-else>
              <i class="fas fa-building"></i>{{ 'register_btn_text' | translate }}
            </template>
          </el-button>
        </div>
      </el-form>
      <div class="auth-form-layer-bottom">
        <router-link to="/auth/register" class="btn-register">
          <i class="el-icon-back"></i>
          <span>{{ 'reg_back' | translate }}</span>
        </router-link>
        <router-link to="/auth/" class="btn-register">
          <i class="fal fa-sign-in-alt"></i>
          <span>{{ 'login_text' | translate }}</span>
        </router-link>
      </div>
      <div class="auth-msg success" v-if="isSuccess">
        <i class="el-icon-success"></i>
        <p>{{ 'reg_success' | translate }}</p>
      </div>
      <div class="auth-msg danger" v-if="isError">
        <i class="el-icon-error"></i>
        <p>{{ 'reg_error' | translate }}</p>
      </div>
      <div class="auth-msg warning" v-if="loading">
        <i class="el-icon-loading"></i>
        <p>{{ 'reg_loading' | translate }}</p>
      </div>
    </div>
    <el-dialog
        :title="'reg_term' | translate"
        :visible.sync="showTerm"
        width="30%"
        :before-close="termClose">
      <span>{{ 'reg_term' | translate }}</span>
      <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="showTerm = false">Ok</el-button>
            </span>
    </el-dialog>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Register",
  data() {
    const validateSpace = (rule, value, callback) => {
      let whiteSpace = /\s/g;
      if (whiteSpace.test(value)) {
        callback(new Error("Хоосон зай орсон байна"));
      } else {
        callback();
      }
    };
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please input the password'));
      } else {
        if (this.credentials.passwdCheck !== '') {
          this.$refs.registerFormRef.validateField('passwdCheck');
        }
        callback();
      }
    };

    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please input the password again'));
      } else if (value !== this.credentials.passwd) {
        callback(new Error('Two inputs don\'t match!'));
      } else {
        callback();
      }
    };

    const validateCheckbox = (rule, value, callback) => {
      if (value === false) {
        callback(new Error("Үйлчилгээний нөхцөл"));
      } else {
        callback();
      }
    };
    return {
      isValid: true,
      rePassError: false,
      showTerm: false,
        disabledSubmit: true,
      loading: false,
      isSuccess: false,
      isError: false,
      errorMsg: null,
      disabled: true,
      disabledOrg: true,
      orgs: [],
      org_types: [],
      org_subTypes: [],
      credentials: {
        is_org: 1,
        purpose: [],
        other_purpose: null,
        org_name: null,
        org_type: null,
        org_subType: null,
        org_position: null,
        org_document: null,
        first_name: null,
        last_name: null,
        login: null,
        phone: null,
        passwd: null,
        passwdCheck: null,
        termChecked: false,
      },
      rules: {
        org_name: [
          {required: true, message: 'Байгууллагын нэр оруулна уу!', trigger: 'blur'}
        ],
        org_position: [
          {required: true, message: 'Албан тушаал оруулна уу!', trigger: 'blur'}
        ],
        org_type: [
          {required: true, message: 'Байгууллагын төрлөө сонгоно уу!', trigger: 'blur'}
        ],
        last_name: [
          {required: true, message: 'Эцэг/эхийн нэр оруулна уу!', trigger: 'blur'}
        ],
        first_name: [
          {required: true, message: 'Өөрийн нэрээ оруулна уу!', trigger: 'blur'}
        ],
        phone: [
          {required: true, message: 'Утсаа оруулна уу!', trigger: 'blur'}
        ],
        login: [
          {type: 'email', required: true, message: 'Бүртгэлтэй имэйл хаягаа оруулна уу!', trigger: ["blur", "change"]},
          {validator: validateSpace, trigger: "blur"},
        ],
        passwd: [
          {required: true, message: 'Нууц үгээ оруулна үү!', trigger: 'blur'},
          {validator: validatePass, trigger: 'blur'}
        ],
        passwdCheck: [
          {required: true, message: 'Нууц үгээ дахин оруулна үү!', trigger: 'blur'},
          {validator: validatePass2, trigger: 'blur'}
        ],
        org_document: [
          {required: true, message: 'Албан тоот оруулна уу!', trigger: 'blur'}
        ],
        termChecked: [
          {validator: validateCheckbox, trigger: 'change'}
        ],
      }
    }
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters([
      'languages',
      'language',
      // ...
    ])
  },
  mounted() {
    this.getOrgTypes();
  },
  methods: {
    handleAvatarSuccess(res) {
      this.credentials.org_document = res;
    },

    beforeAvatarUpload(file) {
      const isImg = file.type === 'image/jpeg' || 'image/png';
      const isLt2M = file.size < 512 * 512;

      if (!isImg) {
        this.$message.error('Зураг оруулна уу!');
      }
      if (!isLt2M) {
        this.$message.error('Файл 500KB хэмжээнээс бага байх хэрэгтэй!');
      }
      return isImg && isLt2M;
    },

    getOrgs(st) {
      this.$http.get(`/api/org/list/${st}`).then(({data}) => {
        this.disabledOrg = false;
        this.orgs = data;
      })
    },

    getOrgTypes() {
      this.$http.get('/api/org/type').then(({data}) => {
        this.org_types = data;
      })
    },

    getSubTypes($event) {
      this.credentials.org_subType = null;
      if ($event) {
        this.$http.get("/api/org/subtype/" + $event).then(response => {
          this.org_subTypes = response.data;
          this.disabled = false;
        })
      } else {
        this.disabled = true;
      }
    },

    submitRegister() {
      this.$refs['registerFormRef'].validate((valid) => {
        if (valid) {
          this.isValid = valid;
          if (this.credentials.termChecked) {
            this.loading = true;
            this.isSuccess = false;
            this.isError = false;
            this.rePassError = false;
            this.credentials.purpose = JSON.stringify(this.credentials.purpose).replace('[', '').replace(']', '').replace(/"/g, '');
            this.$http.post('/api/registerUser', this.credentials).then(({data}) => {
              console.log("LOGIN FORM:");
              console.log(data);
              if (data.status) {
                this.loading = false;
                this.isError = false;
                this.isSuccess = true;
                this.msg = data.msg;
                this.$store.commit('setUser', data.data);
                this.$store.commit('setUserToken', data.token);
                setTimeout(() => {
                  this.$router.push("/")
                  // window.location.reload();
                }, 1000);

              } else {
                // this.$store.commit('setUser', null);
                // this.$store.commit('setUserToken', null);
                this.loading = false;
                this.isError = true;
                this.msg = "Нэвтрэх мэдээлэл буруу байна";
                // }, 1000);
              }

              // eslint-disable-next-line no-unused-vars
            }).catch(e => {
              setTimeout(() => {
                // this.$store.commit('setUser', null);
                // this.$store.commit('setUserToken', null);
                this.loading = false;
                this.isError = true;
                this.isSuccess = false;
                this.msg = "Нэвтрэх мэдээлэл буруу байна";
              }, 1000);
            })
          } else {
            this.termChecked = true;
          }
        } else {
          this.isValid = false;
        }
      })
    },

    termClose() {
      this.showTerm = false
      // this.$confirm('Are you sure to close this dialog?')
      //     .then(_ => {
      //         done();
      //     })
      //     .catch(_ => {});
    }
  }
}
</script>