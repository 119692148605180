<template>
    <div class="auth-form box-shadow">
        <div class="auth-form-layer">
            <div class="auth-form-layer-title">
                <i class="fal fa-sign-in-alt"></i>
                <span>{{ 'login_text' | translate }}</span>
            </div>
            <el-form class="auth-form-layer-login" ref="loginFormRef"  label-position="top" :model="credentials" :rules="rules" @keypress.enter.native="submitLogin()">
                <el-form-item :label="'mail' | translate" prop="login">
                    <el-input v-model="credentials.login"
                              prefix-icon="el-icon-message"
                              type="email"
                              :placeholder="'login_email_placeholder' | translate"
                              autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item :label="'_password' | translate" prop="password">
                    <el-input v-model="credentials.password"
                              prefix-icon="el-icon-key"
                              type="password"
                              :placeholder="'login_password_placeholder' | translate"
                              :show-password=true></el-input>
                </el-form-item>
                <div class="form-button-container">
                    <el-button :disabled="loading" type="primary"  @click="submitLogin()" size="medium" style="width: 100%">
                        <template v-if="loading">
                            <i class="el-icon-loading"></i>{{ '_sending' | translate }}
                        </template>
                        <template v-else>
                            <i class="fal fa-sign-in-alt"></i>{{ 'login_btn_text' | translate }}
                        </template>
                    </el-button>
                </div>
            </el-form>
            <div class="auth-form-layer-bottom">
                <router-link to="/auth/register" class="btn-register">
                    <i class="fas fa-user-plus"></i>
                    <span>{{ 'register_text' | translate }}</span>
                </router-link>
                <router-link to="/auth/forget-password" class="lost_password">
                    <i class="fas fa-unlock-alt"></i>
                    <span class="show-lpt">{{ 'forgot_password' | translate }}</span>
                </router-link>
            </div>
            <div class="auth-msg success" v-if="isSuccess">
                <i class="el-icon-success"></i>
                <p>{{ 'login_success' | translate }}</p>
            </div>
            <div class="auth-msg danger" v-if="isError" >
                <i class="el-icon-warning"></i>
                <p>{{ 'login_error' | translate }}</p>
            </div>
        </div>
    </div>
</template>
<script>


export default {
    name: "Login",
    data() {
        const validateSpace = (rule, value, callback) => {
            let whiteSpace = /\s/g;
            if (whiteSpace.test(value)) {
                callback(new Error("Хоосон зай орсон байна"));
            } else {
                callback();
            }
        };
        return {
            apiBaseUrl: window.init.apiBaseUrl,
            loading: false,
            isSuccess: false,
            isError: false,
            msg: null,
            credentials: {
                login: null,
                password: null
            },
            forgot_credentials: {
                login: null
            },
            rules: {
                login: [
                    { type: 'email', required: true, message: 'Бүртгэлтэй имэйл хаягаа оруулна уу!', trigger: ["blur", "change"] },
                    { validator: validateSpace, trigger: "blur"},
                ],
                password: [
                    { required: true, message: 'Нууц үгээ бичнэ үү!', trigger: 'blur' }
                ]
            }
        }
    },
    methods: {
        submitLogin() {
            this.$refs["loginFormRef"].validate((valid) => {
                if (valid) {
                    this.loading = true;
                    this.isSuccess = false;
                    this.isError = false;
                    this.$http.post('/auth/login', this.credentials).then(({data}) => {
                        if (data.status) {
                            this.loading = false;
                            this.isError = false;
                            this.isSuccess = true;
                            this.msg = data.msg;
                            console.log(data.msg);
                            this.$store.commit('setUser', data.data);
                            this.$store.commit('setUserToken', data.token);
                            setTimeout(() => {
                                this.$router.push("/")
                            }, 1000);

                        } else {
                            // this.$store.commit('setUser', null);
                            // this.$store.commit('setUserToken', null);
                            this.loading = false;
                            this.isError = true;
                            this.msg = "'_sending' | translate";
                            // }, 1000);
                        }

                        // eslint-disable-next-line no-unused-vars
                    }).catch(e => {
                        setTimeout(() => {
                            // this.$store.commit('setUser', null);
                            // this.$store.commit('setUserToken', null);
                            this.loading = false;
                            this.isError = true;
                            this.isSuccess = false;
                            this.msg = "'_sending' | translate";
                        }, 1000);
                    })
                }
            })
        },
        submitForgotPassword(formRef) {
            this.$refs[formRef].validate((valid) => {
                if (valid) {
                    this.isSuccess = false;
                    this.isError = false;
                    this.$http.post('/customer/recovery', this.forgot_credentials).then(({data}) => {
                        console.log(data);
                        if (data.status) {
                            this.loading = false;
                            this.isError = false;
                            this.isSuccess = true;
                            this.msg = data.msg;

                            setTimeout(() => {
                                // this.$router.push({ name: data.path })
                                this.formType = 'login';
                                this.isSuccess = false;
                                this.isError = false;
                            }, 1000);

                        } else {
                            // setTimeout(() => {
                            console.log(data.msg);
                            this.loading = false;
                            this.isError = true;
                            this.msg = data.msg;
                            // }, 1000);
                        }

                        // eslint-disable-next-line no-unused-vars
                    }).catch(e => {
                        setTimeout(() => {
                            this.loading = false;
                            this.isError = true;
                            this.isSuccess = false;
                        }, 1000);
                    })
                }
            })
        },
    }
}
</script>
