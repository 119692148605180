<template>
  <div id="wrapper">
    <!-- content-->
    <div class="content">
      <!--  section  -->
      <section class="parallax-section dashboard-header-sec gradient-bg" data-scrollax-parent="true">
        <div class="container">
          <div class="dashboard-breadcrumbs breadcrumbs"><a href="#">Home</a><a
              href="#">Dashboard</a><span>Main page</span></div>
          <!--Tariff Plan menu-->
          <div class="tfp-btn"><span>Tariff Plan : </span> <strong>Extended</strong></div>
          <div class="tfp-det">
            <p>You Are on <a href="#">Extended</a> . Use link bellow to view details or upgrade. </p>
            <a href="#" class="tfp-det-btn color2-bg">Details</a>
          </div>
          <!--Tariff Plan menu end-->
          <div class="dashboard-header_conatiner fl-wrap dashboard-header_title">
            <h1>Welcome : <span>Alisa Noory</span></h1>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="dashboard-header fl-wrap">
          <div class="container">
            <div class="dashboard-header_conatiner fl-wrap">
              <div class="dashboard-header-avatar">
                <img src="images/avatar/4.jpg" alt="">
                <a href="dashboard-myprofile.html" class="color-bg edit-prof_btn"><i class="fal fa-edit"></i></a>
              </div>
              <div class="dashboard-header-stats-wrap">
                <div class="dashboard-header-stats">
                  <div class="swiper-container">
                    <div class="swiper-wrapper">
                      <!--  dashboard-header-stats-item -->
                      <div class="swiper-slide">
                        <div class="dashboard-header-stats-item">
                          <i class="fal fa-map-marked"></i>
                          Active Listings
                          <span>21</span>
                        </div>
                      </div>
                      <!--  dashboard-header-stats-item end -->
                      <!--  dashboard-header-stats-item -->
                      <div class="swiper-slide">
                        <div class="dashboard-header-stats-item">
                          <i class="fal fa-chart-bar"></i>
                          Listing Views
                          <span>1054</span>
                        </div>
                      </div>
                      <!--  dashboard-header-stats-item end -->
                      <!--  dashboard-header-stats-item -->
                      <div class="swiper-slide">
                        <div class="dashboard-header-stats-item">
                          <i class="fal fa-comments-alt"></i>
                          Total Reviews
                          <span>79</span>
                        </div>
                      </div>
                      <!--  dashboard-header-stats-item end -->
                      <!--  dashboard-header-stats-item -->
                      <div class="swiper-slide">
                        <div class="dashboard-header-stats-item">
                          <i class="fal fa-save"></i>
                          Times Bookmarked
                          <span>654</span>
                        </div>
                      </div>
                      <!--  dashboard-header-stats-item end -->
                    </div>
                  </div>
                </div>
                <!--  dashboard-header-stats  end -->
                <div class="dhs-controls">
                  <div class="dhs dhs-prev"><i class="fal fa-angle-left"></i></div>
                  <div class="dhs dhs-next"><i class="fal fa-angle-right"></i></div>
                </div>
              </div>
              <!--  dashboard-header-stats-wrap end -->
              <a class="add_new-dashboard">Add Listing <i class="fal fa-layer-plus"></i></a>
            </div>
          </div>
        </div>
        <div class="gradient-bg-figure" style="right:-30px;top:10px;"></div>
        <div class="gradient-bg-figure" style="left:-20px;bottom:30px;"></div>
        <div class="circle-wrap" style="left:120px;bottom:120px;" data-scrollax="properties: { translateY: '-200px' }">
          <div class="circle_bg-bal circle_bg-bal_small"></div>
        </div>
        <div class="circle-wrap" style="right:420px;bottom:-70px;" data-scrollax="properties: { translateY: '150px' }">
          <div class="circle_bg-bal circle_bg-bal_big"></div>
        </div>
        <div class="circle-wrap" style="left:420px;top:-70px;" data-scrollax="properties: { translateY: '100px' }">
          <div class="circle_bg-bal circle_bg-bal_big"></div>
        </div>
        <div class="circle-wrap" style="left:40%;bottom:-70px;">
          <div class="circle_bg-bal circle_bg-bal_middle"></div>
        </div>
        <div class="circle-wrap" style="right:40%;top:-10px;">
          <div class="circle_bg-bal circle_bg-bal_versmall" data-scrollax="properties: { translateY: '-350px' }"></div>
        </div>
        <div class="circle-wrap" style="right:55%;top:90px;">
          <div class="circle_bg-bal circle_bg-bal_versmall" data-scrollax="properties: { translateY: '-350px' }"></div>
        </div>
      </section>
      <!--  section  end-->
      <!--  section  -->
      <section class="gray-bg main-dashboard-sec" id="sec1">
        <div class="container">
          <!--  dashboard-menu-->
          <div class="col-md-3">
            <div class="mob-nav-content-btn color2-bg init-dsmen fl-wrap"><i class="fal fa-bars"></i> Dashboard menu
            </div>
            <div class="clearfix"></div>
            <div class="fixed-bar fl-wrap" id="dash_menu">
              <div class="user-profile-menu-wrap fl-wrap block_box">
                <!-- user-profile-menu-->
                <div class="user-profile-menu">
                  <h3>Main</h3>
                  <ul class="no-list-style">
                    <li><a href="dashboard.html"><i class="fal fa-chart-line"></i>Dashboard</a></li>
                    <li><a href="dashboard-feed.html"><i class="fal fa-rss"></i>Your Feed <span>7</span></a></li>
                    <li><a href="dashboard-myprofile.html"><i class="fal fa-user-edit"></i> Edit profile</a></li>
                    <li><a href="dashboard-messages.html" class="user-profile-act"><i class="fal fa-envelope"></i>
                      Messages <span>3</span></a></li>
                    <li><a href="dashboard-password.html"><i class="fal fa-key"></i>Change Password</a></li>
                    <li>
                      <a href="#" class="submenu-link"><i class="fal fa-plus"></i>Submenu</a>
                      <ul class="no-list-style">
                        <li><a href="#"><i class="fal fa-th-list"></i> Submenu 2 </a></li>
                        <li><a href="#"> <i class="fal fa-calendar-check"></i> Submenu 2</a></li>
                        <li><a href="#"><i class="fal fa-comments-alt"></i>Submenu 2</a></li>
                        <li><a href="#"><i class="fal fa-file-plus"></i> Submenu 2</a></li>
                      </ul>

                    </li>
                  </ul>
                </div>
                <!-- user-profile-menu end-->
                <!-- user-profile-menu-->
                <div class="user-profile-menu">
                  <h3>Listings</h3>
                  <ul class="no-list-style">
                    <li><a href="dashboard-listing-table.html"><i class="fal fa-th-list"></i> My listigs </a></li>
                    <li><a href="dashboard-bookings.html"> <i class="fal fa-calendar-check"></i> Bookings <span>2</span></a>
                    </li>
                    <li><a href="dashboard-review.html"><i class="fal fa-comments-alt"></i> Reviews </a></li>
                    <li><a href="dashboard-add-listing.html"><i class="fal fa-file-plus"></i> Add New</a></li>
                    <li>
                      <a href="#" class="submenu-link"><i class="fal fa-plus"></i>Submenu</a>
                      <ul class="no-list-style">
                        <li><a href="#"><i class="fal fa-th-list"></i> Submenu 2 </a></li>
                        <li><a href="#"> <i class="fal fa-calendar-check"></i> Submenu 2</a></li>
                        <li><a href="#"><i class="fal fa-comments-alt"></i>Submenu 2</a></li>
                        <li><a href="#"><i class="fal fa-file-plus"></i> Submenu 2</a></li>
                      </ul>

                    </li>
                  </ul>
                </div>
                <!-- user-profile-menu end-->
                <button class="logout_btn color2-bg">Log Out <i class="fas fa-sign-out"></i></button>
              </div>
            </div>
            <a class="back-tofilters color2-bg custom-scroll-link fl-wrap" href="#dash_menu">Back to Menu<i
                class="fas fa-caret-up"></i></a>
            <div class="clearfix"></div>
          </div>
          <!-- dashboard-menu  end-->
          <!-- dashboard content-->
          <div class="col-md-9">
            <!-- dashboard-list-box-->
            <div class="dashboard-list-box fl-wrap">
              <div class="dashboard-header color-bg fl-wrap">
                <h3>Indox</h3>
              </div>
              <div class="chat-wrapper fl-wrap">
                <div class="row">
                  <!-- chat-box-->
                  <div class="col-sm-8">
                    <div class="chat-box fl-wrap">
                      <!-- message-->
                      <div class="chat-message chat-message_guest fl-wrap">
                        <div class="dashboard-message-avatar">
                          <img src="images/avatar/1.jpg" alt="">
                          <span class="chat-message-user-name cmun_sm">Andy</span>
                        </div>
                        <span class="massage-date">25 may 2018  <span>7.51 PM</span></span>
                        <p>Vivamus lobortis vel nibh nec maximus. Donec dolor erat, rutrum ut feugiat sed, ornare vitae
                          nunc. Donec massa nisl, bibendum id ultrices sed, accumsan sed dolor.</p>
                      </div>
                      <!-- message end-->
                      <!-- message-->
                      <div class="chat-message chat-message_user fl-wrap">
                        <div class="dashboard-message-avatar">
                          <img src="images/avatar/4.jpg" alt="">
                          <span class="chat-message-user-name cmun_sm">Jessie</span>
                        </div>
                        <span class="massage-date">25 may 2018  <span>7.51 PM</span></span>
                        <p>Nulla eget erat consequat quam feugiat dapibus eget sed mauris.</p>
                      </div>
                      <!-- message end-->
                      <!-- message-->
                      <div class="chat-message chat-message_guest fl-wrap">
                        <div class="dashboard-message-avatar">
                          <img src="images/avatar/1.jpg" alt="">
                          <span class="chat-message-user-name cmun_sm">Andy</span>
                        </div>
                        <span class="massage-date">25 may 2018  <span>7.51 PM</span></span>
                        <p>Sed non neque faucibus, condimentum lectus at, accumsan enim. Fusce pretium egestas
                          cursus..</p>
                      </div>
                      <!-- message end-->
                      <!-- message-->
                      <div class="chat-message chat-message_user fl-wrap">
                        <div class="dashboard-message-avatar">
                          <img src="images/avatar/4.jpg" alt="">
                          <span class="chat-message-user-name cmun_sm">Jessie</span>
                        </div>
                        <span class="massage-date">25 may 2018  <span>7.51 PM</span></span>
                        <p>Donec a consectetur nulla. Nulla posuere sapien vitae lectus suscipit, et pulvinar nisi
                          tincidunt. Aliquam erat volutpat. Curabitur convallis fringilla diam sed aliquam. Sed tempor
                          iaculis massa faucibus feugiat. In fermentum facilisis massa, a consequat .</p>
                      </div>
                      <!-- message end-->
                    </div>
                    <div class="chat-widget_input fl-wrap">
                      <textarea placeholder="Type Message"></textarea>
                      <button type="submit"><i class="fal fa-paper-plane"></i></button>
                    </div>
                  </div>
                  <!-- chat-box end-->
                  <!-- chat-contacts-->
                  <div class="col-sm-4">
                    <div class="chat-contacts fl-wrap">
                      <!-- chat-contacts-item-->
                      <a class="chat-contacts-item" href="#">
                        <div class="dashboard-message-avatar">
                          <img src="images/avatar/2.jpg" alt="">
                          <div class="message-counter">2</div>
                        </div>
                        <div class="chat-contacts-item-text">
                          <h4>Mark Rose</h4>
                          <span>27 Dec 2018 </span>
                          <p>Vivamus lobortis vel nibh nec maximus. Donec dolor erat, rutrum ut feugiat sed, ornare
                            vitae nunc. Donec massa nisl, bibendum id ultrices sed, accumsan sed dolor.</p>
                        </div>
                      </a>
                      <!-- chat-contacts-item -->
                      <!-- chat-contacts-item-->
                      <a class="chat-contacts-item" href="#">
                        <div class="dashboard-message-avatar">
                          <img src="images/avatar/avatar-bg.png.jpeg" alt="">
                          <div class="message-counter">1</div>
                        </div>
                        <div class="chat-contacts-item-text">
                          <h4>Adam Koncy</h4>
                          <span>27 Dec 2018 </span>
                          <p>Vivamus lobortis vel nibh nec maximus. Donec dolor erat, rutrum ut feugiat sed, ornare
                            vitae nunc. Donec massa nisl, bibendum id ultrices sed, accumsan sed dolor.</p>
                        </div>
                      </a>
                      <!-- chat-contacts-item -->
                      <!-- chat-contacts-item-->
                      <a class="chat-contacts-item chat-contacts-item_active" href="#">
                        <div class="dashboard-message-avatar">
                          <img src="images/avatar/1.jpg" alt="">
                          <div class="message-counter">3</div>
                        </div>
                        <div class="chat-contacts-item-text">
                          <h4>Andy Smith</h4>
                          <span>27 Dec 2018 </span>
                          <p>Vivamus lobortis vel nibh nec maximus. Donec dolor erat, rutrum ut feugiat sed, ornare
                            vitae nunc. Donec massa nisl, bibendum id ultrices sed, accumsan sed dolor.</p>
                        </div>
                      </a>
                      <!-- chat-contacts-item -->
                      <!-- chat-contacts-item-->
                      <a class="chat-contacts-item" href="#">
                        <div class="dashboard-message-avatar">
                          <img src="images/avatar/5.jpg" alt="">
                          <div class="message-counter">4</div>
                        </div>
                        <div class="chat-contacts-item-text">
                          <h4>Joe Frick</h4>
                          <span>27 Dec 2018 </span>
                          <p>Vivamus lobortis vel nibh nec maximus. Donec dolor erat, rutrum ut feugiat sed, ornare
                            vitae nunc. Donec massa nisl, bibendum id ultrices sed, accumsan sed dolor.</p>
                        </div>
                      </a>
                      <!-- chat-contacts-item -->
                      <!-- chat-contacts-item-->
                      <a class="chat-contacts-item" href="#">
                        <div class="dashboard-message-avatar">
                          <img src="images/avatar/avatar-bg.png.jpeg" alt="">
                        </div>
                        <div class="chat-contacts-item-text">
                          <h4>Alise Goovy</h4>
                          <span>27 Dec 2018 </span>
                          <p>Vivamus lobortis vel nibh nec maximus. Donec dolor erat, rutrum ut feugiat sed, ornare
                            vitae nunc. Donec massa nisl, bibendum id ultrices sed, accumsan sed dolor.</p>
                        </div>
                      </a>
                      <!-- chat-contacts-item -->
                      <!-- chat-contacts-item-->
                      <a class="chat-contacts-item" href="#">
                        <div class="dashboard-message-avatar">
                          <img src="images/avatar/3.jpg" alt="">
                        </div>
                        <div class="chat-contacts-item-text">
                          <h4>Freddy Kovalsky</h4>
                          <span>27 Dec 2018 </span>
                          <p>Vivamus lobortis vel nibh nec maximus. Donec dolor erat, rutrum ut feugiat sed, ornare
                            vitae nunc. Donec massa nisl, bibendum id ultrices sed, accumsan sed dolor.</p>
                        </div>
                      </a>
                      <!-- chat-contacts-item -->
                      <!-- chat-contacts-item-->
                      <a class="chat-contacts-item" href="#">
                        <div class="dashboard-message-avatar">
                          <img src="images/avatar/avatar-bg.png.jpeg" alt="">
                        </div>
                        <div class="chat-contacts-item-text">
                          <h4>Cristiano Olando</h4>
                          <span>27 Dec 2018 </span>
                          <p>Vivamus lobortis vel nibh nec maximus. Donec dolor erat, rutrum ut feugiat sed, ornare
                            vitae nunc. Donec massa nisl, bibendum id ultrices sed, accumsan sed dolor.</p>
                        </div>
                      </a>
                      <!-- chat-contacts-item -->
                    </div>
                  </div>
                  <!-- chat-contacts end-->
                </div>
              </div>
              <!-- dashboard-list-box end-->
            </div>
          </div>
          <!-- dashboard content end-->
        </div>
      </section>
      <!--  section  end-->
      <div class="limit-box fl-wrap"></div>
    </div>
    <!--content end-->
  </div>
</template>

<script>
export default {
  mounted() {
    window.initTowhub()
    window.initparallax();
  }
}
</script>
