<template>
  <div id="main">
    <app-header v-if="!routeAuth"/>

    <vue-page-transition name="fade-in-up">
      <router-view/>
    </vue-page-transition>

    <app-footer v-if="!routeAuth"/>
    <div class="map-modal-wrap">
      <div class="map-modal-wrap-overlay"></div>
      <div class="map-modal-item">
        <div class="map-modal-container fl-wrap">
          <div class="map-modal fl-wrap">
            <div id="singleMap" data-latitude="40.7" data-longitude="-73.1"></div>
          </div>
          <h3><span>Location for : </span><a href="#">Listing Title</a></h3>
          <div class="map-modal-close"><i class="fal fa-times"></i></div>
        </div>
      </div>
    </div>
    <div class="main-register-wrap modal">
      <div class="reg-overlay"></div>
      <div class="main-register-holder tabs-act">
        <div class="main-register fl-wrap  modal_main">
          <div class="main-register_title"><span><strong>Манай</strong> САЙТАД<strong></strong></span> тавтай
            морил
          </div>
          <div class="close-reg"><i class="fal fa-times"></i></div>
          <ul class="tabs-menu fl-wrap no-list-style">
            <li class="current"><a href="#tab-1"><i class="fal fa-sign-in-alt"></i> Нэвтрэх</a></li>
            <li><a href="#tab-2"><i class="fal fa-user-plus"></i> Бүртгүүлэх</a></li>
          </ul>
          <!--tabs -->
          <div class="tabs-container">
            <div class="tab">
              <!--tab -->
              <div id="tab-1" class="tab-content first-tab">
                <div class="custom-form">
                  <form method="post" name="registerform">
                    <label>Нэвтрэх нэр эсвэл Email хаяг <span>*</span> </label>
                    <input name="email" type="text" onClick="this.select()" value="">
                    <label>Нууц үг <span>*</span> </label>
                    <input name="password" type="password" onClick="this.select()" value="">
                    <button type="submit" class="btn float-btn color2-bg"> Нэвтрэх <i
                        class="fas fa-caret-right"></i>
                    </button>
                    <div class="clearfix"></div>
                    <div class="filter-tags">
                      <input id="check-a3" type="checkbox" name="check">
                      <label for="check-a3">Сануулах</label>
                    </div>
                  </form>
                  <div class="lost_password">
                    <a href="#">Нууц үг сэргээх үү?</a>
                  </div>
                </div>
              </div>
              <!--tab end -->
              <!--tab -->
              <div class="tab">
                <div id="tab-2" class="tab-content">
                  <div class="custom-form">
                    <form method="post" name="registerform" class="main-register-form"
                          id="main-register-form2">
                      <label>Таны нэр <span>*</span> </label>
                      <input name="name" type="text" onClick="this.select()" value="">
                      <label>Email хаяг <span>*</span></label>
                      <input name="email" type="text" onClick="this.select()" value="">
                      <label>Нууц үг <span>*</span></label>
                      <input name="password" type="password" onClick="this.select()" value="">
                      <div class="filter-tags ft-list">
                        <input id="check-a2" type="checkbox" name="check">
                        <label for="check-a2">Би зөвшөөрч байна. <a href="#">Нууцлалын
                          бодлого</a></label>
                      </div>
                      <div class="clearfix"></div>
                      <div class="filter-tags ft-list">
                        <input id="check-a" type="checkbox" name="check">
                        <label for="check-a">Би зөвшөөрч байна. <a href="#">Үйлчилгээний
                          нөхцөл</a></label>
                      </div>
                      <div class="clearfix"></div>
                      <button type="submit" class="btn float-btn color2-bg"> Бүртгүүлэх <i
                          class="fas fa-caret-right"></i></button>
                    </form>
                  </div>
                </div>
              </div>
              <!--tab end -->
            </div>
            <!--tabs end -->
            <div class="log-separator fl-wrap"><span>эсвэл</span></div>
            <div class="soc-log fl-wrap">
              <p>Олон нийтийн сүлжээ ашиглан нэвтрэх эсвэл бүртгүүлэх</p>
              <a href="#" class="facebook-log"> Facebook</a>
            </div>
            <div class="wave-bg">
              <div class='wave -one'></div>
              <div class='wave -two'></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <cart-side v-if="!routeAuth"/>
    <a class="to-top"><i class="fas fa-caret-up"></i></a>
  </div>
</template>

<script>
import AppHeader from "./components/header";
import AppFooter from "./components/footer";
import CartSide from "./components/cartSide";
import {mapActions} from "vuex"
export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter,
    CartSide
  },
  data() {
    return {
      routeAuth: false,
    }
  },
  beforeCreate() {
    this.$store.commit('setStorageValues');
  },
  mounted() {
    this.setBasketItems();
    this.routeAuth = this.$route.path.includes("auth");
  },
  watch: {
    '$route.path': function () {
      if (this.$route.path.includes("auth")) {
        this.routeAuth = true
      } else {
        this.routeAuth = false
      }
      console.log(this.routeAuth)
    }
  },
  methods: {
    ...mapActions({
      "setBasketItems": "setBasketItems"
    })
  }
}
</script>