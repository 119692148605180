<template>
    <!-- dashboard content-->
    <div class="col-md-8">
        <div class="dashboard-title fl-wrap">
            <h3>{{ '_personal_information' | translate }}</h3>
        </div>
        <!-- profile-edit-container-->
        <div class="profile-edit-container fl-wrap block_box">
            <el-form class="profile-form-container" :model="profileForm" :rules="rules" ref="profileForm">
                <div class="profile-form-container">
                    <el-row :gutter="20" class="demo-ruleForm">
                        <el-col :span="24" style="display: flex; justify-content: center">
                            <div style="width: 100px">
                                <img style="width: 100%" v-if="this.profileForm.avatar!=null"
                                     :src="$http.defaults.baseURL+this.profileForm.avatar"
                                     alt="">
                                <img style="width: 100%" v-else src="../../images/mris_user.jpeg" alt="">

                                <el-upload
                                    style="width: 36px; margin-top:-20px; margin-left:-20px; display:inline-block;
    height: 36px;
    line-height: 36px;
    border-radius: 100%;
    color: #fff;
    z-index: 4;
    text-align: center;
    box-shadow: 0 9px 26px rgb(58 87 135 / 20%);"
                                    v-model="profileForm.avatar"
                                    class="color-bg"
                                    :action="`${$http.defaults.baseURL}/api/profile/avatar`"
                                    :show-file-list="false"
                                    :on-success="handleAvatarSuccess"
                                    :before-upload="beforeAvatarUpload">
                                    <i class="fal fa-edit"></i>
                                </el-upload>
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item :label="'_firstname' | translate" prop="firstname">
                                <el-input :placeholder="'your_name' | translate"
                                          prefix-icon="fal fa-user"
                                          v-model="profileForm.first_name"
                                          autocomplete="off"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item :label="'_lastname' | translate" prop="lastname">
                                <el-input :placeholder="'your_lastname' | translate"
                                          prefix-icon="fal fa-user"
                                          v-model="profileForm.last_name"
                                          autocomplete="off"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <el-form-item :label="'_gender' | translate" prop="gender">
                                <el-select v-model="profileForm.gender" :placeholder="'_select' | translate"
                                           style="width: 100%">
                                    <el-option label="Эрэгтэй" value="1"></el-option>
                                    <el-option label="Эмэгтэй" value="0"></el-option>
                                    <i slot="prefix" class="el-input__icon el-icon-male"></i>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item :label="'_birthday' | translate" prop="birthday">
                                <!--                <el-input :placeholder="'your_mail' | translate"-->
                                <!--                          prefix-icon="fal fa-envelope"-->
                                <!--                          v-model="profileForm.birthday"-->
                                <!--                          autocomplete="off"></el-input>-->
                                <el-date-picker
                                    v-model="profileForm.birthday"
                                    type="date" style="width: 100%"
                                    :placeholder="'_select_birthday' | translate">
                                </el-date-picker>
                            </el-form-item>

                        </el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <el-form-item :label="'_phone' | translate" prop="phone">
                                <el-input :placeholder="'phone' | translate"
                                          prefix-icon="fal fa-phone"
                                          v-model="profileForm.phone"
                                          autocomplete="off"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item :label="'_addres' | translate" prop="addres">
                                <el-input :placeholder="'_address' | translate"
                                          prefix-icon="el-icon-location-outline"
                                          v-model="profileForm.address"
                                          autocomplete="off"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <div v-if="user.is_org == 1">
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <el-form-item :label="'reg_organization_name' | translate" prop="org_name">
                                    <el-input :placeholder="'reg_organization_name_placeholder' | translate"
                                              prefix-icon="el-icon-office-building"
                                              :disabled="disabled"
                                              v-model="profileForm.org_name"
                                              autocomplete="off"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item :label="'reg_position' | translate" prop="org_position">
                                    <el-input :placeholder="'reg_position_placeholder' | translate"
                                              prefix-icon="el-icon-suitcase"
                                              :disabled="disabled"
                                              v-model="profileForm.org_position"
                                              autocomplete="off"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <el-form-item :label="'reg_org_type' | translate" prop="org_type">
                                    <el-select v-model="profileForm.org_cat_id" :placeholder="'reg_org_type_placeholder' | translate" style="width: 100%" :disabled="disabled">
                                        <el-option v-for="item in org_types"
                                                   :key="item.id"
                                                   :aria-selected="profileForm.org_cat_id"
                                                   :label="item.lut_res_org_type"
                                                   :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item :label="'reg_org_sub_type' | translate">
                                    <el-select v-model="profileForm.org_sub_id" :placeholder="'reg_org_sub_type_placeholder' | translate" style="width: 100%" :disabled="disabled">
                                        <el-option v-for="item in org_subTypes"
                                                   :key="item.id"
                                                   :label="`${language.short == 'mn' ? item.sub_res_org_mn : item.sub_res_org_en}`"
                                                   :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <el-form-item :label="'reg_purpose' | translate" prop="purpose">
                                    <el-input :placeholder="'reg_purpose' | translate"
                                              prefix-icon="fal fa-flag"
                                              :disabled="disabled"
                                              v-model="profileForm.purpose"
                                              autocomplete="off"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item :label="'reg_purpose_other' | translate" prop="other_purpose">
                                    <el-input :placeholder="'reg_purpose_other_placeholder' | translate"
                                              prefix-icon="fal fa-flag"
                                              :disabled="disabled"
                                              v-model="profileForm.other_purpose"
                                              autocomplete="off"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>

                    <div class="home-search-button">
                        <el-button type="primary"
                                   icon="el-icon-upload"
                                   @click="submitForm('profileForm')"
                                   v-loading.fullscreen.lock="loading"
                        >{{ 'save' | translate }}
                        </el-button>
                    </div>
                </div>
            </el-form>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "Profile",
    data() {
        return {
            apiBaseUrl: window.init.apiBaseUrl,
            loading: false,
            isSuccess: false,
            isError: false,
            profileForm: {
                id: null,
                avatar: null,
                last_name: null,
                first_name: null,
                gender: null,
                phone: null,
                birthday: null,
                address: null,
                org_name: null,
                org_position: null,
                org_cat_id: null,
                org_sub_id: null,
                purpose: null,
                other_purpose: null,
            },
            org_types: [],
            org_subTypes: [],
            disabled: true,
            rules: {
                // firstname: [
                //   {required: true, message: 'Please input firstname'},
                //   {min: 3, max: 5, message: 'Length should be 3 to 5', trigger: 'blur'}
                // ],
                // lastname: [
                //   {required: true, message: 'Please input lastname', trigger: 'blur'},
                //   {min: 3, max: 5, message: 'Length should be 3 to 5', trigger: 'blur'}
                // ],
                // mail: [
                //   {type: 'email', required: true, message: 'Please input email', trigger: 'blur'},
                // ]
            }
        };
    },

    computed: {
        ...mapGetters([
            'user'
        ])
    },

    mounted() {
        this.profileForm.id = this.user.id;
        this.profileForm.avatar = this.user.avatar;
        this.profileForm.first_name = this.user.first_name;
        this.profileForm.last_name = this.user.last_name;
        this.profileForm.gender = this.user.gender;
        this.profileForm.birthday = this.user.birthday;
        this.profileForm.phone = this.user.phone;
        this.profileForm.address = this.user.address;
        this.profileForm.org_name = this.user.org_name;
        this.profileForm.org_position = this.user.org_position;
        this.profileForm.org_cat_id = this.user.org_cat_id.toString();
        this.profileForm.org_sub_id = this.user.org_sub_id.toString();
        this.profileForm.purpose = this.user.purpose;
        this.profileForm.other_purpose = this.user.other_purpose;
        this.getOrgTypes();
        this.getSubTypes();
    },

    methods: {
        handleAvatarSuccess(res) {
            this.profileForm.avatar = res;
        },
        getOrgTypes() {
            this.$http.get('/api/org/type').then(({data}) => {
                this.org_types = data;
            })
        },
        getSubTypes() {
            this.$http.get("/api/org/subtype/"+this.user.org_cat_id).then(response => {
                this.org_subTypes = response.data;
            })
        },
        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$message.error('Avatar picture must be JPG format!');
            }
            if (!isLt2M) {
                this.$message.error('Avatar picture size can not exceed 2MB!');
            }
            return isJPG && isLt2M;
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.profileForm.id = this.user.id;
                    this.loading = true;
                    this.$http.post('/api/profile/update', this.profileForm).then(({data}) => {
                        console.log("LOGIN FORM:");
                        console.log(data);
                        if (data.status) {
                            this.loading = false;
                            this.isError = false;
                            this.isSuccess = true;
                            this.msg = data.msg;
                            setTimeout(() => {
                                // this.$router.push({ name: data.path })
                                this.$store.commit('setUser', data.data);
                                // window.location.reload();
                            }, 1000);

                        } else {
                            // setTimeout(() => {
                            console.log(data.msg);
                            this.loading = false;
                            this.isError = true;
                            this.msg = "Мэдээлэл хадгалах үед алдаа гарлаа";
                            // }, 1000);
                        }

                        // eslint-disable-next-line no-unused-vars
                    }).catch(e => {
                        setTimeout(() => {
                            this.loading = false;
                            this.isError = true;
                            this.isSuccess = false;
                            this.msg = "Мэдээлэл хадгалах үед алдаа гарлаа";
                        }, 1000);
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
    }
}
</script>