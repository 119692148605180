import { render, staticRenderFns } from "./Ask_questions.vue?vue&type=template&id=7758638d&scoped=true"
import script from "./Ask_questions.vue?vue&type=script&lang=js"
export * from "./Ask_questions.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7758638d",
  null
  
)

export default component.exports