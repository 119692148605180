// import SimpleRenderer from "esri/renderers/SimpleRenderer";
// import PictureFillSymbol from "esri/symbols/PictureFillSymbol";
// import hexToRgba from 'hex-to-rgba';
import {doCluster} from './map';
function getColorWithAlpha(source_color, alpha){
    var color = source_color.split(',');

    if(alpha){
        return color[0]+','+color[1]+','+color[2]+','+alpha+")";
    } else {

        return color[0]+','+color[1]+','+color[2]+','+color[3]+")";
    }

}
export function getLayerShow(layer_id, layer_show, showedLayers, frame){
    if(frame == 'true')
        return false
    let isShow = false;
    if(showedLayers){

        let isIndex = showedLayers.findIndex(showedLayer=>{return showedLayer==layer_id});

        if(isIndex >= 0){
            isShow = true;

        } else {
            isShow = false;


        }
    } else {
        isShow = layer_show == '1' ||  layer_show == 1 ? true: false;
    }

    return isShow;
}
export function getRerender(legend) {
    if (legend.element_type == 'simple-point') {

        if(doCluster == 'true'){
            return {
                type: 'simple',
                symbol: {
                    size: 4,
                    type: 'simple-marker',
                    color: getColorWithAlpha(legend.fill_color,undefined),
                    style: 'solid',
                    outline: {
                        width: 5,
                        color: getColorWithAlpha(legend.border_color, '0.3'),
                    },
                },
            };
        } else {
            const symbol = {
                type: 'picture-marker', // autocasts as new PictureMarkerSymbol()
                url: legend.icon,
                width: '28px',
                height: '28px',
            };

            return {
                type: 'simple', // autocasts as new SimpleRenderer()
                symbol: symbol,
            };
        }


    }
    if (legend.element_type == 'point') {
        const symbol = {
            type: 'picture-marker', // autocasts as new PictureMarkerSymbol()
            url: legend.icon,
            width: '36px',
            height: '36px',
        };

        return {
            type: 'simple', // autocasts as new SimpleRenderer()
            symbol: symbol,
        };
    }
    if (legend.element_type == 'polygon') {
        if (legend.style_type == '0') {
            return {
                type: 'simple',
                symbol: {
                    type: 'simple-fill',
                    color: getColorWithAlpha(legend.fill_color,undefined),
                    style: 'solid',
                    outline: {
                        width: '1px',
                        color: getColorWithAlpha(legend.border_color, '1'),
                    },
                },
            };
        }
        if (legend.style_type == '2') {
            return {
                type: 'simple',
                symbol: {
                    type: 'simple-fill',
                    color: getColorWithAlpha(legend.fill_color, '1'),
                    width: '2px',
                    style: 'horizontal',
                    outline: {
                        width: '1px',
                        color: getColorWithAlpha(legend.border_color, '1'),
                        opacity: 1,
                    },
                },
            };
        }
        if (legend.style_type == '3') {
            return {
                type: 'simple',
                symbol: {
                    type: 'simple-fill',
                    color: getColorWithAlpha(legend.fill_color, '1'),
                    width: '2px',
                    style: 'vertical',
                    outline: {
                        width: '1px',
                        color: getColorWithAlpha(legend.border_color, '1'),
                        opacity: 1,
                    },
                },
            };
        }
        if (legend.style_type == '4') {
            return {
                type: 'simple',
                symbol: {
                    type: 'simple-fill',
                    color: getColorWithAlpha(legend.fill_color, '1'),
                    width: '2px',
                    style: 'backward-diagonal',
                    outline: {
                        width: '1px',
                        color: getColorWithAlpha(legend.border_color, '1'),
                    },
                },
            };
        }
        if (legend.style_type == '5') {
            return {
                type: 'simple',
                symbol: {
                    type: 'simple-fill',
                    color: getColorWithAlpha(legend.fill_color, '1'),
                    width: '2px',
                    style: 'forward-diagonal',
                    outline: {
                        width: '1px',
                        color: getColorWithAlpha(legend.border_color, '1'),
                    },
                },
            };
        }
        if (legend.style_type == '1') {
            return {
                type: 'simple',
                symbol: {
                    type: 'simple-fill',
                    color: getColorWithAlpha(legend.fill_color, '1'),
                    width: '2px',
                    style: 'diagonal-cross',
                    outline: {
                        width: '1px',
                        color: getColorWithAlpha(legend.border_color, '1'),
                    },
                },
            };
        }
    }

    if (legend.element_type == 'line') {
        return {
            type: 'simple', // autocasts as new SimpleRenderer()
            symbol: {
                type: 'simple-line', // autocasts as new SimpleLineSymbol()
                width: 3,
                color: getColorWithAlpha(legend.fill_color, '1'),
            },
        };

    }
}
/*

export function getRerender(legend){

    if(legend.element_type == 'point'){


            let symbol = {
                type: "picture-marker",  // autocasts as new PictureMarkerSymbol()
                url: legend.icon.destinationUrl+legend.icon.uniqueName,
                width: "20px",
                height: "20px"
            };

            return {
                type: "simple", // autocasts as new SimpleRenderer()
                symbol: symbol,
            }



    }
    if(legend.element_type == 'polygon'){
        if(legend.style_type == '0'){


            return {
                type: "simple",
                symbol: {
                    type: "simple-fill",
                    color: getColorWithAlpha(legend.fill_color, '0.5'),
                    style: "solid",
                    outline: {
                        width: '1px',
                        color: getColorWithAlpha(legend.border_color, '1'),

                    }
                }
            };
        }
        if(legend.style_type == '2'){


            return {
                type: "simple",
                symbol: {
                    type: "simple-fill",
                    color: getColorWithAlpha(legend.fill_color, '1'),
                    width: '2px',
                    style: "horizontal",
                    outline: {
                        width: '1px',
                        color: getColorWithAlpha(legend.border_color, '1'),
                        opacity:1
                    }
                }
            };
        }
        if(legend.style_type == '3'){


            return {
                type: "simple",
                symbol: {
                    type: "simple-fill",
                    color: getColorWithAlpha(legend.fill_color, '1'),
                    width: '2px',
                    style: "vertical",
                    outline: {
                        width: '1px',
                        color: getColorWithAlpha(legend.border_color, '1'),
                        opacity:1
                    }
                }
            };
        }
        if(legend.style_type == '4'){


            return {
                type: "simple",
                symbol: {
                    type: "simple-fill",
                    color: getColorWithAlpha(legend.fill_color, '1'),
                    width: '2px',
                    style: "backward-diagonal",
                    outline: {
                        width: '1px',
                        color: getColorWithAlpha(legend.border_color, '1'),

                    }
                }
            };
        }
        if(legend.style_type == '5'){


            return {
                type: "simple",
                symbol: {
                    type: "simple-fill",
                    color: getColorWithAlpha(legend.fill_color, '1'),
                    width: '2px',
                    style: "forward-diagonal",
                    outline: {
                        width: '1px',
                        color: getColorWithAlpha(legend.border_color, '1'),
                    }
                }
            };
        }
        if(legend.style_type == '1'){


            return {
                type: "simple",
                symbol: {
                    type: "simple-fill",
                    color: getColorWithAlpha(legend.fill_color, '1'),
                    width: '2px',
                    style: "diagonal-cross",
                    outline: {
                        width: '1px',
                        color: getColorWithAlpha(legend.border_color, '1'),
                    }
                }
            };
        }


    }

    if(legend.element_type == 'line'){


        return {
            type: "simple", // autocasts as new SimpleRenderer()
            symbol: {
                type:  "simple-line", // autocasts as new SimpleLineSymbol()
                width: 3,
                color: getColorWithAlpha(legend.fill_color, '1')
            }
        }
    }

}
*/
